<template>
  <div>
    <div class="col-md-12">
      <h4 class="site-heading my-3">
        <span>Filters want to show in grid</span>
      </h4>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <table
          class="table table-bordered builder-table has-aggregate-function"
        >
          <thead>
            <th style="width: 250px">Column</th>
            <th style="width: 100px">Operator</th>
            <th style="width: 100px">Alias</th>
            <th style="width: 30px">Is Dropdown?</th>
            <th style="width: 30px">Is Multiple?</th>
            <th style="width: 100px">Field Type</th>
            <th></th>
            <th style="width: 50px"></th>
          </thead>
          <tbody>
            <tr v-for="(filter, index) in filters" :key="index">
              <td>
                <multiselect
                  v-model="filter.column"
                  :options="select_options"
                  :searchable="true"
                  :multiple="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Pick a value"
                ></multiselect>
              </td>
              <td>
                <select
                  class="form-control filter-operators-primary-table"
                  v-model="filter.operators"
                >
                  <option value="equal">equal</option>
                  <option value="not_equal">not_equal</option>
                  <option value="less">less</option>
                  <option value="greater">greater</option>
                  <option value="begins_with">begins_with</option>
                  <option value="contains">contains</option>
                  <option value="ends_with">ends_with</option>
                  <option value="between">between</option>
                  <option value="in">in</option>
                  <option value="not_in">not_in</option>
                </select>
              </td>
              <td>
                <input class="form-control" v-model="filter.alias" />
              </td>
              <td style="text-align: center">
                <label class="checkbox"
                  ><input
                    class="form-check-input"
                    type="checkbox"
                    value="true"
                    v-model="filter.is_dropdown"
                  />
                  <span class="checkmark"></span>&nbsp;
                </label>
              </td>
              <td>
                <label class="checkbox" v-if="filter.is_dropdown == true"
                  ><input
                    class="form-check-input"
                    type="checkbox"
                    value="true"
                    v-model="filter.is_multiple" />
                  <span class="checkmark"></span
                ></label>
              </td>
              <td>
                <select
                  class="form-control filter-operators-primary-table"
                  v-model="filter.field_type"
                >
                  <option value="string">string</option>
                  <option value="integer">integer</option>
                  <option value="date">date</option>
                  <option value="date_time">date_time</option>
                </select>
              </td>
              <td>
                <input
                  v-if="filter.is_dropdown == true"
                  class="form-control"
                  v-model="filter.options"
                  title="Dropdown Option ID (DDID:1) or comma separated value for example - GET,POST"
                  placeholder="Dropdown Option ID (DDID:1) or comma separated value for example - GET,POST"
                />
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="deleteGridFilter(index)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          type="button"
          @click="addGridFilter"
          class="btn btn-success btn-sm mt-5"
        >
          <i class="fa fa-plus"></i>&nbsp;New
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  props: ["grid_filters", "select_options"],
  data: () => ({
    filters: [],
  }),
  components: {
    Multiselect,
  },
  methods: {
    addGridFilter: function () {
      this.filters.push({
        column: "",
        alias: "",
        is_dropdown: false,
        is_multiple: false,
        options: "",
        operators: "equal",
        field_type: "string",
      });
    },
    deleteGridFilter: function (index) {
      this.filters.splice(index, 1);
    },
  },
  watch: {
    grid_filters: function (val, oldVal) {
      this.filters = val;
    },
  },
  updated() {
    this.$emit("updateGridFilter", this.filters);
  },
};
</script>
