import { RouteConfig } from 'vue-router';
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue';

const routePath = '/ext-cbs-accounting';
const viewPath = '/CBS/Accounting'

const routes: Array<RouteConfig> = [
  {
    path: '/admin' + routePath + '/general_ledger_account',
    name: 'GeneralLedgerAccountList',
    component: () => import('../views' + viewPath + '/GeneralLedgerAccount/GeneralLedgerAccountList.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/general_ledger_account/add',
    name: 'GeneralLedgerAccountAdd',
    component: () => import('../views' + viewPath + '/GeneralLedgerAccount/GeneralLedgerAccountForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/general_ledger_account/:id/edit',
    name: 'GeneralLedgerAccountEdit',
    component: () => import('../views' + viewPath + '/GeneralLedgerAccount/GeneralLedgerAccountForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/general_ledger_account/:id/show',
    name: 'GeneralLedgerAccountShow',
    component: () => import('../views' + viewPath + '/GeneralLedgerAccount/GeneralLedgerAccountShow.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/account',
    name: 'CBSAccount',
    component: () => import('../views' + viewPath + '/Account/List.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/account/create',
    name: 'CBSAccountForm',
    component: () => import('../views' + viewPath + '/Account/Form.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/account/:id/show',
    name: 'CBSAccountShow',
    component: () => import('../views' + viewPath + '/Account/Show.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/account/:id/edit',
    name: 'CBSAccountEdit',
    component: () => import('../views' + viewPath + '/Account/Form.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/accounting_transaction',
    name: 'AccountingTransactionList',
    component: () => import('../views' + viewPath + '/AccountingTransaction/AccountingTransactionList.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/accounting_transaction/add',
    name: 'AccountingTransactionAdd',
    component: () => import('../views' + viewPath + '/AccountingTransaction/AccountingTransactionForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/accounting_transaction/:id/edit',
    name: 'AccountingTransactionEdit',
    component: () => import('../views' + viewPath + '/AccountingTransaction/AccountingTransactionForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/accounting_transaction/:id/show',
    name: 'AccountingTransactionShow',
    component: () => import('../views' + viewPath + '/AccountingTransaction/AccountingTransactionShow.vue'),
    meta: { requireAuth: true, layout: MasterLayout }
  },
]

export default routes
