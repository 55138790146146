import axios from 'axios';

export default {
  index(currentPage = null, query: any = null, is_paginate = true) {
    return new Promise((resolve, reject) => {
      let params = {page: currentPage, pagination: is_paginate}
      params = {...query, ...params};

      axios.get('services', {
        params: params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    })
  },
  store(payload: any) {
    return axios.post('services', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  show(id: any) {
    return axios.get('services/' + id)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  update(id: any, payload: any) {
    return axios.put('services/' + id, payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  destroy(id: any) {
    return axios.delete('services/' + id)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  }
}
