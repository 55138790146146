import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import 'v-toaster/dist/v-toaster.css'

import VueSweetalert2 from 'vue-sweetalert2'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueFontAwesomePicker from 'vfa-picker'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CKEditor from 'ckeditor4-vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VuePluralize from 'vue-pluralize'

import 'vue2-datepicker/index.css'

import localStorage from '@/services/localStorage'
import '@/services/elements'
import '@/services/veeValidate'

import vmodal from 'vue-js-modal'
import Vuex from 'vuex'
import moment from 'moment'

/* code for slick */

/* import '@/services/globalComponents' */

// import './../public/admin-lte/plugins/fontawesome-free/css/all.min.css'
// import './../public/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css'
// import './../public/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'
// import './../public/admin-lte/plugins/jqvmap/jqvmap.min.css'
// import './../public/admin-lte/dist/css/adminlte.min.css'
// import './../public/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
// import './../public/admin-lte/plugins/sweetalert2-theme-bootstrap-4/bootstrap-4.min.css'
// import './../public/assets/css/custom.css'
// import './../public/assets/css/main.css'
// import './../public/assets/css/login.css'
// import './../public/front/js/dist/pwa-shortcut.js'

Vue.use(VueMeta)

// adds vue-meta to Vue app
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Toaster = require('v-toaster')

// eslint-disable-next-line @typescript-eslint/no-var-requires
const _ = require('lodash')

Vue.use(VueFontAwesomePicker)

Vue.use(CKEditor)

Vue.use(VuePluralize)
/* const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask) */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DatePicker = require('vue2-datepicker')

// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(vmodal)
Vue.use(Vuex)

Vue.use(VueAxios, axios)
Vue.use(Toaster, { timeout: 5000 })
Vue.use(VueSweetalert2)
Vue.component('multiselect', Multiselect)

Vue.prototype.$moment = moment

Vue.config.productionTip = false

Vue.set(Vue.prototype, '_', _)
Vue.set(Vue.prototype, 'API_BASE_URL', process.env.VUE_APP_API_BASE_URL)
Vue.set(Vue.prototype, 'VUE_APP_MAIN_URL', process.env.VUE_APP_MAIN_URL)
Vue.set(Vue.prototype, 'API_ASSET_URL', process.env.VUE_APP_ASSET_UPLOAD_URL)
Vue.set(Vue.prototype, 'API_ASSET_UPLOAD_URL', process.env.VUE_APP_ASSET_UPLOAD_URL + '/uploads/')

Vue.set(Vue.prototype, 'APP_ENABLE_NOTIFICATION', process.env.VUE_APP_ENABLE_NOTIFICATION)
Vue.set(Vue.prototype, 'APP_MQTT_SERVER', process.env.VUE_APP_MQTT_SERVER)
Vue.set(Vue.prototype, 'APP_MQTT_CLIENT_PORT', process.env.VUE_APP_MQTT_CLIENT_PORT)
Vue.set(Vue.prototype, 'APP_MQTT_CLIENT_ID', process.env.VUE_APP_MQTT_CLIENT_ID)
Vue.set(Vue.prototype, 'APP_MQTT_HTTPS_ENABLE', process.env.VUE_APP_MQTT_HTTPS_ENABLE)
Vue.set(Vue.prototype, 'APP_MQTT_USERNAME', process.env.VUE_APP_MQTT_USERNAME)
Vue.set(Vue.prototype, 'APP_MQTT_PASSWORD', process.env.VUE_APP_MQTT_PASSWORD)

Vue.prototype.$API_BASE_URL = process.env.VUE_APP_API_BASE_URL

// Add a request interceptor
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

const CBSAccountingInstance = axios.create({
  baseURL: process.env.VUE_APP_CBS_ACCOUNTING_API_BASE_URL
})

Vue.prototype.$CBSAccountingInstance = CBSAccountingInstance

axios.interceptors.request.use(
  config => {
    const token = localStorage.get('token')
    if (token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers.Authorization = 'Bearer ' + token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    return Promise.reject(error)
  })

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (!_.isUndefined(error.response) && error.response.status === 401 &&
    error.response.data.message === 'Unauthenticated' && window.location.pathname !== '/login') {
    Vue.swal.fire({
      title: 'Error!',
      text: 'Your session has been terminated!',
      icon: 'error',
      confirmButtonText: 'OK'
    }).then((error) => {
      localStorage.clear()
      // router.push('/login');
      router.push({ name: 'Login' })
      return Promise.reject(error)
    })
  } else {
    if (!_.isUndefined(error.response) && error.response.status === 401) {
      localStorage.clear()
      router.push({ name: 'Login' })
      return Promise.reject(error)
    } else if (_.isUndefined(error.response.status)) {
      Vue.swal.fire({
        title: 'Oops..',
        text: 'There is some problem in your network!',
        icon: 'error',
        confirmButtonText: 'OK!'
      })
    }
    return Promise.reject(error)
  }
})

Vue.mixin({
  methods: {
    isMobile: function () {
      let deviceType = false;

      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) deviceType = true
      })(navigator.userAgent || navigator.vendor)

      return deviceType
    },
    getImage (image) {
      let deviceType = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) deviceType = true
      })(navigator.userAgent || navigator.vendor)

      return deviceType ? '/assets/images/' + image : '/assets/images/' + image
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
