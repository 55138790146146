import { RouteConfig } from 'vue-router';
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue';

const routePath = '/ext-cbs-product-rating'
const viewPath = '/CBS/ProductRating'

const routes: Array<RouteConfig> = [

  {
    path: '/admin' + routePath + '/rating_type',
    name: 'RatingTypeList',
    component: () => import('../views' + viewPath + '/RatingType/RatingTypeList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_type/add',
    name: 'RatingTypeAdd',
    component: () => import('../views' + viewPath + '/RatingType/RatingTypeForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_type/:id/edit',
    name: 'RatingTypeEdit',
    component: () => import('../views' + viewPath + '/RatingType/RatingTypeForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_type/:id/show',
    name: 'RatingTypeShow',
    component: () => import('../views' + viewPath + '/RatingType/RatingTypeShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_condition_name',
    name: 'RatingConditionNameList',
    component: () => import('../views' + viewPath + '/RatingConditionName/RatingConditionNameList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_condition_name/add',
    name: 'RatingConditionNameAdd',
    component: () => import('../views' + viewPath + '/RatingConditionName/RatingConditionNameForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_condition_name/:id/edit',
    name: 'RatingConditionNameEdit',
    component: () => import('../views' + viewPath + '/RatingConditionName/RatingConditionNameForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_condition_name/:id/show',
    name: 'RatingConditionNameShow',
    component: () => import('../views' + viewPath + '/RatingConditionName/RatingConditionNameShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_plan',
    name: 'RatingPlanList',
    component: () => import('../views' + viewPath + '/RatingPlan/RatingPlanList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_plan/add',
    name: 'RatingPlanAdd',
    component: () => import('../views' + viewPath + '/RatingPlan/RatingPlanForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_plan/:id/edit',
    name: 'RatingPlanEdit',
    component: () => import('../views' + viewPath + '/RatingPlan/RatingPlanForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_plan/:id/show',
    name: 'RatingPlanShow',
    component: () => import('../views' + viewPath + '/RatingPlan/RatingPlanShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_config',
    name: 'RatingConfigList',
    component: () => import('../views' + viewPath + '/RatingConfig/RatingConfigList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_config/add',
    name: 'RatingConfigAdd',
    component: () => import('../views' + viewPath + '/RatingConfig/RatingConfigForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_config/:id/edit',
    name: 'RatingConfigEdit',
    component: () => import('../views' + viewPath + '/RatingConfig/RatingConfigForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/rating_config/:id/show',
    name: 'RatingConfigShow',
    component: () => import('../views' + viewPath + '/RatingConfig/RatingConfigShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
];

export default routes;
