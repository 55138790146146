import axios from 'axios'

export default {
  getInvoice (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/invoice/invoice', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  storeInvoice (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/invoice/invoice', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showInvoice (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/invoice/invoice/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateInvoice (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/invoice/invoice/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  destroyInvoice (id: any) {
    return new Promise((resolve, reject) => {
      axios.delete('/cbs/invoice/invoice/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
}
