import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

const routes: Array<RouteConfig> = [
  {
    path: '/admin/ext-cbs-api-gateway/gw_app_info',
    name: 'ApiGateWayApiList',
    meta: {title: 'Gateway API Info', requireAuth: true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "apigw" */ '../views/CBSAPIGateway/CBSApplicationInfo/CBSApiGatewayApplicationInfoList.vue')
  },
  {
    path: '/admin/ext-cbs-api-gateway/gw_api_info',
    name: 'ApiGateWayApplicationList',
    meta: {title: 'Gateway Application Info - Dotlines', requireAuth: true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "apigw" */ '../views/CBSAPIGateway/CBSApplicationInfo/CBSApiGatewayApplicationInfoList.vue')
  },
  {
    path: '/admin/ext-cbs-api-gateway/gw_app_info/create',
    name: 'ApiGatewayApplicationForm',
    component: () => import('../views/CBSAPIGateway/CBSApplicationInfo/CBSApiGatewayApplicationInfoForm.vue'),
    meta: {
      requireAuth: true,
      layout: MasterLayout,
      title: 'APIGW Application form - Dotlines',
      metaTags: [{
        name: 'Application',
        content: 'The Department form page of Dotlines app.'
      }, {
        property: 'og:description',
        content: 'The Department form page of Dotlines app.'
      }]
    }
  },
  {
    path: '/admin/ext-cbs-api-gateway/gw_app_info/edit/:id',
    name: 'ApiGatewayApplicationEdit',
    component: () => import('../views/CBSAPIGateway/CBSApplicationInfo/CBSApiGatewayApplicationInfoForm.vue'),
    meta: {
      requireAuth: true,
      title: 'APIGW Application edit - Dotlines',
      metaTags: [{
        name: 'Application',
        content: 'The Department edit page of Dotlines app.'
      }, {
        property: 'og:description',
        content: 'The Department edit page of Dotlines app.'
      }]
    }
  },
];

export default routes;
