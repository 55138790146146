<template>
<div>
  <div class="header">
    <div class="header-left">
      <div class="logo">
        <a href=""><img src="./../../../public/assets/digi/images/sohoj-white-logo.svg" alt=""></a>
      </div>
    </div>
  </div>
  <div class="container p-0">
   <div class="campaign-image">
     <img style="width: 100%" :src=" pictureBaseUrl+'/images/campaign/campaign.png'" alt=""/>
   </div>
    <div class="campaign-operators">
      <h3>Select Your Operator</h3>
      <div class="row campaign-box">
        <div class="col-md-6 campaign-item" >
          <a href="" @click="submit('dg')">
            <img :src="pictureBaseUrl+'/images/campaign/digi.svg'" alt="digi">
            Digi
          </a>
        </div>
        <div class="col-md-6 campaign-item">
          <a href="" @click="submit('um')">
            <img :src="pictureBaseUrl+'/images/campaign/umobile.svg'" alt="U mobile">
            U Mobile
          </a></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import selectLanguage from '@/views/includes/select-language.vue'
import {gtmDataPush} from "../../../public/front/js/gtmHepler";

export default {
  name: 'campaign',
  data() {
    return {
      apiUrl: 'content/notice-page',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn') ?? '',
      showModal: false,
      operatorId: localStorage.getItem('operatorId') ?? '1',
      index_no: '',
      detailsShow: false
    }
  },
  mounted: function () {
    localStorage.setItem('shortcut_modal', 'false')
  },
  methods: {
    submit(op) {
      if (op === 'dg') {
        this.$router.push('/topup-services/topup?op=1&lang=23')
      } else if (op === 'um') {
        this.$router.push('/topup-services/topup?op=2&lang=12')
      }
    }
  }
}
</script>

<style scoped>

</style>
