import { RouteConfig } from 'vue-router';
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue';

let routePath = '/ext-cbs-payment';
let viewPath = '/CBS/Payment'

const routes: Array<RouteConfig> = [
  {
    path: '/admin' + routePath + '/gateway',
    name: 'GatewayList',
    component: () => import('../views' + viewPath + '/Gateway/GatewayList.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/gateway/add',
    name: 'GatewayAdd',
    component: () => import('../views' + viewPath + '/Gateway/GatewayForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/gateway/:id/edit',
    name: 'GatewayEdit',
    component: () => import('../views' + viewPath + '/Gateway/GatewayForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/gateway/:id/show',
    name: 'GatewayShow',
    component: () => import('../views' + viewPath + '/Gateway/GatewayShow.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },

  {
    path: '/admin' + routePath + '/merchant_gateway',
    name: 'MerchantGatewayList',
    component: () => import('../views' + viewPath + '/MerchantGateway/MerchantGatewayList.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/merchant_gateway/add',
    name: 'MerchantGatewayAdd',
    component: () => import('../views' + viewPath + '/MerchantGateway/MerchantGatewayForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/merchant_gateway/:id/edit',
    name: 'MerchantGatewayEdit',
    component: () => import('../views' + viewPath + '/MerchantGateway/MerchantGatewayForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/merchant_gateway/:id/show',
    name: 'MerchantGatewayShow',
    component: () => import('../views' + viewPath + '/MerchantGateway/MerchantGatewayShow.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction',
    name: 'PaymentTransactionList',
    component: () => import('../views' + viewPath + '/PaymentTransaction/PaymentTransactionList.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction/add',
    name: 'PaymentTransactionAdd',
    component: () => import('../views' + viewPath + '/PaymentTransaction/PaymentTransactionForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction/:id/edit',
    name: 'PaymentTransactionEdit',
    component: () => import('../views' + viewPath + '/PaymentTransaction/PaymentTransactionForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction/:id/show',
    name: 'PaymentTransactionShow',
    component: () => import('../views' + viewPath + '/PaymentTransaction/PaymentTransactionShow.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction_event',
    name: 'PaymentTransactionEventList',
    component: () => import('../views' + viewPath + '/PaymentTransactionEvent/PaymentTransactionEventList.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction_event/add',
    name: 'PaymentTransactionEventAdd',
    component: () => import('../views' + viewPath + '/PaymentTransactionEvent/PaymentTransactionEventForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction_event/:id/edit',
    name: 'PaymentTransactionEventEdit',
    component: () => import('../views' + viewPath + '/PaymentTransactionEvent/PaymentTransactionEventForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/payment_transaction_event/:id/show',
    name: 'PaymentTransactionEventShow',
    component: () => import('../views' + viewPath + '/PaymentTransactionEvent/PaymentTransactionEventShow.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
];

export default routes;
