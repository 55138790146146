<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Right navbar links -->
        <ul class="navbar-nav align-items-center ">
            <li class="nav-item mr-auto">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
            <!-- Messages Dropdown Menu -->
            <li class="nav-item dropdown notify-menu" v-if="show_notification == true">
                <a class="nav-link" data-toggle="dropdown" href="javascript:void(0)" @click="updateStatus()">
                    <i class="fas fa-bell"></i>
                    <span class="badge badge-danger navbar-badge" v-if="notification_count !== 0">{{
                            notification_count
                        }}</span>
                </a>

                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div class="notification-inner-content">
                        <div v-for="message in messages">
                            <router-link :to="message.link" class="dropdown-item" v-if="!isExternalLink(message.link)">
                                <!-- Message Start -->
                                <div class="media">
                                    <!--              <img src="/assets/images/user-avatar.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">-->
                                    <div class="media-body">
                                        <h3 class="dropdown-item-title">
                                            {{ message.title }}
                                        </h3>
                                        <p class="text-sm">{{ message.description }}</p>
                                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>
                                            {{ message.time_diffrence }}</p>
                                    </div>
                                </div>
                                <!-- Message End -->
                            </router-link>
                            <a :href="message.link" class="dropdown-item" v-else target="_blank">
                                <!-- Message Start -->
                                <div class="media">
                                    <!--              <img src="/assets/images/user-avatar.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">-->
                                    <div class="media-body">
                                        <h3 class="dropdown-item-title">
                                            {{ message.title }}
                                        </h3>
                                        <p class="text-sm">{{ message.description }}</p>
                                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>
                                            {{ message.time_diffrence }}</p>
                                    </div>
                                </div>
                                <!-- Message End -->
                            </a>
                        </div>
                        <!--          <a href="#" class="dropdown-item">
                                    &lt;!&ndash; Message Start &ndash;&gt;
                                    <div class="media">
                        &lt;!&ndash;              <img src="/assets/images/user-avatar.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">&ndash;&gt;
                                      <div class="media-body">
                                        <h3 class="dropdown-item-title">
                                          Brad Diesel
                        &lt;!&ndash;                  <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>&ndash;&gt;
                                        </h3>
                                        <p class="text-sm">Call me whenever you can...</p>
                                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                      </div>
                                    </div>
                                    &lt;!&ndash; Message End &ndash;&gt;
                                  </a>-->
                    </div>
                    <router-link :to="{ name: 'AuthNotifications'}" class="dropdown-item dropdown-footer">
                        See All Messages
                    </router-link>
                    <!--          <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>-->
                </div>
            </li>

            <li class="nav-item dropdown user user-menu">
                <!-- Menu Toggle Button -->
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <!-- The user image in the navbar-->
                    <img v-bind:src="profile.avatar" class="img-circle user-image" alt="User Image">
                    <!-- hidden-xs hides the username on small devices so only the image appears. -->
                    <span class="">{{ profile.name }}</span>
                </a>
                <ul class="dropdown-menu">
                    <!-- The user image in the menu -->
                    <li class="user-header">
                        <img v-bind:src="profile.avatar" class="img-circle img-size-50" alt="User Image">
                        <p>
                            {{ profile.name }}
                            <small>Member since admin {{ profile.created_at }}</small>
                        </p>
                    </li>
                    <li class="user-footer d-flex justify-content-between">
                        <div>
                            <!--                                <a href="#" class="btn">Setting</a>-->
                            <router-link :to="{name: 'AuthProfile'}" class="btn">
                                <i class="fas fa-cogs"></i> Settings
                            </router-link>
                        </div>
                        <div>
                            <a href="javascript:void(0)" @click="logout()" class="btn btn-danger"><i
                                class="fas fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import mqtt from 'mqtt'
import {CommonAPI} from "@/services/api";
import axios from "axios";

export default {
    props: {
        //
    },
    computed: {
        ...mapGetters(["profile"])
    },
    data: () => ({
        //let subscriptionID = 'user-' + '".Admin::user()->id."';
        //let clientID = 'user-' + '".Admin::user()->id."' + '_' + makeid(6);
        menuItems: [],
        connection: {
            host: '',
            port: '',
            endpoint: '/mqtt',
            clean: true,
            connectTimeout: 4000,
            reconnectPeriod: 4000,
            clientId: '',
            username: '',
            password: '',
        },
        subscription: {
            topic: '',
            qos: 0,
        },
        subscribeSuccess: false,
        //received_messages: '',
        messages: [],
        notification_count: 0,
        user_detail: {},
        show_notification: false
    }),
    mounted() {
        this.initialize();
        this.FETCH_PROFILE();


        // this.$store.dispatch(FETCH_AUTH);
        // await this.getAuthDetail();
        //await this.getMenuItems();
        /*let SELECTOR_DATA_WIDGET = '[data-widget="treeview"]';
        let SELECTOR_LINK = '.nav-link';
        let Default = {
          trigger: SELECTOR_DATA_WIDGET + " " + SELECTOR_LINK,
          animationSpeed: 300,
          accordion: true,
          expandSidebar: false,
          sidebarButtonSelector: '[data-widget="pushmenu"]'
        }*/
        /*$('[data-widget="treeview"]').each(function() {
          AdminLte.Treeview._jQueryInterface.call($(this), 'init');
        });*/
        //window.$('ul[data-widget="treeview"]').Treeview('init');

        /*const $ = window.$;
        $('[data-widget="treeview"]').each(function() {
          AdminLte.Treeview('init');
        });*/
        /*const $ = window.$;
        const trees = $('[data-widget="treeview"]');
        trees.Treeview('init');*/

        //jQuery('[data-widget="treeview"]').Treeview('init');
        //console.log(jQuery);
        //jQuery('.sidebar-toggle-btn').pushMenu(options)
    },
    methods: {
        ...mapActions(["FETCH_PROFILE"]),
        logout: function () {
            localStorage.setItem("authenticate", false);
            localStorage.removeItem("token");
            this.$router.push({'name': 'Login'});
        },
        async initialize() {
            //this.is_loading = true;

            if (_.isEmpty(this.profile)) {
                await CommonAPI.getProfile().then(profile_info => {
                    this.user_detail = profile_info;
                });
            } else {
                this.user_detail = this.profile;
            }

            if (this.APP_ENABLE_NOTIFICATION == 'true') {
                this.show_notification = true;
                this.connection = {
                    host: this.APP_MQTT_SERVER,
                    port: this.APP_MQTT_CLIENT_PORT,
                    endpoint: '/mqtt',
                    clean: true,
                    connectTimeout: 4000,
                    reconnectPeriod: 4000,
                    clientId: '',
                    username: this.APP_MQTT_USERNAME,
                    password: this.APP_MQTT_PASSWORD,
                }

                await this.getNotifications().then(response => {
                    this.messages = response.data;

                    let date_object = new Date();
                    let current = date_object.getTime();
                    let message_time;
                    let times_ago;
                    //received_object.time_diffrence = times_ago
                    //this.messages.unshift(received_object)
                    //this.notification_count = this.notification_count + 1

                    this.messages.forEach((value, key) => {
                        message_time = Date.parse(value.created_at)
                        times_ago = this.timeDifference(current, message_time);
                        this.messages[key].time_diffrence = times_ago
                        if (value.is_seen == 0) {
                            this.notification_count = this.notification_count + 1
                        }
                    });

                }).catch(error => {
                    if (!_.isEmpty(error.response) && error.response.status === 403) {
                        this.has_permission = false;
                    }
                });

                this.createConnection();
            }
            //this.is_loading = false;
        },
        updateStatus: function () {
            let ids = [];
            this.messages.forEach((value, key) => {
                ids.push(value.id);
            });
            //console.log(this.messages);
            //console.log('ids::', ids);
            axios({
                method: "PUT", url: "/auth/notifications/statusUpdate",
                data: {'ids': ids},
                headers: {"Content-Type": "application/json"},
            }).then(function (response) {

            }).catch((errors) => {
                if (!_.isEmpty(errors.response) && errors.response.status === 500) {
                    this.$toaster.error(errors.response.data.message);
                }
            }).finally(() => {
                this.notification_count = 0;
                this.getNotifications();
            });
        },
        getNotifications: function () {
            return new Promise((resolve, reject) => {
                //let params = {page: this.list.current_page}
                //params = {...this.filter, ...params};
                axios.get("/auth/notifications?limit=10").then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                    //console.log("error", error.response.data);
                }).finally(() => {
                    //_this.is_loading = false;
                })
            });
        },
        createConnection() {
            const {host, port, endpoint, ...options} = this.connection
            let protocol = 'ws';
            if (parseInt(this.APP_MQTT_HTTPS_ENABLE) == 1) {
                protocol = 'wss';//`wss://${host}:${port}${endpoint}`
            }

            const connectUrl = `${protocol}://${host}:${port}${endpoint}`
            this.connection.clientId = 'user-' + this.user_detail.id + '-' + this.makeid(6);
            this.subscription.topic = 'user-' + this.user_detail.id;
            //console.log('this.connection.clientId::', this.connection.clientId);

            try {
                this.client = mqtt.connect(connectUrl, options)
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
            this.client.on('connect', () => {
                console.log('Connection succeeded!')
                this.doSubscribe()
            })
            this.client.on('error', error => {
                console.log('Connection failed', error)
            })
            this.client.on('message', (topic, message) => {
                let received_messages = ''
                let received_object = {}
                received_messages = received_messages.concat(message)
                received_object = JSON.parse(received_messages)

                let date_object = new Date();
                let current = date_object.getTime();
                let message_time = Date.parse(received_object.created_at)
                let times_ago = this.timeDifference(current, message_time);
                received_object.time_diffrence = times_ago
                this.messages.unshift(received_object)
                this.notification_count = this.notification_count + 1

                //console.log(`Received message ${message} from topic ${topic}`)
            })
        },
        doSubscribe() {
            const {topic, qos} = this.subscription
            this.client.subscribe(topic, qos, (error, res) => {
                if (error) {
                    console.log('Subscribe to topics error', error)
                    return
                }
                this.subscribeSuccess = true
                //console.log('Subscribe to topics res', res)
            })
        },
        isExternalLink: function (to) {
            return typeof to === 'string' && to.startsWith('http')
        },
        timeDifference: function (current, previous) {
            let msPerMinute = 60 * 1000;
            let msPerHour = msPerMinute * 60;
            let msPerDay = msPerHour * 24;
            let msPerMonth = msPerDay * 30;
            let msPerYear = msPerDay * 365;

            var elapsed = current - previous;

            /*if (elapsed < msPerMinute) {
              return Math.round(elapsed/1000) + ' seconds ago';
            }*/

            if (elapsed < msPerHour) {
                return Math.round(elapsed / msPerMinute) + ' minutes ago';
            } else if (elapsed < msPerDay) {
                return Math.round(elapsed / msPerHour) + ' hours ago';
            } else if (elapsed < msPerMonth) {
                return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
            } else if (elapsed < msPerYear) {
                return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
            } else {
                return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
            }
        },
        makeid: function (length) {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    }
}
</script>
