<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="single-category-box">
        <img :src="dataDetails.image_url" alt="">
      </div>
     <div  v-if="subscriptionStatus !== 0">
       <a href="javascript:void(0);" @click="submit(data.product_id)" v-for="data in dataDetails.packages"
          class="hoichoi-subscribe" data-product_id="1">
         <p><strong>{{ data.validity }}</strong> দিনের জন্য <span>সাবস্ক্রাইব</span> করুন</p>
         <div class="price-box"><strong>{{ data.price }}</strong> <span>মাত্র <span>RM</span></span></div>
       </a>
     </div>
      <div class="unsubscribe-box" v-else><a id="visit" class="btn-visit" href="javascript:void(0)" @click="visitSite()" data-language="1"
                                      data-visit_url="https://www.hoichoi.tv?msisdn=MDExMzMzMTI5MTE=" data-tel="">{{ !_.isEmpty(dataDetails) ? dataDetails.visit_btn_text : '' }}</a>
        <a class="unsbs-btn" href="javascript:void(0)" @click="unSubSubmit()" data-option_id="undefined" data-service_id="undefined"
                   data-language="1">{{ !_.isEmpty(dataDetails) ? dataDetails.unsubscribe_btn_text : '' }}</a></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'
import {updateEvent} from "@/services/api/vivr-engine-helper";

export default {
  name: 'packageService',
  data () {
    return {
      apiUrl: '/content/',
      dataDetails: {},
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      is_loading: true,
      subscriptionStatus: '',
      registrationCheckUrl: '/check-reg-status',
      sessionId: sessionStorage.getItem('sessionId'),
      service: {},
      operatorId: localStorage.getItem('operatorId')
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      const position = this.service.search('_')
      if (position > -1) {
        this.service = this.service.slice(0, position)
      }
      this.apiUrl += this.service
      await this.getData().then(response => {
        this.dataDetails = response.data
      })
      if (this.msisdn) {
        await this.getRegistrationStatus().then(response => {
          this.subscriptionStatus = response.data
        })
      }
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
      this.is_loading = false
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, { languageId: this.languageId, msisdn: this.msisdn, operatorId: this.operatorId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getRegistrationStatus () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.registrationCheckUrl, { msisdn: this.msisdn, operatorId: this.operatorId, service: this.service })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    unSubSubmit () {
      this.$router.push({ name: 'unsubConfirmationPage' })
    },
    submit (id) {
      if (id === 1) {
        sessionStorage.setItem('service', 'hoichoi_1')
      } else if (id === 2) {
        sessionStorage.setItem('service', 'hoichoi_2')
      } else {
        sessionStorage.setItem('service', 'hoichoi_3')
      }
      if (this.msisdn) {
        this.$router.push({ name: 'service-confirmation-page' })
      } else {
        this.$router.push({ name: 'input-msisdn-page' })
      }
    },
    visitSite () {
      { location.href = this.dataDetails.visit_url }
    }
  }
}
</script>

<style scoped>

</style>
