<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <h2>404 - Not Found!</h2>
      <br>
      <router-link to="/" class="btn btn-default">
        <p>Back to Home</p>
      </router-link>
    </div>
  </div>
</template>
<script>
import GuestLayout from '@/components/layouts/GuestLayoutComponent.vue';
export default {
  data: () => ({
    //
  }),
  mounted: function () {
    console.log('Error404 component mounted.');
  },
  created() {
    this.$emit('update:layout', GuestLayout);
  },
}
</script>
