<template>
  <div id="page-container">
    <div class="container">
      <div id ='loader' v-if="is_loading"></div>
      <div class="confirm-wrap" v-else>
        <img :src="datalist.image_link" alt="">
        <p style="color: red;" v-html="datalist.confirmation_text"></p>
        <a class="btn" id="back_to_home" @click="goBack" data-service_id="" data-option_id=""
           data-language="1">{{ !_.isEmpty(datalist) ? datalist.btn_text : '' }}</a>
        <br>
      </div>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'

export default {
  name: 'serviceDeRegistration',
  data () {
    return {
      dataApiUrl: 'content/unsub-success-page',
      datalist: {},
      serviceDeRegistrationUrl: '/service-deregistration',
      serviceDeRegistrationStatus: '',
      subService: sessionStorage.getItem('sub_service'),
      languageId: sessionStorage.getItem('language') ?? '1',
      service: (sessionStorage.getItem('service') ?? ''),
      msisdn: (localStorage.getItem('msisdn') ?? ''),
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      serviceUrl: (sessionStorage.getItem('serviceUrl') ?? '') + '/',
      serviceType: (sessionStorage.getItem('serviceType') ?? ''),
      operatorId: localStorage.getItem('operatorId'),
      timerCount: 59,
      reToken: sessionStorage.getItem('re_token') ?? '',
      reProductId: sessionStorage.getItem('re_product_id') ?? '',
      storeReferalInfoApiUrl: 'store-referal-info'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      if (this.serviceType === 'mutiple-service') {
        this.service = this.subService
      }
      await this.serviceDeRegistration().then(response => {
        this.serviceDeRegistrationStatus = response.data
      })
      await this.getdata().then(response => {
        this.datalist = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, this.service + '_registration_page', this.msisdn, this.languageId, this.sessionId)
    },
    getdata () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.dataApiUrl, {
            msisdn: this.msisdn,
            service: this.service,
            languageId: this.languageId,
            operatorId: this.operatorId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    serviceDeRegistration () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.serviceDeRegistrationUrl, {
            msisdn: this.msisdn,
            service: this.service,
            operatorId: this.operatorId,
            languageId: this.languageId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    storeReferalInfo (smsId, price) {
      axios
        .post(this.storeReferalInfoApiUrl, '', {
          params: {
            msisdn: this.msisdn,
            reToken: this.reToken,
            reProductId: this.reProductId,
            tId: smsId,
            price: price
          }
        })
    },
    goBack () {
      this.$router.go(-2)
    }
  }
}
</script>

<style scoped>

</style>
