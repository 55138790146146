import axios from 'axios';
const _ = require('lodash');

export default {
  getAllOrganizations: function () {
    return new Promise(resolve => {
      axios.get('/all-organizations')
        .then(response => {
          resolve(response.data);
          //return response.data;
        })
        .catch((error) => {
          console.log('error ' + error);
          resolve(error);
        });
    });
  },
  getProfile: function () {
    return new Promise(resolve => {
      axios.get('/auth/get-user')
        .then(response => {
          resolve(response.data);
          //return response.data;
        })
        .catch((error) => {
          console.log('error ' + error);
          resolve(error);
        });
    });
  },
  getCustomPermissionLists: function () {
    return new Promise(resolve => {
      axios.get('/custom-permission-list')
        .then(response => {
          resolve(response.data);
          //return response.data;
        })
        .catch((error) => {
          console.log('error ' + error);
          resolve(error);
        });
    });
  },
  getCustomPermission: function (url: any) {
    return new Promise(resolve => {
      axios.get(url)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          console.log('error ' + error);
          resolve(error);
        });
    });
  },
  getDepartment: function () {
    return new Promise(resolve => {
      axios.get('/all-departments')
        .then(response => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error ' + error);
          resolve(error);
        });
    });
  },
  getPermissions: function () {
    return new Promise(resolve => {
      axios.get('/all-permissions')
        .then(response => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error ' + error);
          resolve(error);
        });
    });
  },
  getRoles: function () {
    return new Promise(resolve => {
      axios.get('/all-roles')
        .then(response => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error ' + error);
          resolve(error);
        });
    });
  },
  buildUrl(url: string, parameters: any, mark= false) {
    var qs = "";
    for(var key in parameters) {
      var value = parameters[key];
      qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
    if (qs.length > 0) {
      qs = qs.substring(0, qs.length-1); //chop off last "&"
      if ( mark == false ) {
        url = url + "?" + qs;
      } else {
        url = url + "&" + qs;
      }
    }
    return url;
  },
  getAllUsers(query = null) {
    // @ts-ignore
    let url = (query && query.length >= 2) ? 'all-users?q=' + query : 'all-users';
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      }).finally(() => {
        //
      });
    })
  },
  /*checkCreateButtonPermission: function (actionPermission: any, createIdCondition: any, userDetail: any, enableAdministratorPermissionByDefault: any): boolean {
    let createConditions = '';
    let disableCreateButton = false;
    let createConditionFieldValue = '';
    let createConditionValue;

    if (enableAdministratorPermissionByDefault == '1') {
      return true;
    }


    createIdCondition.forEach((condition: any, index: number) => {
      if (!_.isEmpty(condition['condition_field']) && _.isEmpty(condition['condition_op']) && _.isEmpty(condition['condition_value'])) {
        createConditionFieldValue = userDetail.roles[0].id;
      } else {
        createConditionFieldValue = (_.isNumber(userDetail[condition['condition_field']])) ? userDetail[condition['condition_field']] : '"'+userDetail[condition['condition_field']]+'"';
      }

      if (condition['condition_op'] === 'in') {
        createConditionValue = condition['condition_value'].split(',');
        if (createConditionValue.includes(createConditionFieldValue.toString())) {
          createConditionValue = createConditionFieldValue;
        } else {
          createConditionValue = 0;
        }
        condition['condition_op'] = '==';
      } else {
        createConditionValue = (_.isNumber(condition['condition_value'])) ? condition['condition_value'] : '"'+condition['condition_value']+'"';
      }

      switch (condition['joining_rules']) {
        case 'NA':
        case 'AND':
          createConditions += "("+createConditionFieldValue+condition['condition_op']+createConditionValue+") && ";
          break;
        case 'OR':
          createConditions += "("+createConditionFieldValue+condition['condition_op']+createConditionValue+") || ";
          break;
      }
    });

    createConditions = this.rtrim(this.rtrim(createConditions, ' && '), ' || ');

    if (!_.isEmpty(createConditions) && actionPermission.includes("Create") && this.check(createConditions)) {
      disableCreateButton = true;
    }

    return disableCreateButton;
  },
  checkEditButtonPermission: function (actionPermission: any, editIdCondition: any, editRefidCondition: any, editCustomCondition: any, data: any, userDetail: any, enableAdministratorPermissionByDefault: any): boolean {
    let editRefidConditions = '';
    let editRefidConditionValue = ''
    let editRefidConditionFieldValue = '';
    let editCustomConditions = '';
    let condition_value;
    let editConditionValue;
    let edit_custom_condition_field;
    let editConditionFieldValue;

    if (enableAdministratorPermissionByDefault == '1') {
      return true;
    }

    editRefidCondition.forEach((condition: any, index: number) => {
      if (!_.isEmpty(condition['condition_field']) && !_.isEmpty(condition['condition_op'])) {
        editRefidConditionValue = (data.creator[condition['condition_field']]) ? '"' + data.creator[condition['condition_field']] + '"' : '';
        editRefidConditionFieldValue = (userDetail[condition['condition_field']]) ? '"' + userDetail[condition['condition_field']] + '"' : '';
        if (!_.isEmpty(editRefidConditionValue) && !_.isEmpty(editRefidConditionFieldValue)) {
          switch (condition['joining_rules']) {
            case 'NA':
            case 'AND':
              editRefidConditions += (condition['condition_op'] === 'like') ? _.startsWith(editRefidConditionFieldValue, editRefidConditionValue) + " && " : "(" + editRefidConditionFieldValue + condition['condition_op'] + editRefidConditionValue + ") && ";
              break;
            case 'OR':
              editRefidConditions += (condition['condition_op'] === 'like') ? _.startsWith(editRefidConditionFieldValue, editRefidConditionValue) + " || " : "(" + editRefidConditionFieldValue + condition['condition_op'] + editRefidConditionValue + ") || ";
              break;
          }
        }
      }
    });

    editRefidConditions = this.rtrim(this.rtrim(editRefidConditions, ' && '), ' || ');

    editCustomCondition.forEach((condition: any, index: number) => {
      //console.log('editCustomCondition', editCustomCondition)
      if (!_.isEmpty(condition['edit_custom_condition_field']) && !_.isEmpty(condition['condition_op']) && !_.isEmpty(condition['condition_value'])) {
        //console.log('editCustomCondition::', editCustomCondition)
        condition_value = condition.hasOwnProperty('condition_value') && (condition['condition_value']) ? condition['condition_value'] : '';
        editConditionValue = _.isNumber(condition_value) ? condition_value : '"' + condition_value + '"';

        edit_custom_condition_field = (data[condition['edit_custom_condition_field']]) ? data[condition['edit_custom_condition_field']] : '';
        editConditionFieldValue = _.isNumber(edit_custom_condition_field) ? edit_custom_condition_field : '"' + edit_custom_condition_field + '"';

        if ((editConditionValue) && (editConditionFieldValue)) {
          switch (condition['joining_rules']) {
            case 'NA':
            case 'AND':
              editCustomConditions += (condition['condition_op'] === 'like') ? _.startsWith(editConditionFieldValue, editConditionValue) + " && " : "(" + editConditionFieldValue + condition['condition_op'] + editConditionValue + ") && ";
              break;
            case 'OR':
              editCustomConditions += (condition['condition_op'] === 'like') ? _.startsWith(editConditionFieldValue, editConditionValue) + " || " : "(" + editConditionFieldValue + condition['condition_op'] + editConditionValue + ") || ";
              break;
          }
        }
      }
    });

    editCustomConditions = this.rtrim(this.rtrim(editCustomConditions, ' && '), ' || ');

    let editConditions = '';
    let condition_field;
    let conditionValue;

    editIdCondition.forEach((condition: any, index: number) => {
        if ((!_.isEmpty(condition['condition_field']) && !_.isEmpty(condition['condition_op']) && !_.isEmpty(condition['condition_value']))
          || (!_.isEmpty(condition['edit_condition_table_field']))) {



          condition_field = (userDetail[condition['condition_field']]) ? userDetail[condition['condition_field']] : '';
          //console.log('condition_field::', condition_field);

          editConditionFieldValue = _.isNumber(condition_field) ? condition_field : '"' + condition_field + '"';

          //console.log('editConditionFieldValue::', editConditionFieldValue);

          conditionValue = condition.hasOwnProperty('condition_value') && !_.isEmpty(condition['condition_value']) ? condition['condition_value'] : data[condition['edit_condition_table_field']];
          if (condition['condition_op'] === 'in') {
            editConditionValue = conditionValue.split(",");
            if(editConditionValue.includes(userDetail[condition['condition_field']].toString())) {
              editConditionValue = editConditionFieldValue;
            } else {
              editConditionValue = 0;
            }
            condition['condition_op'] = '==';
          } else {
            conditionValue = (conditionValue) ? conditionValue : '';
            editConditionValue = _.isNumber(conditionValue) ? conditionValue : '"' + conditionValue + '"';
          }

          if ((editConditionValue) && (editConditionFieldValue)) {
            switch (condition['joining_rules']) {
              case 'NA':
              case 'AND':
                editConditions += "(" + editConditionFieldValue + condition['condition_op'] + editConditionValue + ") && ";
                break;
              case 'OR':
                editConditions += "(" + editConditionFieldValue + condition['condition_op'] + editConditionValue + ") || ";
                break;
            }
          }
        }
    });


    editConditions = this.rtrim(this.rtrim(editConditions, ' && '), ' || ');

    let disableEditButton = false;

    if (_.isEmpty(editConditions) && _.isEmpty(editCustomConditions) && !_.isEmpty(editRefidConditions) && actionPermission.includes("Edit")
      && this.check(editRefidConditions)) {
      disableEditButton = true;
    }

    if (_.isEmpty(editConditions) && !_.isEmpty(editCustomConditions) && _.isEmpty(editRefidConditions) && actionPermission.includes("Edit")
      && this.check(editCustomConditions)) {
      disableEditButton = true;
    }

    if (_.isEmpty(editConditions) && !_.isEmpty(editCustomConditions) && !_.isEmpty(editRefidConditions) && actionPermission.includes("Edit")
      && this.check(editCustomConditions) && this.check(editRefidConditions)) {
      disableEditButton = true;
    }

    if (!_.isEmpty(editConditions) && _.isEmpty(editCustomConditions) && _.isEmpty(editRefidConditions) && actionPermission.includes("Edit")
      && this.check(editConditions)) {
      disableEditButton = true;
    }

    if (!_.isEmpty(editConditions) && _.isEmpty(editCustomConditions) && !_.isEmpty(editRefidConditions) && actionPermission.includes("Edit")
      && this.check(editConditions) && this.check(editRefidConditions)) {
      disableEditButton = true;
    }

    if (!_.isEmpty(editConditions) && !_.isEmpty(editCustomConditions) && _.isEmpty(editRefidConditions) && actionPermission.includes("Edit")
      && this.check(editConditions) && this.check(editCustomConditions)) {
      disableEditButton = true;
    }

    if (!_.isEmpty(editConditions) && !_.isEmpty(editCustomConditions) && !_.isEmpty(editRefidConditions) && actionPermission.includes("Edit")
      && this.check(editConditions) && this.check(editCustomConditions) && this.check(editRefidConditions)) {
      disableEditButton = true;
    }

    return disableEditButton;
  },
  checkDeleteButtonPermission: function (actionPermission: any, deleteIdCondition: any, deleteRefidCondition: any, deleteCustomCondition: any, data: any, userDetail: any, enableAdministratorPermissionByDefault: any): boolean {
    let deleteRefidConditions = '';
    let deleteRefidConditionValue;
    let deleteRefidConditionFieldValue;

    if (enableAdministratorPermissionByDefault == '1') {
      return true;
    }

    deleteRefidCondition.forEach((condition: any, index: number) => {
      if (!_.isEmpty(condition['condition_field']) && !_.isEmpty(condition['condition_op'])) {
        deleteRefidConditionValue = (data.creator[condition['condition_field']]) ? '"' + data.creator[condition['condition_field']] + '"' : '';
        deleteRefidConditionFieldValue = (userDetail[condition['condition_field']]) ? '"' + userDetail[condition['condition_field']] + '"' : '';

        if (!_.isEmpty(deleteRefidConditionValue) && !_.isEmpty(deleteRefidConditionFieldValue)) {
          switch (condition['joining_rules']) {
            case 'NA':
            case 'AND':
              deleteRefidConditions += (condition['condition_op'] === 'like') ? _.startsWith(deleteRefidConditionFieldValue, deleteRefidConditionValue) + " && " : "(" + deleteRefidConditionFieldValue + condition['condition_op'] + deleteRefidConditionValue + ") && ";
              break;
            case 'OR':
              deleteRefidConditions += (condition['condition_op'] === 'like') ? _.startsWith(deleteRefidConditionFieldValue, deleteRefidConditionValue) + " || " : "(" + deleteRefidConditionFieldValue + condition['condition_op'] + deleteRefidConditionValue + ") || ";
              break;
          }
        }
      }
    });

    deleteRefidConditions = this.rtrim(this.rtrim(deleteRefidConditions, ' && '), ' || ');

    let deleteCustomConditions = '';
    let condition_value;
    let deleteConditionValue;
    let delete_custom_condition_field;
    let deleteConditionFieldValue = '';
    deleteCustomCondition.forEach((condition: any, index: number) => {
      if (!_.isEmpty(condition['delete_custom_condition_field']) && !_.isEmpty(condition['condition_op']) && !_.isEmpty(condition['condition_value'])) {
        //console.log('condition:', condition);
        condition_value = condition.hasOwnProperty('condition_value') && (condition['condition_value']) ? condition['condition_value'] : '';
        deleteConditionValue = _.isNumber(condition_value) ? condition_value : '"' + condition_value + '"';

        delete_custom_condition_field = (data[condition['delete_custom_condition_field']]) ? data[condition['delete_custom_condition_field']] : '';
        deleteConditionFieldValue = _.isNumber(delete_custom_condition_field) ? delete_custom_condition_field : '"' + delete_custom_condition_field + '"';

        if ((deleteConditionValue) && (deleteConditionFieldValue)) {
          switch (condition['joining_rules']) {
            case 'NA':
            case 'AND':
              deleteCustomConditions += (condition['condition_op'] === 'like') ? _.startsWith(deleteConditionFieldValue, deleteConditionValue) + " && " : "(" + deleteConditionFieldValue + condition['condition_op'] + deleteConditionValue + ") && ";
              break;
            case 'OR':
              deleteCustomConditions += (condition['condition_op'] === 'like') ? _.startsWith(deleteConditionFieldValue, deleteConditionValue) + " || " : "(" + deleteConditionFieldValue + condition['condition_op'] + deleteConditionValue + ") || ";
              break;
          }
        }

      }
    });

    deleteCustomConditions = this.rtrim(this.rtrim(deleteCustomConditions, ' && '), ' || ');

    let deleteConditions = '';
    let condition_field;
    let conditionValue;

    deleteIdCondition.forEach((condition: any, index: number) => {
      if (
        !_.isEmpty(condition['condition_field']) && !_.isEmpty(condition['condition_op']) && (
          (!_.isEmpty(condition['condition_value']) && _.isEmpty(condition['condition_value'] !== ''))
            || (!_.isEmpty(condition['delete_condition_table_field']))
          )) {


        condition_field = (userDetail[condition['condition_field']]) ? userDetail[condition['condition_field']] : '';
        deleteConditionFieldValue = _.isNumber(condition_field) ? condition_field : '"' + condition_field + '"';


        conditionValue = condition.hasOwnProperty('condition_value') && !_.isEmpty(condition['condition_value']) ? condition['condition_value'] : data[condition['delete_condition_table_field']];

        if (condition['condition_op'] === 'in') {
          deleteConditionValue = conditionValue.split(",");
          if (deleteConditionValue.includes(userDetail[condition['condition_field']].toString())) {
            deleteConditionValue = deleteConditionFieldValue;
          } else {
            deleteConditionValue = 0;
          }

          condition['condition_op'] = '==';
        } else {
          conditionValue = (conditionValue) ? conditionValue : '';
          deleteConditionValue = _.isNumber(conditionValue) ? conditionValue : '"' + conditionValue + '"';
        }




        if ((deleteConditionValue) && (deleteConditionFieldValue)) {
          switch (condition['joining_rules']) {
            case 'NA':
            case 'AND':
              deleteConditions += "(" + deleteConditionFieldValue + condition['condition_op'] + deleteConditionValue + ") && ";
              break;
            case 'OR':
              deleteConditions += "(" + deleteConditionFieldValue + condition['condition_op'] + deleteConditionValue + ") || ";
              break;
          }
        }
      }
    });

    deleteConditions = this.rtrim(this.rtrim(deleteConditions, ' && '), ' || ');

    let disableDeleteButton = false;

    if (_.isEmpty(deleteConditions) && _.isEmpty(deleteCustomConditions) && !_.isEmpty(deleteRefidConditions) &&
      actionPermission.includes("Delete") && this.check(deleteRefidConditions)) {
      disableDeleteButton = true;
    }

    if (_.isEmpty(deleteConditions) && !_.isEmpty(deleteCustomConditions) && _.isEmpty(deleteRefidConditions) &&
      actionPermission.includes("Delete") && this.check(deleteCustomConditions)) {
      disableDeleteButton = true;
    }

    if (_.isEmpty(deleteConditions) && !_.isEmpty(deleteCustomConditions) && !_.isEmpty(deleteRefidConditions) &&
      actionPermission.includes("Delete") && this.check(deleteCustomConditions) && this.check(deleteRefidConditions)) {
      disableDeleteButton = true;
    }

    if (!_.isEmpty(deleteConditions) && deleteCustomConditions == '' && deleteRefidConditions == '' &&
      actionPermission.includes("Delete") && this.check(deleteConditions)) {
      disableDeleteButton = true;
    }

    if (!_.isEmpty(deleteConditions) && _.isEmpty(deleteCustomConditions) && !_.isEmpty(deleteRefidConditions) &&
      actionPermission.includes("Delete") && this.check(deleteConditions) && this.check(deleteRefidConditions)) {
      disableDeleteButton = true;
    }

    if (!_.isEmpty(deleteConditions) && !_.isEmpty(deleteCustomConditions) && _.isEmpty(deleteRefidConditions) &&
      actionPermission.includes("Delete") && this.check(deleteConditions) && this.check(deleteCustomConditions)) {
      disableDeleteButton = true;
    }

    if (!_.isEmpty(deleteConditions) && !_.isEmpty(deleteCustomConditions) && !_.isEmpty(deleteRefidConditions) &&
      actionPermission.includes("Delete") && this.check(deleteConditions) && this.check(deleteCustomConditions) && this.check(deleteRefidConditions)) {
      disableDeleteButton = true;
    }

    return disableDeleteButton;
  },*/
  check: function(conditions: string): any
  {
    let code = ''+ conditions + ';';
    //console.log(code);
    return eval(code);
  },
  inArray: function (needle: any, haystack: any) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] == needle) return true;
    }
    return false;
  },
  rtrim: function(str: any, chr: any) {
    var rgxtrim = (!chr) ? new RegExp('\\s+$') : new RegExp(chr+'+$');
    return str.replace(rgxtrim, '');
  },
  getInitials: function (word: any, length: any = 1) {
    return word.substring(0, length === undefined ? 1 : length)
  },
  initials: function (words: any, length: any = 1) {
    let wordsArray, initials;
    wordsArray = words === undefined ? [] : words.split(' ')

    if (length === undefined) {
      initials = wordsArray.map((word: any) => this.getInitials(word, length)).join('')
    } else {
      initials =
        wordsArray.length === 1
          ? this.getInitials(wordsArray[0], length)
          : wordsArray
            .splice(0, length)
            .map((word: any) => this.getInitials(word))
            .join('')
    }

    return initials.toUpperCase()
  }
}
