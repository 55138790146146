<template>
  <div>
    <div class="form-row">
      <div class="form-group col-md-10">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="enable_aggregate_function"
            true-value="yes"
            false-value="no"
          />
          <span class="checkmark"></span>&nbsp; Have aggregate function?
        </label>
      </div>
    </div>
    <div class="form-row">
      <div
        class="form-group col-md-12"
        v-if="enable_aggregate_function == 'yes'"
      >
        <label class="control-label mb-10">Select Operation</label>
        <table class="table table-bordered has-aggregate-function">
          <thead>
            <th style="width: 150px">Operation</th>
            <th>Column</th>
            <th>Alias</th>
            <th>Is Sortable?</th>
            <th style="width: 100px"></th>
          </thead>
          <tbody>
            <tr v-for="(select_aggregate, index) in aggregates" :key="index">
              <td>
                <select
                  class="form-control"
                  style="width: 100%"
                  v-model="select_aggregate.aggregate_function"
                >
                  <option value="SUM">SUM</option>
                  <option value="MAX">MAX</option>
                  <option value="MIN">MIN</option>
                  <option value="COUNT">COUNT</option>
                </select>
              </td>
              <td>
                <select
                  class="form-control extra-operation-column"
                  style="width: 100%"
                  v-model="select_aggregate.column"
                >
                  <option value=""></option>
                  <option
                    v-for="(option, index_op) in select_options"
                    v-bind:value="option"
                    :key="index_op"
                  >
                    {{ option }}
                  </option>
                </select>
              </td>
              <td>
                <input class="form-control" v-model="select_aggregate.alias" />
              </td>
              <td>
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="select_aggregate.is_sortable"
                    true-value="yes"
                    false-value="no" />
                  <span class="checkmark"></span
                ></label>
              </td>
              <td>
                <button
                  type="button"
                  @click="deleteAggregate(index)"
                  class="btn btn-danger btn-sm pull-right"
                  data-class="aggregate-1"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-sm-12" v-if="enable_aggregate_function == 'yes'">
      <div class="btn btn-success btn-sm" @click="addAggregate">
        <i class="fa fa-plus"></i> New
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["select_aggregates", "select_options", "have_aggregate_function"],
  data: () => ({
    aggregates: [
      {
        aggregate_function: "",
        column: "",
        alias: "",
        is_sortable: "no",
      },
    ],
    enable_aggregate_function: "no",
  }),
  methods: {
    addAggregate: function () {
      this.aggregates.push({
        aggregate_function: "",
        column: "10",
        alias: "",
        is_sortable: "no",
      });
    },
    deleteAggregate: function (index) {
      this.aggregates.splice(index, 1);
    },
  },
  watch: {
    select_aggregates: function (val, oldVal) {
      this.aggregates = val;
    },
    have_aggregate_function: function (val, oldVal) {
      this.enable_aggregate_function = val;
    },
  },
  updated() {
    this.$emit("updateAggregates", {
      select_aggregates: this.aggregates,
      have_aggregate_function: this.enable_aggregate_function,
    });
  },
};
</script>
