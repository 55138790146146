import { RouteConfig } from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

let routePath = '/ext-cbs-user-management';
let viewPath = '/CBS/UserManagement'

const routes: Array<RouteConfig> = [
  {
    path: '/admin' + routePath + '/user_payment_method',
    name: 'UserPaymentMethodList',
    component: () => import('../views'+viewPath+'/UserPaymentMethod/UserPaymentMethodList.vue'),
    meta: {layout: MasterLayout, requireAuth: true}
  },
  {
    path: '/admin' + routePath + '/user_payment_method/add',
    name: 'UserPaymentMethodAdd',
    component: () => import('../views'+viewPath+'/UserPaymentMethod/UserPaymentMethodForm.vue'),
    meta: {layout: MasterLayout, requireAuth: true}
  },
  {
    path: '/admin' + routePath + '/user_payment_method/:id/edit',
    name: 'UserPaymentMethodEdit',
    component: () => import('../views'+viewPath+'/UserPaymentMethod/UserPaymentMethodForm.vue'),
    meta: {layout: MasterLayout, requireAuth: true}
  },
  {
    path: '/admin' + routePath + '/user_payment_method/:id/show',
    name: 'UserPaymentMethodShow',
    component: () => import('../views'+viewPath+'/UserPaymentMethod/UserPaymentMethodShow.vue'),
    meta: {layout: MasterLayout, requireAuth: true}
  },
];

export default routes;
