// Register
import Login from '@/views/Register/Login.vue'
import {RouteConfig} from "vue-router";

const registerRoutes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    meta: {title: 'Login Page - App', guest: true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Register/Login.vue')
  },
  {
    path: "/admin/forgot-pass",
    name: "ForgotPass",
    component: () => import("../views/Register/ForgetPassword.vue"),
    meta: {
      requireAuth: false
    }
  },
  {
    path: "/admin/reset-pass",
    name: "ResetPass",
    component: () => import("../views/Register/ResetPassword.vue"),
    meta: {
      requireAuth: false
    }
  },
];

export default registerRoutes;
