<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Home Page</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Blank Page</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content">
      <div class="container-fluid">
        
      </div>


    </section>
  </div>
</template>

<script>
import localStorage from "@/services/localStorage";

export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'Dashboard | Dotlines'
  },
  mounted() {
    if( this.isMobile()) {
      window.$(document).find('.push-menu-class').trigger('click');
    }
    const windowReload = localStorage.get('window_reload');
    if(windowReload) {
      localStorage.set('window_reload', false);
      window.location.reload();
    }
  }
}
</script>
