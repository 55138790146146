<template>
<!--  <ul class="pagination pagination-sm m-0 float-right">
    <li v-if="pagination.current_page > 1" class="paginate_button page-item previous">
      <button class="page-link" aria-label="Previous" v-on:click.prevent="changePage(pagination.current_page - 1)">
        <span aria-hidden="true">« Previous</span>
      </button>
    </li>
    <li v-for="page in pagesNumber" class="paginate_button page-item" :class="{'active': page === pagination.current_page}">
      <button class="page-link" v-on:click.prevent="changePage(page)">{{ page }}</button>
    </li>
    <li v-if="pagination.current_page < pagination.last_page" class="paginate_button page-item next">
      <button class="page-link" aria-label="Next" v-on:click.prevent="changePage(pagination.current_page + 1)">
        <span aria-hidden="true">Next »</span>
      </button>
    </li>
  </ul>-->

  <el-pagination
    background
    layout="prev, pager, next"
    @current-change="changePage"
    :page-size="pagination.per_page"
    :pager-count="5"
    :total="pagination.total"
    :current-page="pagination.current_page"
    class="float-right"
  >
  </el-pagination>
</template>
<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true
    },
    offset: {
      type: Number,
      default: 4
    }
  },
  computed: {
    pagesNumber () {
      if (!this.pagination.to) {
        return []
      }
      let from = this.pagination.current_page - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + (this.offset * 2)
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page
      }
      const pagesArray = []
      for (let page = from; page <= to; page++) {
        pagesArray.push(page)
      }
      return pagesArray
    }
  },
  methods: {
    changePage (page) {
      this.pagination.current_page = page
      // this.$router.push({ path: this.$route.fullPath, query: { page: page } })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ path: this.$route.fullPath, query: { page: page } }).catch(() => {})
      this.$emit('paginate')
    }
  }
}
</script>
