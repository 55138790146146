<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div class="offer-details-wrap" v-else>
      <div class="offer-placeholder">
        <div class="placeholder-inner">
          <h2>{{ !_.isEmpty(dataDetails) ? dataDetails.offerDetail.pack_limit : '' }}</h2>
          <h6><span>GB</span> {{ !_.isEmpty(dataDetails) ? dataDetails.offerDetail.body : '' }}</h6>
        </div>
        <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.offerDetail.title : '' }}</h4>
      </div>
      <div class="offer-add" v-if="unlimitedBox"></div>
      <div class="unlimited-box">
        <h5 v-html="!_.isEmpty(dataDetails) ? dataDetails.offerDetail.unlimited_offer : ''"></h5>
      </div>

      <div class="offer-details-text">

        <h3><span>{{ !_.isEmpty(dataDetails) ? dataDetails.offerDetail.validity : '' }}</span></h3>
        <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.offerDetail.price : '' }}</h4>
        <p v-html="!_.isEmpty(dataDetails) ? dataDetails.offerDetail.bonus_offer : ''"></p> <strong>
        <a id="activate" class="btn" data-option_id="102" data-service_id="9" :href="'tel:'+ dataDetails.offerDetail.tel"
           >{{
            !_.isEmpty(dataDetails) ? dataDetails.offerDetail.button_text : ''
          }}</a>
      </strong></div>
      <strong>
      </strong></div>
    <strong>
    </strong></div>
</template>

<script>
import axios from 'axios'
import {saveUserJourney} from './../../services/api/saveUserJourney'

export default {
  name: 'internetServiceDetails',
  data() {
    return {
      unlimitedBox: '',
      apiUrl: 'content/offer-details-page/',
      dataDetails: {},
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      service: {},
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn')
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.apiUrl += this.$route.params.id
      console.log(this.apiUrl)
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      this.unlimitedBox = this.dataDetails.offerDetail.unlimited_offer ?? ''
      saveUserJourney(this.operatorId, 'offer_details_page', this.msisdn, this.languageId, this.sessionId)
    },
    getData() {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit(number) {
      console.log('hi', number)
    }
  }

}
</script>

<style scoped>

</style>
