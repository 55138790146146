<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="otp-wrapper">
        <form id="form" class="response-alert">
          <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.apnar_mobile_no_likhun_text : '' }}</label>
          <div class="receiver-number digi-number">
            <input type="text" v-model="msisdn" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" name="mobile_no" id="mobile_no" placeholder="" style="text-align:left"
                   minlength="9" maxlength="11" autocomplete="off" class="form-control">
            <span class="number-prefix">+6</span>
          </div>
          <!--          <div class="terms-by-clicking">-->
          <!--            <strong>‘সাবমিট’</strong> ক্লিক করে আপনি সহজ <a href="javascript:void(0);"-->
          <!--                                                            id="terms_of_use_msisdn">শর্তাবলী</a> এর সাথে সহমত পোষণ-->
          <!--            করছেন।-->
          <!--          </div>-->
          <button type="submit" name="submit" id="submit" value="1" class="btn justify-center"
                  @click.prevent="checkOperator()">{{ !_.isEmpty(dataDetails) ? dataDetails.submit_text : '' }}
          </button>
        </form>
      </div>
      <div id="wrong_message"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'

export default {
  name: 'input-msisdn-page',
  data () {
    return {
      inputMsisdnApiUrl: '/content/input-msisdn-page',
      operatorCheckUrl: '/is_dg_user',
      registrationCheckUrl: '/check-reg-status',
      msisdn: null,
      isDigiResponse: {},
      inputMsisdnData: {},
      is_loading: true,
      languageId: sessionStorage.getItem('language') ?? '1',
      service: this.$route.params.service,
      dataDetails: {},
      sessionId: sessionStorage.getItem('sessionId'),
      serviceType: sessionStorage.getItem('serviceType'),
      operatorId: localStorage.getItem('operatorId'),
      subscriptionStatus: ''

    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      await this.getMsisdnDataDetails().then(response => {
        this.dataDetails = response.data
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      this.is_loading = false
      updateEvent(pageID, eventID)
    },
    getMsisdnDataDetails () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.inputMsisdnApiUrl, { languageId: this.languageId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response)
            } else {
              //
            }
          })
          .finally(() => {
          })
      })
    },
    getOperatorDetails () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.operatorCheckUrl, { msisdn: this.msisdn })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response)
            } else {
              //
            }
          })
          .finally(() => {
          })
      })
    },
    async checkOperator () {
      await this.getOperatorDetails().then(response => {
        this.isDigiResponse = response.data
      })
      if (this.operatorId == 1) {
        if (this.isDigiResponse == 2001) {
          this.submit()
        } else {
          this.$toaster.error('Please Give Vaild Digi Number !')
        }
      } else if (this.operatorId == 2) {
        if (this.isDigiResponse == 105) {
          this.submit()
        } else {
          this.$toaster.error('Please Give Vaild U Mobile Number !')
        }
      }
    },
    getRegistrationStatus () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.registrationCheckUrl, null, {
            params: {
              msisdn: this.msisdn,
              operatorId: this.operatorId,
              service: this.service
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    async submit () {
      if (this.serviceType === 'input' || this.serviceType === 'transaction' || this.service === 'history' || this.service === 'subscription' || this.serviceType === 'favourite') {
        this.$router.push({ name: 'otpPage', params: { msisdn: this.msisdn } })
      } else if (this.serviceType === 'topup') {
        localStorage.setItem('msisdn', this.msisdn)
        this.$router.push({ name: 'package-review-page', params: { service: this.service } })
      } else if (this.service === 'support') {
        localStorage.setItem('msisdn', this.msisdn)
        sessionStorage.setItem('service', 'support')
        this.$router.push({ name: 'otpPage' })
      } else if (this.serviceType === 'refer') {
        localStorage.setItem('msisdn', this.msisdn)
        this.$router.push({ name: 'refer-friend', params: { service: this.service } })
      } else {
        this.is_loading = true
        localStorage.setItem('msisdn', this.msisdn)
        await this.getRegistrationStatus().then(response => {
          this.subscriptionStatus = response.data
        })
        if (this.subscriptionStatus === 0) {
          this.$router.go(-1)
        } else {
          this.$router.push({ name: 'service-confirmation-page', params: { service: this.service } })
        }
      }
      saveUserJourney(this.operatorId, 'input_msisdn_page', this.msisdn, this.languageId, this.sessionId)
    },
    getAlert (msg) {
      $('.response-alert').prepend(
        $(
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
          msg +
          '    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
          '       <span aria-hidden="true">&times;</span>\n' +
          '    </button>\n' +
          '</div>'
        )
      )
    }
  }

}
</script>

<style scoped>

</style>
