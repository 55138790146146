import { RouteConfig } from 'vue-router'

// MasterLayout
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue'
import HomeLayout from '@/components/layouts/HomeLayoutContent.vue'

// Register
import registerRoutes from './register'
import defaultRoutes from './default'
import departmentRoutes from './department'
import serviceRoutes from './services'
import Home from '@/views/Home.vue'
import organizationRoutes from './organization'
import permissionRoutes from './permission'
import rolesRoutes from './roles'
import reportRoutes from './report'
import customRoutesPermissionRoutes from './customRoutesPermission'
import databaseConnectionRoutes from './databaseConnection'

// CBS Routes
import CBSAPIGatewayRoutes from './CBSApiGateway'
import CBSAccountingRoutes from './CBSAccounting'
import CBSCommissionRoutes from './CBSCommission'
import CBSPaymentRoutes from './CBSPayment'
import CBSProductRoutes from './CBSProduct'
import operationalLogs from './operational_logs'
import CBSProductRatingRoutes from './CBSProductRating'
import CBSUserManagementRoutes from './CBSUserManagement'
import CBSSubscriptionRoutes from './CBSSubscription'
import CBSInvoice from './CBSInvoice'
import FrontHome from '@/views/FrontHome.vue'
import Operator from '@/views/operator/operator.vue'
import servicePage from '@/views/content/servicePage.vue'
import Topup from '@/views/content/topup/top-up.vue'
import Input from '@/views/content/input-msisdn-page.vue'
import ReferFriend from '@/views/content/refer-friend.vue'
import ServiceConfirmation from '@/views/content/service-confirmation-page.vue'
import serviceRegistration from '@/views/content/serviceRegistration.vue'
import listService from '@/views/content/listService.vue'
import dialService from '@/views/content/dialService.vue'
import internetService from '@/views/content/internetService.vue'
import internetServiceDetails from '@/views/content/internetServiceDetails.vue'
import packageService from '@/views/content/packageService.vue'
import cinemaServiceDetails from '@/views/content/cinemaServiceDetails.vue'
import listServiceDetails from '@/views/content/listServicesDetails.vue'
import favouriteNumber from '@/views/content/topup/favouriteNumberAdd.vue'
import favouriteNumberList from '@/views/content/topup/favouriteNumberList.vue'
import otoPage from '@/views/content/topup/otpPage.vue'
import freeService from '@/views/content/freeService.vue'
import unsubConfirmationPage from '@/views/content/unsubConfirmationPage.vue'
import serviceDeRegistration from '@/views/content/serviceDeRegistration.vue'
import umobileSuccessFailed from '@/views/content/umobileSuccessFailed.vue'
import supportHome from "@/views/content/supportHome.vue";
import ticketSuccessPage from "@/views/content/ticketSuccessPage.vue";
import supportHistory from "@/views/content/supportHistory.vue";
import noticePage from "@/views/content/noticePage.vue";
import campaign from "@/views/content/campaign.vue";

const baseRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'FrontHome',
    component: FrontHome,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/m',
    name: 'FrontHome',
    component: FrontHome,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/home/:op?',
    name: 'FrontHomeOp',
    component: FrontHome,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/operator/:id',
    name: 'Operator',
    component: Operator

  },
  {
    path: '/admin',
    name: 'Home',
    component: Home,
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/subscription-services/:service?',
    name: 'servicePage',
    component: servicePage,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/list-service/:service',
    name: 'ListService',
    component: listService,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/internet-services/:service',
    name: 'InternetService',
    component: internetService,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/internet-services/internet/:id',
    name: 'internetServiceDetails',
    component: internetServiceDetails,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/cinema-services/details',
    name: 'cinemaServiceDetails',
    component: cinemaServiceDetails,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/multiple-service/details/:service',
    name: 'multiple-service-details',
    component: () => import('@/views/content/multipleServiceDetails.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/list-services-details/:service',
    name: 'listServiceDetails',
    component: listServiceDetails,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/package-services/:service',
    name: 'packageService',
    component: packageService, // like hoichoi
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/free-services/:id',
    name: 'freeService',
    component: freeService,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/topup-services/:service',
    name: 'topUp',
    component: Topup,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/subscription-services/dial/:service',
    name: 'dialService',
    component: dialService,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/refer-friend',
    name: 'refer-friend',
    component: ReferFriend,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/support-page',
    name: 'support-page',
    component: supportHome,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/support-history',
    name: 'support-history',
    component: supportHistory,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/notice-page',
    name: 'notice-page',
    component: noticePage,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: campaign
    //meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/ticket-success',
    name: 'ticket-success',
    component: ticketSuccessPage,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/input-msisdn-page/:service',
    name: 'input-msisdn-page',
    component: Input,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/otp-page/:msisdn?',
    name: 'otpPage',
    component: otoPage,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/topup-services/topup/:type',
    name: 'topUpList',
    component: () => import('@/views/content/topup/topUpList.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/topup-services/topup/packages/:type',
    name: 'topUpPackages',
    component: () => import('@/views/content/topup/topUpPackages.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/topup-services/topup/packages/review/:service',
    name: 'package-review-page',
    component: () => import('@/views/content/topup/packageReviewPage.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/wallet-select-page/:service',
    name: 'wallet-select-page',
    component: () => import('@/views/content/topup/walletSelectPage.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/wallet-confirmation-page/:service',
    name: 'wallet-confirmation-page',
    component: () => import('@/views/content/topup/walletConfirmationPage.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/wallet-submit-page/:reason',
    name: 'topup-success-failed-page',
    component: () => import('@/views/content/topup/topupSuccessFailed.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/user-history',
    name: 'userHistory',
    component: () => import('@/views/content/topup/userHistory.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/my-subscription',
    name: 'mySubscription',
    component: () => import('@/views/content/mySubscription.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/terms-conditions',
    name: 'termsAndCondition',
    component: () => import('@/views/content/termsAndCondition.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: () => import('@/views/content/aboutUs.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/favourite-number-add',
    name: 'favourite-number-add',
    component: favouriteNumber,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/favourite-number-view',
    name: 'favourite-number-view',
    component: favouriteNumberList,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/multiple-serivce/:service',
    name: 'multiple-service',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/content/multipleService.vue'),
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/service-confirmation-page',
    name: 'service-confirmation-page',
    component: ServiceConfirmation,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/unsub-confirmation-page/:service?',
    name: 'unsubConfirmationPage',
    component: unsubConfirmationPage,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/service-registration',
    name: 'serviceRegistration',
    component: serviceRegistration,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/service-deregistration',
    name: 'serviceDeRegistration',
    component: serviceDeRegistration,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/umobile/:pageType/:type/:service?',
    name: 'umobileSuccessFailed',
    component: umobileSuccessFailed,
    meta: { layout: HomeLayout, requireAuth: false }
  },
  {
    path: '/admin/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/users',
    name: 'Users',
    component: () => import('../views/User/UserList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/users/add',
    name: 'UserAdd',
    component: () => import('../views/User/UserForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/users/edit/:id',
    name: 'UserEdit',
    component: () => import('../views/User/UserForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/users/view/:id',
    name: 'UserView',
    component: () => import('../views/User/UserView.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/profile',
    name: 'AuthProfile',
    component: () => import('../views/User/AuthProfile.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/notifications',
    name: 'AuthNotifications',
    component: () => import('../views/User/AuthNotifications.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/notifications/:id/show',
    name: 'AuthNotificationShow',
    component: () => import('../views/User/AuthNotificationShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/update-password',
    name: 'AuthChangePassword',
    component: () => import('../views/User/UpdatePassword.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin/auth/ext/change-password',
    name: 'UserPasswordReset',
    component: () => import('../views/User/UserPasswordReset.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  }
  // ##Routes## //
]

const routes = baseRoutes.concat(
  registerRoutes,
  defaultRoutes,
  departmentRoutes,
  serviceRoutes,
  organizationRoutes,
  permissionRoutes,
  rolesRoutes,
  operationalLogs,
  reportRoutes,
  customRoutesPermissionRoutes,
  databaseConnectionRoutes,

  // CBS Routes
  CBSAPIGatewayRoutes,
  CBSAccountingRoutes,
  CBSCommissionRoutes,
  CBSPaymentRoutes,
  CBSProductRoutes,
  CBSProductRatingRoutes,
  CBSUserManagementRoutes,
  CBSSubscriptionRoutes,
  CBSInvoice
)

export default routes
