
import { Header, Navbar, LeftSidebar, Footer } from '@/components/partials'
export default {
  name: 'App',
  components: {
    Header,
    Navbar,
    LeftSidebar,
    Footer
  },
  computed: {
    //
  },
  data () {
    return {
      audioLoaded: false,
      isPlaying: false
    }
  },
  // data: () => ({
  //   layout: "div",
  // }),
  mounted: function () {
    // console.log('App component mounted.');
    // let audioPlayer = this.$refs.player;
    // if (audioPlayer.paused) {
    //   console.log('Playing.')
    //   audioPlayer.play()
    //   this.isPlaying = true
    // } else {
    //   audioPlayer.pause()
    //   this.isPlaying = false
    // }
  },
  methods: {
    // toggleAudio () {
    //   var audioPlayer = this.$refs.player
    //   // var audio = document.getElementById("audio-player");
    //   if (audioPlayer.paused) {
    //     audioPlayer.play()
    //     this.isPlaying = true
    //   } else {
    //     audioPlayer.pause()
    //     this.isPlaying = false
    //   }
    // }
  },
  // created() {
  //   //
  // },
}
