import AuthApi from '@/services/api/auth-api'
import axios from 'axios'

const state = {
  profile: {},
  lang: {},
  isAudioPlaying: false
}

const getters = {
  profile (state: { profile: any; }) {
    return state.profile
  },
  lang (state: { lang: any }) {
    return state.lang
  }
}

const actions = {
  FETCH_PROFILE (context: { commit: (arg0: string, arg1: any) => void; }) {
    AuthApi.fetchProfile().then((response) => {
      context.commit('SET_PROFILE', response)
    })
  },
  GET_LANGUAGE (context: { commit: (arg0: string, arg1: any) => void; }, operatorId: number) {
    axios.get('/get-language' + '/' + operatorId).then(response => {
      context.commit('SET_LANGUAGE', response.data)
    })
  }
}

const mutations = {
  SET_PROFILE (state: { profile: any }, payload: any) {
    state.profile = payload
  },
  SET_LANGUAGE (state: { lang: any }, payload: any) {
    state.lang = payload
  },
  toggleAudio (state: { isAudioPlaying: any }) {
    state.isAudioPlaying = !state.isAudioPlaying
  }
}

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters
}
