import axios from 'axios'

export default {
  getAllApplications () {
    return new Promise((resolve, reject) => {
      axios.get('/ext-cbs-api-gateway/all-applications').then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAllAccount () {
    return new Promise(resolve => {
      axios.get('/cbs/accounting/all-account').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllGeneralLedgerAccount () {
    return new Promise(resolve => {
      axios.get('/cbs/accounting/all-general-ledger-account').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllCommissionPlan () {
    return new Promise(resolve => {
      axios.get('/cbs/commission/all-commission-plan').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllConditionName () {
    return new Promise(resolve => {
      axios.get('/cbs/commission/all-condition-name').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllCommissionConfig () {
    return new Promise(resolve => {
      axios.get('/cbs/commission/all-commission-config').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllProductCategory () {
    return new Promise(resolve => {
      axios.get('/cbs/product/all-product-category').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllProducts () {
    return new Promise(resolve => {
      axios.get('/cbs/product/all-product').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllRatingPlan () {
    return new Promise(resolve => {
      axios.get('/cbs/product-rating/all-rating-plan').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllRatingType () {
    return new Promise(resolve => {
      axios.get('/cbs/product-rating/all-rating-type').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllRatingConditionName () {
    return new Promise(resolve => {
      axios.get('/cbs/product-rating/all-rating-condition-name').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
  getAllPaymentGateway () {
    return new Promise(resolve => {
      axios.get('/cbs/payment/all-gateway').then(response => {
        resolve(response.data)
      })
        .catch((error) => {
          resolve(error)
        })
    })
  },
}
