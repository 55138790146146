<template>
  <div>
    <div class="col-sm-12">
      <label class="checkbox"
        ><input
          type="checkbox"
          v-model="enable_group_by"
          true-value="yes"
          false-value="no"
          name="have_group_by"
        />
        <span class="checkmark"></span> Wanted to add group by?
      </label>
    </div>
    <div class="form-row" v-if="enable_group_by == 'yes'">
      <div class="form-group col-md-12">
        <table
          class="table table-bordered builder-table has-aggregate-function"
        >
          <thead>
            <th style="width: 150px">Operation</th>
            <th>Column</th>
            <th>Is Raw?</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <select
                  class="form-control"
                  style="width: 100%"
                  v-model="custom_query.extra_operation"
                >
                  <option value="GROUP BY">GROUP BY</option>
                </select>
              </td>
              <td>
                <multiselect
                  v-model="custom_query.columns"
                  :options="select_options"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="Pick a value"
                ></multiselect>
                <input
                  v-if="custom_query.is_raw == 'yes'"
                  class="form-control"
                  v-model="custom_query.columns"
                />
              </td>
              <td>
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="custom_query.is_raw"
                    true-value="yes"
                    false-value="no"
                  />
                  <span class="checkmark"></span> is raw?
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br />
    <div class="col-sm-12">
      <label class="checkbox"
        ><input
          type="checkbox"
          v-model="enable_order_by"
          true-value="yes"
          false-value="no"
          name="have_order_by"
        />
        <span class="checkmark"></span> Wanted to add order by?
      </label>
    </div>
    <div class="form-row" v-if="enable_order_by == 'yes'">
      <div class="form-group col-md-12">
        <table
          class="table table-bordered builder-table has-aggregate-function"
        >
          <thead>
            <th>Column</th>
            <th>Order Type</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <multiselect
                  v-model="custom_query.order_columns"
                  :options="select_options"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="Pick a value"
                ></multiselect>
              </td>
              <td>
                <select
                  class="form-control"
                  style="width: 100%"
                  v-model="custom_query.order_type"
                >
                  <option value="ASC">ASC</option>
                  <option value="DESC">DESC</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br />
    <div class="col-sm-12">
      <label class="checkbox"
        ><input
          type="checkbox"
          v-model="enable_limit"
          true-value="yes"
          false-value="no"
          name="set_limit"
        />
        <span class="checkmark"></span> Wanted to set limit?
      </label>
    </div>
    <div class="form-row" v-if="enable_limit == 'yes'">
      <div class="form-group col-md-12">
        <label for="table" class="control-label">Limit Value</label>
        <input class="form-control" v-model="custom_query.limit_value" />
      </div>
    </div>
    <br />
    <div class="col-sm-12">
      <label class="checkbox"
        ><input
          type="checkbox"
          v-model="enable_custom_action"
          true-value="yes"
          false-value="no"
          name="set_custom_action"
        />
        <span class="checkmark"></span> Wanted to add custom action limit?
        (Works only in grid tables)</label
      >
    </div>

    <div v-if="enable_custom_action == 'yes'">
      <div class="form-group">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="table" class="col-sm-3 control-label"
              >Custom Link</label
            >
            <div class="col-sm-9">
              <input class="form-control" v-model="link_value" />
              <p class="help-block">
                Example (http://localhost/admin/auth/users/{id}/edit)
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="table" class="col-sm-3 control-label">Icon</label>
            <div class="col-sm-9">
              <input class="form-control" v-model="link_icon" />
              <p class="help-block">
                Use font awesome 4 icons Example (fa-address-book)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  props: [
    "query_clause",
    "select_options",
    "have_group_by",
    "have_order_by",
    "set_limit",
    "set_custom_action",
    "add_graph",
    "custom_link_value",
    "custom_icon_value",
  ],
  components: {
    Multiselect,
  },
  data: () => ({
    custom_query: {
      extra_operation: "",
      columns: [],
      order_columns: [],
      order_type: "",
      limit_value: 10,
      is_raw: "no",
    },
    enable_group_by: "no",
    enable_order_by: "no",
    enable_limit: "no",
    enable_custom_action: "no",
    enable_graph: "no",
    link_value: "",
    link_icon: "",
  }),
  methods: {},
  watch: {
    query_clause: function (val, oldVal) {
      this.custom_query = val;
    },
    custom_link_value: function (val, oldVal) {
      this.link_value = val;
    },
    custom_icon_value: function (val, oldVal) {
      this.link_icon = val;
    },
    have_group_by: function (val, oldVal) {
      this.enable_group_by = val;
    },
    have_order_by: function (val, oldVal) {
      this.enable_order_by = val;
    },
    add_graph: function (val, oldVal) {
      this.enable_graph = val;
    },
    set_custom_action: function (val, oldVal) {
      this.enable_custom_action = val;
    },
    set_limit: function (val, oldVal) {
      this.enable_limit = val;
    },
  },
  updated() {
    this.$emit("updateQueryClause", {
      query: this.custom_query,
      link_value: this.link_value,
      link_icon: this.link_icon,
      enable_group_by: this.enable_group_by,
      enable_order_by: this.enable_order_by,
      enable_limit: this.enable_limit,
      enable_custom_action: this.enable_custom_action,
      enable_graph: this.enable_graph,
    });
  },
};
</script>
