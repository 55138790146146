<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Report</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'Home' }">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active">Report</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <!-- Main content -->
        <section class="content" v-if="has_permission == true">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-info"
                            @click="showFilter = !showFilter"
                        ><i class="fa fa-filter"></i>Filter</a
                        >
                        <div class="card-tools">
                            <router-link :to="{ name: 'QueryForm' }" class="btn btn-success">
                                <i class="fas fa-plus"></i> New
                            </router-link>
                        </div>
                    </div>
                    <FilterList
                        v-if="showFilter"
                        :filter="filter"
                        @updateFilter="getFilterData"
                    />
                    <div class="overlay" v-if="is_loading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                    <div class="card-body table-responsive-md p-0">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Report Name</th>
                                <th class="text-right">Action</th>
                            </tr>
                            </thead>
                            <tbody class="users-list">
                            <tr v-for="(val, index) in list.data" :key="index">
                                <td>{{ val.id }}</td>
                                <td>{{ val.report_name }}</td>
                                <td class="text-right">
                                    <!-- <button class="btn btn-info" @click="generateReport">
                                      Generate Report
                                    </button> -->
                                    <router-link
                                        :to="{ name: 'QueryForm', params: { id: val.id } }"
                                        class="btn btn-info"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'QueryForm', params: { id: val.id } }"
                                        class="btn btn-info"
                                    >
                                        <i class="fas fa-pencil-alt"></i>
                                    </router-link>
                                    <a
                                        class="btn btn-danger"
                                        href="javascript:void(0)"
                                        @click="deleteReport(val.id, index)"
                                    ><i class="fas fa-trash"></i
                                    ></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-4" v-if="_.isEmpty(list.data)">
                            <div class="alert alert-default-warning" role="alert">
                                No data found!
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <vue-pagination
                            :pagination="list"
                            v-if="list.total > list.per_page"
                            @paginate="reload()"
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="content" v-if="has_permission == false">
            <div class="alert alert-danger alert-dismissible">
                <h5><i class="icon fas fa-ban"></i> Permission denied!</h5>
            </div>
        </section>
    </div>
</template>

<script>
import VuePagination from '@/components/partials/PaginationComponent'
import { ReportHelper } from '@/services/api'
import { FilterList } from './Components'

export default {
  name: 'QueryList',
  metaInfo: {
    title: 'Report List | Dotlines'
  },
  components: {
    VuePagination,
    FilterList
  },
  data: () => ({
    list: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
      current_page: 1,
      data: []
    },
    api_url: '',
    showFilter: false,
    filter: {},
    dateRange: '',
    pre: [],
    is_loading: false,
    has_permission: true
  }),
  mounted: function () {
    // this.getAllReports()
    this.initialize()
  },
  watch: {
    '$route.query' () {
      this.reload()
    }
  },
  methods: {
    async initialize () {
      this.filter = JSON.parse(JSON.stringify(this.$route.query))
      // eslint-disable-next-line no-undef
      if (!_.isEmpty(this.filter)) {
        this.showFilter = true
      }
      this.is_loading = true

      await this.getAllReports()
        .then((response) => {
          this.list = response.data
        })
        .catch((error) => {
          this.empty = true
          if (error.response.status === 403) {
            this.has_permission = false
          }
        })
      this.is_loading = false
    },
    getAllReports: function () {
      // this.is_loading = true
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      /* let params = { page: this.list.current_page }
            params = { ...this.filter, ...params } */
      const params = { ...this.filter, ...this.$route.query }
      return new Promise((resolve, reject) => {
        ReportHelper.getAllReports(params)
          .then(function (response) {
            // that.list = response.data
            // console.log(response)
            resolve(response)
          })
          .catch(function (error) {
            console.log('error:', error.response.status)
            /* if (error.response.status === 403) {
                          this.has_permission = false
                        } */
            reject(error)
          })
      })
    },
    reload: function () {
      this.getAllReports().then(response => {
        this.list = response.data
      }).catch(error => {
        this.empty = true
        if (error.response.status === 403) {
          this.has_permission = false
        }
      })
    },
    deleteReport: function (id, index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      that.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          confirmButtonText: 'Yes, delete it!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33'
        })
        .then((result) => {
          if (result.isConfirmed) {
            that.is_loading = true

            ReportHelper.removeReport(id)
              .then(function (response) {
                that.list.data.splice(index, 1)
                that.$toaster.success(response.data.message)
              })
              .catch(error => {
                console.log('error', error.response.data)
                that.$swal.fire('Failed!', 'Failed to delete.', 'error')
              })
              .finally(function () {
                that.is_loading = false
              })
          }
        })
    },
    async getFilterData () {
      /* this.is_loading = true
            await this.getAllReports().then(response => {
              this.list = response.data
            }).catch(error => {
              this.empty = true
              if (error.response.status === 403) {
                this.has_permission = false
              }
            })
            this.is_loading = false */
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ path: this.$route.fullPath, query: { page_title: this.filter.page_title, slug_url: this.filter.slug_url } }).catch(() => {})
    },
    updateFilter: function (value) {
      this.list.current_page = value.current_page
      this.filter = value.filter
      this.getAllReports()
    }
  }
}
</script>
