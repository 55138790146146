import { RouteConfig } from 'vue-router'
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue'

let routePath = '/ext-cbs-commission'
let viewPath = '/CBS/Commission'

const routes: Array<RouteConfig> = [
  {
    path: '/admin' + routePath + '/commission_plan',
    name: 'CommissionPlanList',
    component: () => import('../views' + viewPath + '/CommissionPlan/CommissionPlanList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_plan/add',
    name: 'CommissionPlanAdd',
    component: () => import('../views' + viewPath + '/CommissionPlan/CommissionPlanForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_plan/:id/edit',
    name: 'CommissionPlanEdit',
    component: () => import('../views' + viewPath + '/CommissionPlan/CommissionPlanForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_plan/:id/show',
    name: 'CommissionPlanShow',
    component: () => import('../views' + viewPath + '/CommissionPlan/CommissionPlanShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_condition_name',
    name: 'CommissionConditionNameList',
    component: () => import('../views' + viewPath + '/CommissionConditionName/CommissionConditionNameList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_condition_name/add',
    name: 'CommissionConditionNameAdd',
    component: () => import('../views' + viewPath + '/CommissionConditionName/CommissionConditionNameForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_condition_name/:id/edit',
    name: 'CommissionConditionNameEdit',
    component: () => import('../views' + viewPath + '/CommissionConditionName/CommissionConditionNameForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_condition_name/:id/show',
    name: 'CommissionConditionNameShow',
    component: () => import('../views' + viewPath + '/CommissionConditionName/CommissionConditionNameShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },

  {
    path: '/admin' + routePath + '/commission_config',
    name: 'CommissionConfigList',
    component: () => import('../views' + viewPath + '/CommissionConfig/CommissionConfigList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_config/add',
    name: 'CommissionConfigAdd',
    component: () => import('../views' + viewPath + '/CommissionConfig/CommissionConfigForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_config/:id/edit',
    name: 'CommissionConfigEdit',
    component: () => import('../views' + viewPath + '/CommissionConfig/CommissionConfigForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_config/:id/show',
    name: 'CommissionConfigShow',
    component: () => import('../views' + viewPath + '/CommissionConfig/CommissionConfigShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_deferred',
    name: 'CommissionDeferredList',
    component: () => import('../views' + viewPath + '/CommissionDeferred/CommissionDeferredList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_deferred/add',
    name: 'CommissionDeferredAdd',
    component: () => import('../views' + viewPath + '/CommissionDeferred/CommissionDeferredForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_deferred/:id/edit',
    name: 'CommissionDeferredEdit',
    component: () => import('../views' + viewPath + '/CommissionDeferred/CommissionDeferredForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_deferred/:id/show',
    name: 'CommissionDeferredShow',
    component: () => import('../views' + viewPath + '/CommissionDeferred/CommissionDeferredShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_log',
    name: 'CommissionLogList',
    component: () => import('../views' + viewPath + '/CommissionLog/CommissionLogList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/commission_log/:id/show',
    name: 'CommissionLogShow',
    component: () => import('../views' + viewPath + '/CommissionLog/CommissionLogShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/sales_log',
    name: 'SalesLogList',
    component: () => import('../views' + viewPath + '/SalesLog/SalesLogList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/sales_log/:id/show',
    name: 'SalesLogShow',
    component: () => import('../views' + viewPath + '/SalesLog/SalesLogShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  }
]

export default routes
