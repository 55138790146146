<template>
  <div>
    <div v-for="(table, index) in all_tables" :key="index">
      <div class="form-row">
        <div class="form-group col-md-2" v-if="index > 0">
          <label for="table" class="control-label">Query Type</label>
          <select
            style="width: 100%"
            class="form-control"
            v-model="table.object_type"
          >
            <option value="table">Table</option>
            <option value="query">Query</option>
          </select>
        </div>
        <div class="form-group col-md-10" v-show="table.object_type == 'table'">
          <label for="table" class="control-label">Table @{{ index }}</label>
          <select
            class="form-control table m-0"
            @change="onTableValueChange($event, index)"
            v-model="table.name"
            style="width: 100%"
            required
          >
            <option value=""></option>
            <option
              v-for="(tbl_option, indexO) in table_options"
              :key="indexO"
              v-bind:value="tbl_option"
            >
              {{ tbl_option }}
            </option>
          </select>
        </div>
        <div class="form-group col-sm-6" v-show="table.object_type == 'query'">
          <label for="table" class="control-label">Query @{{ index }}</label>
          <select
            class="form-control table m-0"
            @change="onQueryValueChange($event, index)"
            v-model="table.selected_query"
            style="width: 100%"
            required
          >
            <option value=""></option>
            <option
              v-for="(qry_option, indexQ) in query_options"
              :key="indexQ"
              v-bind:value="qry_option"
            >
              {{ qry_option.report_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row" v-if="index > 0">
        <div class="form-group col-md-2">
          <select class="form-control" v-model="table.table_join.type">
            <option value="">Choose Join Type</option>
            <option value="join">Join/Inner Join</option>
            <option value="leftJoin">Left Join</option>
            <option value="rightJoin">Right Join</option>
            <option value="crossJoin">Cross Join</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <select
            class="form-control filter-column-join-primary"
            v-model="table.table_join.column1"
          >
            <option value="">Choose Column</option>
            <option v-for="(option, indexS) in select_options" :key="indexS">
              {{ option }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <select class="form-control" v-model="table.table_join.condition">
            <option value="">Choose Condition</option>
            <option value="=">Equal</option>
            <option value="!=">Not Equal</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <select
            class="form-control filter-column-join-secondary"
            name="table_join[1]"
            v-model="table.table_join.column2"
          >
            <option value="">Choose Column</option>
            <option
              v-for="(option, indexS) in select_options"
              :key="indexS"
              v-bind:value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <button
            type="button"
            v-if="index > 0"
            class="btn btn-danger btn-sm"
            @click="deleteTable(index)"
          >
            <i class="fa fa-times"></i> Delete
          </button>
        </div>
      </div>
    </div>
    <div class="row" v-if="query_type == 'query_builder'">
      <div class="col-md-2">
        <div class="btn btn-success btn-sm" @click="addTable">
          <i class="fa fa-plus"></i> New
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ReportHelper } from "@/services/api";

export default {
  props: [
    "tables",
    "table_options",
    "select_options",
    "query_options",
    "query_type",
    "db_connection_name",
  ],
  data: () => ({
    all_tables: [
      {
        name: "",
        options: [],
        table_join: {
          type: "join",
          condition: "=",
          column1: "",
          column2: "",
        },
        object_type: "table",
        selected_query: {},
        query_alias: "",
      },
    ],
    columns: [],
  }),
  methods: {
    onQueryValueChange: function (event, index) {
      let that = this;
      if (this.tables[index].object_type == "table") return;
      ReportHelper.getColumnsByQuery(
        $.param({
          report: this.tables[index].selected_query.id,
          connection: this.db_connection_name,
        })
      )
        .then(function (response) {
          console.log(response.data);
          that.all_tables[index].name =
            that.tables[index].selected_query.report_name;
          that.all_tables[index].options = response.data;
          that.columns = [];
          that.all_tables[index].table_join = {
            type: "",
            condition: "=",
            column1: "",
            column2: "",
          };
          console.log(that.all_tables[index]);
          that.all_tables.forEach(function (currentValue, index, arr) {
            console.log(currentValue);
            let table_name = currentValue["name"];
            currentValue["options"].forEach(function (value) {
              that.columns.push(table_name + "." + value);
            });
          });
        })
        .catch(function (e) {
          //this.errors.push(e)
        });
    },
    onTableValueChange: function (event, index) {
      let that = this;

      ReportHelper.getColumnsByTable(
        $.param({
          table: event.target.value,
          connection: this.db_connection_name,
        })
      )
        .then(function (response) {
          that.all_tables[index].options = response.data;
          that.columns = [];
          that.all_tables[index].table_join = {
            type: "",
            condition: "=",
            column1: "",
            column2: "",
          };
          that.all_tables.forEach(function (currentValue, index, arr) {
            let table_name = currentValue["name"];
            currentValue["options"].forEach(function (value) {
              that.columns.push(table_name + "." + value);
            });
          });
        })
        .catch(function (e) {
          console.log(e);
          //that.errors.push(e);
        });
    },
    addTable: function () {
      this.all_tables.push({
        name: "",
        table_join: {
          type: "",
          condition: "=",
          column1: "",
          column2: "",
        },
        object_type: "table",
        selected_query: {},
        query_alias: "",
      });
    },
    deleteTable: function (index) {
      let table_name = this.all_tables[index].name;

      this.all_tables.splice(index, 1);
      this.columns = this.columns.filter(function (item) {
        return item.indexOf(table_name) !== 0;
      });
    },
  },
  watch: {
    columns: function (val, oldVal) {
      this.$emit("updateTable", {
        tables: this.all_tables,
        select_options: val,
      });
    },
    tables: function (val, oldVal) {
      this.all_tables = val;
    },
    select_options: function (val, oldVal) {
      this.columns = val;
    },
  },
};
</script>
