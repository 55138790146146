<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div class="support-box" v-else>
      <form action="#">
        <div class="form-group">
          <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.receiver_no_text : '' }}</label>
          <input type="number" name="phone_number" id="phone_number" :value="msisdn" class="form-control"
                 readonly="">
        </div>
        <div class="form-group">
          <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.problem_text : '' }}</label>
          <div class="prblem-wrapper">
            <select class="form-control"  id="problem">
                <option   v-for="option in dataDetails.services" :value="option.value" @click="setServiceName(option.value)">{{ !_.isEmpty(dataDetails) ? option.name : '' }}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.feedback_text : '' }}</label>
          <textarea class="form-control" cols="30" rows="4" name="message" v-model="message" id="message"></textarea>
        </div>
        <button type="button" @click="submitTicket" class="btn" id="ticket_create" data-apnar_problem_text="আপনার প্রবলেম"
                data-apnar_message_likhun_text="আপনার মেসেজ লিখুন">{{ !_.isEmpty(dataDetails) ? dataDetails.btn_text : '' }}
        </button>
        <button type="button" @click="goToSupportHistoryPage" class="btn" id="view" data-apnar_problem_text="আপনার প্রবলেম"
                data-apnar_message_likhun_text="আপনার মেসেজ লিখুন">{{ !_.isEmpty(dataDetails) ? dataDetails.history_btn_text : '' }}
        </button>
      </form>
    </div>
    <div class="ticket-modal" id="supportTicket" style="display: block;" v-if="ticketWarning">
      <div class="ticket-modal-inner">
        <div class="ticket-modal-close ticket-close" @click="toggleTicketWarning">×</div>
        <div class="ticket-modal-content">
          <div class="open-info-icon">
            <img :src="pictureBaseUrl+'/digi/images/icon-open-info.svg'" alt="">
          </div>
          <h3 id="ticket_message">{{ !_.isEmpty(waitingMessage) ? waitingMessage : '' }}</h3>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from '@/services/api/saveUserJourney'

export default {
  name: 'supportHome',
  data () {
    return {
      apiUrl: '/content/service-ticket-page',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      service: {},
      selectedService: null,
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language'),
      ticketCreateUrl: 'create_ticket_service',
      message: '',
      ticketResponseData: '',
      waitingMessage: '',
      ticketWarning: false
    }
  },
  mounted () {
    if (!this.msisdn)
    {
      this.$router.push({ name: 'input-msisdn-page', params: { service: 'support' } })
    }
    this.init()
  },
  methods: {
    async init () {
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, 'support-page', this.msisdn, this.languageId, this.sessionId)
    },
    async submitTicket () {
      await this.submit().then(response => {
        this.ticketResponseData = response.data
        console.log(this.ticketResponseData.message)
        if (this.ticketResponseData.code === 202) {
          this.waitingMessage = this.ticketResponseData.message
          this.ticketWarning = true
        }
        if (this.ticketResponseData.code === 200) {
          sessionStorage.setItem('ticket_serial', this.ticketResponseData.data)
          this.$router.push({ name: 'ticket-success' })
        }
      })
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, { msisdn: this.msisdn, languageId: this.languageId, operatorId: this.operatorId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    toggleTicketWarning () {
      this.ticketWarning = !this.ticketWarning
    },
    setServiceName (value) {
      this.selectedService = value
    },
    submit () {
      if (!this.selectedService) {
        this.selectedService = this.dataDetails.services[0].value
      }
      return new Promise((resolve, reject) => {
        axios
          .post(this.ticketCreateUrl, { msisdn: this.msisdn, languageId: this.languageId, operatorId: this.operatorId, service: this.selectedService, message: this.message })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {

          })
      })
    },
    goToSupportHistoryPage()
    {
      this.$router.push({ name: 'support-history' })
    }
  }
}
</script>

<style scoped>

</style>
