import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";
const routes: Array<RouteConfig> = [
  {
    path: '/admin/auth/permissions',
    name: 'Permissions',
    meta: {requireAuth: true,layout: MasterLayout},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "departments" */ '../views/Permission/PermissionList.vue')
  },
  {
    path: '/admin/auth/permissions/add',
    name: 'PermissionForm',
    component: () => import(/* webpackChunkName: "departments" */ '../views/Permission/PermissionForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/auth/permissions/edit/:id',
    name: 'PermissionEdit',
    component: () => import(/* webpackChunkName: "departments" */ '../views/Permission/PermissionForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/auth/permissions/view/:id',
    name: 'PermissionView',
    component: () => import(/* webpackChunkName: "departments" */ '../views/Permission/PermissionView.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
];
export default routes;
