import { RouteConfig } from 'vue-router'
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue'

const routePath = '/ext-cbs-invoice'
const viewPath = '/CBS/Invoice'

const routes: Array<RouteConfig> = [
  {
    path: '/admin' + routePath + '/invoice',
    name: 'InvoiceList',
    component: () => import('../views' + viewPath + '/InvoiceList.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/invoice/add',
    name: 'InvoiceAdd',
    component: () => import('../views' + viewPath + '/InvoiceForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/invoice/:id/edit',
    name: 'InvoiceEdit',
    component: () => import('../views' + viewPath + '/InvoiceForm.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  },
  {
    path: '/admin' + routePath + '/invoice/:id/show',
    name: 'InvoiceShow',
    component: () => import('../views' + viewPath + '/InvoiceShow.vue'),
    meta: { requireAuth: true, layout: MasterLayout },
  }
]

export default routes
