<template>
  <!-- Main Sidebar Container menu-box-view-->
  <aside class="main-sidebar sidebar-dark-primary">
    <!-- Brand Logo -->

    <router-link to="/" class="brand-link">
      <!-- mini logo for sidebar mini -->
      <span class="logo-mini"
        ><img :src="getImage('logo-mini.svg')" alt="Dotlines"
      /></span>
      <!-- logo for regular state and mobile devices -->
      <span class="logo-lg"
        ><img :src="getImage('logo-white.svg')" alt="Dotlines"
      /></span>
      <!-- <span class="brand-text font-weight-light">Dotlines</span> -->
      <i
        class="far fa-times-circle d-block d-sm-none push-menu-class"
        data-widget="pushmenu"
      ></i>
    </router-link>
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column nav-treeview"
          data-widget="treeview"
          role="menu"
          data-accordion="true"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item" v-for="(item, index) in menuItems" v-if="!ignore_route.includes(item.title)">
            <router-link
              v-if="!_.isEmpty(item.uri) && _.isEmpty(item.children)"
              :to="'/admin/' + (item.uri == '/' ? '' : item.uri)"
              class="nav-link"
            >
              <i
                v-if="!_.isEmpty(item.icon)"
                class="nav-icon fas"
                v-bind:class="item.icon"
              ></i>
              <i v-else class="far fa-circle nav-icon"></i>
              <p>
                {{ item.title }}
                <i
                  v-if="!_.isEmpty(item.children)"
                  class="right fas fa-angle-down"
                ></i>
              </p>
            </router-link>
            <a v-else href="javascript:void(0)" class="nav-link">
              <i
                v-if="!_.isEmpty(item.icon)"
                class="nav-icon fas"
                v-bind:class="item.icon"
              ></i>
              <i v-else class="nav-icon fas fa-circle"></i>
              <p>
                {{ item.title }}
                <i
                  v-if="!_.isEmpty(item.children)"
                  class="right fas fa-angle-down"
                ></i>
              </p>
            </a>
            <ul class="nav nav-treeview" v-if="!_.isEmpty(item.children)">
              <li
                class="nav-item"
                v-for="(itemChild, childIndex) in item.children" v-if="!ignore_route.includes(itemChild.title)"
              >
                <router-link
                  v-if="!_.isEmpty(itemChild.uri)"
                  :to="'/admin/' + itemChild.uri"
                  class="nav-link"
                  :class="
                    $route.path === '/admin/' + itemChild.uri ? 'active' : ''
                  "
                >
                  <i
                    v-if="!_.isEmpty(itemChild.icon)"
                    class="fas nav-icon"
                    v-bind:class="itemChild.icon"
                  ></i>
                  <i v-else class="far fa-circle nav-icon"></i>
                  <p>{{ itemChild.title }}</p>
                </router-link>
                <a v-else href="javascript:void(0)" class="nav-link">
                  <i
                    v-if="!_.isEmpty(itemChild.icon)"
                    class="nav-icon fas"
                    v-bind:class="itemChild.icon"
                  ></i>
                  <i v-else class="nav-icon fas fa-circle"></i>
                  <p>
                    {{ itemChild.title }}
                    <i
                      v-if="!_.isEmpty(itemChild.children)"
                      class="right fas fa-angle-down"
                    ></i>
                  </p>
                </a>
                <ul
                  class="nav nav-treeview"
                  v-if="!_.isEmpty(itemChild.children)"
                >
                  <li
                    class="nav-item"
                    v-for="(
                      itemGrandChild, grandChildIndex
                    ) in itemChild.children"
                  >
                    <router-link
                      v-if="!_.isEmpty(itemGrandChild.uri)"
                      :to="'/admin/' + itemGrandChild.uri"
                      class="nav-link"
                    >
                      <i
                        v-if="!_.isEmpty(itemGrandChild.icon)"
                        class="fas nav-icon"
                        v-bind:class="itemGrandChild.icon"
                      ></i>
                      <i v-else class="far fa-dot-circle nav-icon"></i>
                      <p>{{ itemGrandChild.title }}</p>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
<!--          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Admin
                <i class="right fas fa-angle-down"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  :to="{ name: 'Users' }"
                  class="nav-link"
                  :class="$route.path === '/admin/users' ? 'active' : ''"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>Users</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Organizations' }" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Organizations</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'Departments' }" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Departments</p>
                </router-link>
              </li>
            </ul>
          </li>-->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import axios from "axios";
import { Menu } from "@/services/api";

export default {
  props: {
    //
  },
  data: () => ({
    user: {},
    menuItems: [],
    ignore_route: ['Helpers', 'Product Management', 'Templates Settings', 'Database Connections', 'Manage Services',
      'Manage CSS Class', 'Transaction Logs', 'Redis Manager', 'Manage Box Style', 'Table Options', 'Manage Form Setting', 'App Builder', 'App Build']
  }),
  async mounted() {
    $(window).trigger("resize");
    // await this.getAuthDetail();
    await this.getMenuItems();
    /*let SELECTOR_DATA_WIDGET = '[data-widget="treeview"]';
    let SELECTOR_LINK = '.nav-link';
    let Default = {
      trigger: SELECTOR_DATA_WIDGET + " " + SELECTOR_LINK,
      animationSpeed: 300,
      accordion: true,
      expandSidebar: false,
      sidebarButtonSelector: '[data-widget="pushmenu"]'
    }*/
    /*$('[data-widget="treeview"]').each(function() {
      AdminLte.Treeview._jQueryInterface.call($(this), 'init');
    });*/
    window.$('ul[data-widget="treeview"]').Treeview("init");

    /*const $ = window.$;
    $('[data-widget="treeview"]').each(function() {
      AdminLte.Treeview('init');
    });*/
    /*const $ = window.$;
    const trees = $('[data-widget="treeview"]');
    trees.Treeview('init');*/

    //jQuery('[data-widget="treeview"]').Treeview('init');
    //console.log(jQuery);
    //jQuery('.sidebar-toggle-btn').pushMenu(options)
  },
  methods: {
    /*getAuthDetail: function () {
      let _this = this;
      return new Promise((resolve, reject) => {
        axios
          .get("/auth/get-user")
          .then((response) => {
            _this.user = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.log("error " + error);
            reject(error);
          });
      });
    },*/
    getMenuItems: function () {
      Menu.getAllMenus().then((menus) => {
        this.menuItems = menus;
      });
    },
    logout: function () {
      localStorage.setItem("authenticate", false);
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
