<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="otp-wrapper">
        <!--<form action="success.html">-->
        <!--<form >-->
        <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.title : '' }}</label>
        <div class="form-group otp-input">
          <div class="otp-box" :class="otp[0]?'not-empty':''">
            <input type="number" v-model="otp[0]" ref="otpInput0" @input="handleInput(0)" id="otp1"
                   class="form-control inputBox"
                   minlength="1" maxlength="1" autocomplete="off" autofocus="" data-next="2">
          </div>
          <div class="otp-box" :class="otp[1]?'not-empty':''">
            <input type="number" v-model="otp[1]" ref="otpInput1" @input="handleInput(1)" id="otp2"
                   class="form-control inputBox"
                   minlength="1" maxlength="1" autocomplete="off" data-next="3">
          </div>
          <div class="otp-box" :class="otp[2]?'not-empty':''">
            <input type="number" v-model="otp[2]" ref="otpInput2" @input="handleInput(2)" id="otp3"
                   class="form-control inputBox"
                   minlength="1" maxlength="1" autocomplete="off" data-next="4">
          </div>
          <div class="otp-box" :class="otp[3]?'not-empty':''">
            <input type="number" v-model="otp[3]" ref="otpInput3" @input="handleInput(3)" id="otp4"
                   class="form-control inputBox"
                   minlength="1" maxlength="1" autocomplete="off" data-next="">
          </div>
        </div>
        <button id="otp_verify_btn" @click="submitOtp" class="btn justify-center" data-wrong_otp_text="OTP টি সঠিক নয়"
                data-next_text="পরবর্তী"
                data-processing_text="প্রসেসিং  <i class='fa fa-spinner fa-pulse' aria-hidden='true'></i>"
                data-otp_length_validation_txt="দয়া করে 4 ডিজিটের ওটিপি কোড দিন" data-otp_for="undefined"
                data-topup_otp="undefined" data-temp_ano="undefined">
          {{ !_.isEmpty(dataDetails) ? dataDetails.next_btn_text : '' }} <span class="arrow-icon"></span></button>
        <div class="otp-timer"><span id="timer">{{ formattedTimerCount }}</span></div>
        <div class="resend-box">
          <a href="javascript:void(0);" @click="numberChange" class="change">{{
              !_.isEmpty(dataDetails) ? dataDetails.mobile_no_change_text : ''
            }}</a> |
          <button class="resend" :disabled="isTimerRunning" @click="resendOtp" data-otp_for="undefined"
                  data-topup_otp="undefined" data-temp_ano="undefined">
            {{ !_.isEmpty(dataDetails) ? dataDetails.resend_otp_text : '' }}
          </button>
        </div>
        <div id="wrong_message" v-if="wrongOtp">
          <div style="text-align:center; color:red;">{{
              !_.isEmpty(dataDetails) ? dataDetails.wrong_otp_text : ''
            }}
          </div>
        </div>
        <!--</form>-->
      </div>
      <div id="wrong_message"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { updateEvent } from '@/services/api/vivr-engine-helper'
export default {
  name: 'otpPage',
  data () {
    return {
      apiUrl: 'content/otp-input-page',
      otpVerifyUrl: 'verify-otp',
      sendOtpUrl: 'send_otp',
      dataDetails: {},
      languageId: sessionStorage.getItem('language'),
      msisdn: this.$route.params.msisdn ?? '',
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      is_loading: true,
      operatorId: localStorage.getItem('operatorId') ?? '1',
      otp: ['', '', '', ''],
      data: '',
      otpSendData: {},
      otpResponseCode: {},
      wrongOtp: false,
      service: sessionStorage.getItem('service') ?? '',
      timerCount: 59
    }
  },
  computed: {
    isTimerRunning () {
      return this.timerCount > 0
    },
    formattedTimerCount () {
      return this.timerCount.toString().padStart(5, '00:0')
    }
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  mounted () {
    this.init()
  },

  methods: {
    async init () {
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      await this.sendOtp().then(response => {
        this.otpSendData = response.data
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, '', {
            params: {
              languageId: this.languageId,
              msisdn: this.msisdn
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    sendOtp () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.sendOtpUrl, '', {
            params: {
              languageId: this.languageId,
              msisdn: this.msisdn,
              operatorId: this.operatorId
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    resendOtp () {
      this.timerCount = 59
      this.sendOtp()
    },
    handleInput (index) {
      if (index < this.otp.length - 1 && this.otp[index].length === 1) {
        const nextIndex = index + 1
        this.$nextTick(() => {
          const nextInput = this.$refs[`otpInput${nextIndex}`]
          nextInput.focus()
        })
      }
    },
    submitOtp () {
      this.data = this.otp.join('')
      axios
        .post(this.otpVerifyUrl, '', {
          params: {
            otp: this.data,
            msisdn: this.msisdn
          }
        })
        // eslint-disable-next-line no-return-assign
        .then(response => {
          this.submit(response.data.code)
        })
    },
    submit (code) {
      console.log(this.service)
      if (code === 400) {
        this.wrongOtp = true
      } else if (code === 200) {
        localStorage.setItem('msisdn', this.msisdn)
        if (this.service === 'input') {
          this.$router.push({ name: 'FrontHome' })
          location.reload()
        } else if (this.service === 'transaction' || this.service === 'topup-history') {
          this.$router.push({ name: 'userHistory', params: { service: this.service } })
        } else if (this.service === 'subscription') {
          this.$router.push({ name: 'mySubscription', params: { service: this.service } })
        } else if (this.service === 'favourite') {
          this.$router.push({ name: 'favourite-number-view', params: { service: this.service } })
        } else if (this.service === 'support') {
          this.$router.push({ name: 'support-history'})
        }
      }
    },
    numberChange () {
      this.$router.push({ name: 'input-msisdn-page', params: { service: this.service } })
    }
  }
}
</script>

<style scoped>

</style>
