// Register
import {RouteConfig} from "vue-router";
import Error404 from "@/views/errors/404.vue";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

const defaultRoutes: Array<RouteConfig> = [
  {
    path: '/admin/blank',
    name: 'BlankPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blankPage" */ '../views/BlankPage.vue')
  },
  {
    path: '/admin/auth/menu',
    name: 'Menu',
    meta: {layout: MasterLayout,requireAuth: true, title: 'Menu - App'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "menu-customize" */ '../views/Menu/Menu.vue')
  },
  {
    path: '*', component: Error404,
    name: '404', meta: {layout: MasterLayout,requireAuth: true, title: 'Not Found - App'}
  }
]

export default defaultRoutes;
