
import {Header, Navbar, LeftSidebar, Footer} from '@/components/partials';

export default {
  components: { Header, Navbar, LeftSidebar, Footer },
  data: () => ({
    //
  }),
  methods: {
    //
  },
  mounted: function () {
    //console.log('Master layout component mounted.');
  }
}
