import { RouteConfig } from "vue-router";
import QueryForm from "../views/Report/QueryForm.vue";
import QueryList from "../views/Report/QueryList.vue";
import DesignerForm from "../views/Report/DesignerForm.vue";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

let routePath = '/ext-report-builder'

const routes: Array<RouteConfig> = [
  {
    path: '/admin' + routePath + '/query-builder/:id?',
    name: 'QueryForm',
    component: QueryForm,
    meta: { layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/query-reports',
    name: 'QueryList',
    component: QueryList,
    meta: { layout: MasterLayout }
  },
  {
    path: '/admin' + routePath + '/report-designer',
    name: 'DesignerForm',
    component: DesignerForm,
    meta: { layout: MasterLayout }
  }
]

export default routes;
