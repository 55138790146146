<template>
  <div id="page-container">
    <div class="container">
      <div id ='loader' v-if="is_loading"></div>
      <div class="confirm-wrap" v-else>
        <img :src="datalist.image_link" alt="">
        <p style="color: red;" v-html="datalist.confirmation_text"></p>
        <a class="btn" id="back_to_home" @click="goBack" data-service_id="" data-option_id=""
           data-language="1">{{ !_.isEmpty(datalist) ? datalist.home_btn_text : '' }}</a>
        <br>
        <button class="btn resend" :disabled="isTimerRunning" @click.prevent="retryAgain" data-service_page="quizgiri"
                data-service_id="" data-option_id="" data-language="1" data-service_name="quizgiri">
          {{ !_.isEmpty(datalist) ? datalist.resent_btn_text : '' }}
        </button>
        <div class="otp-timer"><span id="timer">{{ formattedTimerCount }}</span></div>
      </div>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'
import {updateEvent} from "@/services/api/vivr-engine-helper";

export default {
  name: 'serviceRegistration',
  data () {
    return {
      dataApiUrl: 'content/mo-success-page/',
      datalist: {},
      serviceRegistrationUrl: '/service-registration',
      serviceRegistrationStatus: '',
      subService: sessionStorage.getItem('sub_service'),
      languageId: sessionStorage.getItem('language') ?? '1',
      service: (sessionStorage.getItem('service') ?? ''),
      msisdn: (localStorage.getItem('msisdn') ?? ''),
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      serviceUrl: (sessionStorage.getItem('serviceUrl') ?? '') + '/',
      serviceType: (sessionStorage.getItem('serviceType') ?? ''),
      operatorId: localStorage.getItem('operatorId'),
      timerCount: 59,
      reToken: sessionStorage.getItem('re_token') ?? '',
      reProductId: sessionStorage.getItem('re_product_id') ?? '',
      storeReferalInfoApiUrl: 'store-referal-info'
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    isTimerRunning () {
      return this.timerCount > 0
    },
    formattedTimerCount () {
      return this.timerCount.toString().padStart(5, '00:0')
    }
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  methods: {
    async init () {
      if (this.serviceType === 'mutiple-service') {
        this.service = this.subService
      }
      await this.serviceRegistration().then(response => {
        this.serviceRegistrationStatus = response.data
        sessionStorage.setItem('packagePrice', response.data.price)
        if (this.reToken !== '' && this.reProductId !== '') {
          this.storeReferalInfo(response.data.tid, response.data.price)
        }
      })
      await this.getdata().then(response => {
        this.datalist = response.data
        this.is_loading = false
      })
      const pageID = this.datalist.prompt.pageID
      const eventID = this.datalist.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service + '_registration_page', this.msisdn, this.languageId, this.sessionId)
    },
    retryAgain () {
      this.timerCount = 59
      this.serviceRegistration().then(response => {
        this.serviceRegistrationStatus = response.data
      })
    },
    getdata () {
      this.dataApiUrl += this.service
      return new Promise((resolve, reject) => {
        axios
          .post(this.dataApiUrl, {
            msisdn: this.msisdn,
            service: this.service,
            languageId: this.languageId,
            operatorId: this.operatorId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    serviceRegistration () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.serviceRegistrationUrl, {
            msisdn: this.msisdn,
            service: this.service,
            operatorId: this.operatorId,
            languageId: this.languageId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    storeReferalInfo (smsId, price) {
      axios
        .post(this.storeReferalInfoApiUrl, '', {
          params: {
            msisdn: this.msisdn,
            reToken: this.reToken,
            reProductId: this.reProductId,
            tId: smsId,
            price: price
          }
        })
    },
    goBack () {
      console.log(this.serviceType)
      if (this.serviceType === 'list' || this.serviceType === 'mutiple-service') {
        console.log('list here')
        this.$router.push({ name: 'listServiceDetails', params: { service: this.service } })
      } else if (this.serviceType === 'package-service') {
        this.$router.push({ name: 'packageService', params: { service: this.service } })
      } else {
        this.$router.push({ name: 'servicePage', params: { service: this.service } })
        console.log('not list here')
      }
    }
  }
}
</script>

<style scoped>

</style>
