import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

const routes: Array<RouteConfig> = [
  {
    path: '/admin/ext-dotlines-admin/departments',
    name: 'Departments',
    meta: {requireAuth: true,layout: MasterLayout},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "departments" */ '../views/Department/Departments.vue')
  },
  {
    path: '/admin/departments/create',
    name: 'DepartmentForm',
    component: () => import(/* webpackChunkName: "department-create" */ '../views/Department/DepartmentForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/departments/:id/show',
    name: 'DepartmentShow',
    component: () => import(/* webpackChunkName: "department-show" */ '../views/Department/DepartmentShow.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/departments/:id/edit',
    name: 'DepartmentEdit',
    component: () => import(/* webpackChunkName: "department-edit" */ '../views/Department/DepartmentForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
];

export default routes;
