import axios from 'axios';

export default {
  index(query: any = null, is_paginate = true) {
    return new Promise((resolve, reject) => {
      axios.get('database-connections', {
        params: query
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      }).finally(() => {
        //
      });
    })
  },
  store(payload: any) {
    return axios.post('database-connections', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  show(id: any) {
    return axios.get('database-connections/' + id)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  update(id:any, payload: any) {
    return axios.put('database-connections/' + id, payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  destroy(id: any) {
    return axios.delete('database-connections/' + id)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  }
}
