import axios from 'axios'

export default {
  getCommissionPlan (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/commission/commission-plan', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeCommissionPlan (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/commission/commission-plan', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showCommissionPlan (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/commission/commission-plan/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateCommissionPlan (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/commission/commission-plan/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getCommissionConditionName (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/commission/commission-condition-name', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeCommissionConditionName (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/commission/commission-condition-name', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showCommissionConditionName (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/commission/commission-condition-name/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateCommissionConditionName (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/commission/commission-condition-name/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getCommissionConfig (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/commission/commission-config', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeCommissionConfig (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/commission/commission-config', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showCommissionConfig (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/commission/commission-config/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateCommissionConfig (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/commission/commission-config/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getDeferredCommission (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/commission/commission-deferred', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeDeferredCommission (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/commission/commission-deferred', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showDeferredCommission (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/commission/commission-deferred/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateDeferredCommission (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/commission/commission-deferred/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getCommissionLog (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/commission/commission-log', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  showCommissionLog (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/commission/commission-log/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getSalesLog (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/commission/sales-log', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  showSalesLog (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/commission/sales-log/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },

}
