<template>
    <div class="container">
      <div id ='loader' v-if="is_loading"></div>
      <div class="sohoj-category" v-else>
        <ul>
          <li data-aos="fade" data-aos-delay="400" class="aos-init aos-animate" v-for="data in dataDetails.cards">
            <a class="page-card" href="javascript:void(0);" data-action="" data-target_page="internet_topup"
               data-service_id="11" data-option_id="112" data-language="1" @click="submit(data.target_page_name)">
              <div class="category-box">
                <img :src="!_.isEmpty(dataDetails) ? data.icon_url : '' " alt="">
                <h3>{{ !_.isEmpty(dataDetails) ? data.title : '' }}</h3>
              </div>
            </a>
          </li>
        </ul>
        <br>
        <div style="text-align: center;">
<!--          <iframe width="560" height="315"
                  src="https://www.youtube-nocookie.com/embed/EXG9CcRd2D0?controls=0&amp;rel=0&amp;autoplay=1"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="" style="width:100%!important;height:auto;"></iframe>-->
        </div>
        <br>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'
import { gtmDataPush } from '../../../../public/front/js/gtmHepler'
import { uuid } from 'vue-uuid'

export default {
  name: 'top-up',
  data () {
    return {
      apiUrl: '/content/topup-page',
      dataDetails: {},
      is_loading: true,
      savePromotionDataUrl: '/bd-topup',
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language')
    }
  },
  mounted: function () {
    this.init()
    gtmDataPush('view_item')
  },
  methods: {
    async init () {
      if (this.$route.query.op && this.$route.query.lang) {
        localStorage.setItem('shortcut_modal', 'false')
        this.operatorId = this.$route.query.op
        this.languageId = this.$route.query.lang
        if (!this.sessionId) {
          this.sessionId = uuid.v4()
          sessionStorage.setItem('sessionId', this.sessionId)
          sessionStorage.setItem('promotion_user', 'yes')
        }
        await this.savePromotionData()
      }
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, 'top_up', this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, {}, { params: { languageId: this.languageId, operatorId: this.operatorId } })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    savePromotionData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.savePromotionDataUrl, { languageId: this.languageId, operatorId: this.operatorId, sessionId: this.sessionId, msisdn: this.msisdn ?? 'guest-user', source: window.location.href })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit (page) {
      console.log('page', page)
      if (page === 'history') {
        sessionStorage.setItem('service', 'topup-history')
        if (!this.msisdn) {
          this.$router.push({ name: 'input-msisdn-page', params: { service: page } })
        } else {
          this.$router.push({ name: 'userHistory', params: { type: page } })
        }
      } else {
        this.$router.push({ name: 'topUpList', params: { type: page } })
      }
    }
  }
}
</script>

<style scoped>

</style>
