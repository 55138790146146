import { RouteConfig } from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/admin/ext-custom-permissions/routes",
    name: "RouteList",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "RouteList" */ "@/views/RouteList/RouteList.vue"
        ),
  },
  {
    path: "/admin/ext-custom-permissions/routes/create",
    name: "RouteListCreate",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "RouteForm" */ "@/views/RouteList/RouteForm.vue"
        ),
  },
  {
    path: "/admin/ext-custom-permissions/routes/:id/edit",
    name: "RouteListEdit",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "RouteFormEdit" */ "@/views/RouteList/RouteForm.vue"
        ),
  },
  {
    path: "/admin/ext-custom-permissions/routes/:id/show",
    name: "RouteListShow",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "RouteView" */ "@/views/RouteList/RouteView.vue"
        ),
  },

  {
    path: "/admin/ext-custom-permissions/index",
    name: "CustomPermissions",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionList" */ "@/views/CustomPermission/CustomPermissionList.vue"
      ),
  },
  {
    path: "/admin/ext-custom-permissions/create",
    name: "CustomPermissionsCreate",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionForm" */ "@/views/CustomPermission/CustomPermissionForm.vue"
      ),
  },
  {
    path: "/admin/ext-custom-permissions/:id/edit",
    name: "CustomPermissionsEdit",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionFormEdit" */ "@/views/CustomPermission/CustomPermissionForm.vue"
      ),
  },
  {
    path: "/admin/ext-custom-permissions/:id/show",
    name: "CustomPermissionsShow",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionView" */ "@/views/CustomPermission/CustomPermissionView.vue"
      ),
  },

  {
    path: "/admin/ext-custom-permissions/details",
    name: "CustomPermissionDetailList",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionDetailList" */ "@/views/CustomPermissionDetail/CustomPermissionDetailList.vue"
      ),
  },
  {
    path: "/admin/ext-custom-permissions/details/create",
    name: "CustomPermissionDetailCreate",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionDetailForm" */ "@/views/CustomPermissionDetail/CustomPermissionDetailForm.vue"
      ),
  },
  {
    path: "/admin/ext-custom-permissions/details/:id/edit",
    name: "CustomPermissionDetailEdit",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionDetailFormEdit" */ "@/views/CustomPermissionDetail/CustomPermissionDetailForm.vue"
      ),
  },
  {
    path: "/admin/ext-custom-permissions/details/:id/show",
    name: "CustomPermissionDetailShow",
    meta: { requireAuth: true, layout: MasterLayout },
    component: () =>
      import(
        /* webpackChunkName: "CustomPermissionDetailView" */ "@/views/CustomPermissionDetail/CustomPermissionDetailShow.vue"
      ),
  },

];
export default routes;
