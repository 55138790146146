<template>
    <div class="container">
      <div id ='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="point-wrapper">
        <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.title : '' }}</h4>
        <h2><a href="">{{ !_.isEmpty(dataDetails) ? dataDetails.referral_point : '' }}</a></h2>
        <a id="redeem_point" class="btn" href="#" data-target_page="redeem_point_page">{{
            !_.isEmpty(dataDetails) ? dataDetails.redeem_point_button_text : ''
          }}</a>
      </div>
      <div class="refer-top-box">
        <p>{{ !_.isEmpty(dataDetails) ? dataDetails.refer_message : '' }}</p>
      </div>
      <div v-for="data in dataDetails.cards">
        <a href="" @click.prevent="shareUrl(data.share_url)"
           data-url="https://sandbox-referral.dotlines.com.sg/rel?reu=238&amp;repid=71&amp;lang=bn"
           data-message="সহজ টপ-আপ" data-product="bd_topup" data-option_id="238" data-service_id="15"
           class="topup-section aos-init aos-animate" data-aos="fade">
          <img :src="!_.isEmpty(dataDetails) ? data.image_url : ''" alt="TopUp">
        </a>
      </div>
    </div>
      <div class="custom_share" id="custom_share">
        <div class="share-dialog" :class="showModal?'is-open':''">
          <header><h3 class="dialog-title">Share this</h3>
            <button @click.prevent="toggleModal" id="share-close" class="close-button closeshareDialog"><i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </header>
          <div class="targets"><a class="button btn-messenger" data-share_via="messenger" target="_blank"
                                  href="fb-messenger://share?link="><i class="fa fa-comments"
                                                                       aria-hidden="true"></i><span>Messenger</span></a><a
            class="button btn-facebook" data-share_via="facebook" target="_blank"
            href="https://www.facebook.com/sharer.php?u="><i class="fa fa-facebook-official" aria-hidden="true"></i><span>Facebook</span></a><a
            class="button btn-whatsapp" data-share_via="whatsapp" target="_blank"
            href="whatsapp://send/?text=Refer and Win%20"><i class="fa fa-whatsapp"
                                                             aria-hidden="true"></i><span>WhatsApp</span></a><a
            class="button btn-viber" data-share_via="viber" target="_blank" href="viber://forward?text="><i
            class="fa fa-comment" aria-hidden="true"></i><span>Viber</span></a><a class="button btn-skype"
                                                                                  data-share_via="skype" target="_blank"
                                                                                  href="https://web.skype.com/share?url=&amp;text=Refer and Win"><i
            class="fa fa-skype" aria-hidden="true"></i><span>Skype</span></a><a class="button btn-sms" target="_blank"
                                                                                href="sms:?body="><i
            class="fa fa-envelope-o" data-share_via="sms" aria-hidden="true"></i><span>SMS</span></a></div>
          <div class="link">
            <div class="pen-url">{{referUrl}}</div>
            <button @click.prevent="copyText" class="copy-link" data-url="">{{linkText}}</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import Clipboard from 'clipboard'
import {updateEvent} from "@/services/api/vivr-engine-helper";

export default {
  name: 'refer-friend',
  components: {},
  data () {
    return {
      apiUrl: 'content/refer-friend-page',
      dataDetails: {},
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn') ?? '',
      referUrl: '',
      showModal: false,
      linkText: 'Copy Link',
      operatorId: localStorage.getItem('operatorId') ?? '1'
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      await this.getDatails().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
    },
    getDatails () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, '', { params: { languageId: this.languageId, msisdn: this.msisdn, operatorId: this.operatorId } }) // param language,msisdn
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    shareUrl (url) {
      this.referUrl = url
      this.toggleModal()
    },
    toggleModal () {
      this.showModal = !this.showModal
      this.linkText = 'Copy Link'
    },
    copyText () {
      const clipboard = new Clipboard('.btn', {
        text: () => this.referUrl
      })

      clipboard.on('success', () => {
        this.linkText = 'Copied'
        clipboard.destroy()
      })

      clipboard.on('error', () => {
        console.log('Text not copied!')
        clipboard.destroy()
      })

      clipboard.onClick(event)
    }

  }
}
</script>

<style scoped>

</style>
