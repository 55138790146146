import axios from 'axios'

export default {
  getUserPaymentMethod (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/user-management/user-payment', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  createUserPaymentMethod (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/user-management/user-payment', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showUserPaymentMethod (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/user-management/user-payment/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateUserPaymentMethod (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/user-management/user-payment/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  destroyUserPaymentMethod (id: any) {
    return new Promise((resolve, reject) => {
      axios.delete('/cbs/user-management/user-payment/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getAllApplications () {
    return new Promise((resolve, reject) => {
      axios.get('/ext-cbs-api-gateway/all-applications').then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
}
