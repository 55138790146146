import axios from 'axios'

export default {
  getRatingType (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/product-rating/rating-type', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeRatingType (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/product-rating/rating-type', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showRatingType (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/product-rating/rating-type/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateRatingType (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/product-rating/rating-type/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getRatingConditionName (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/product-rating/rating-condition-name', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeRatingConditionName (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/product-rating/rating-condition-name', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showRatingConditionName (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/product-rating/rating-condition-name/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateRatingConditionName (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/product-rating/rating-condition-name/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },

  getRatingPlan (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/product-rating/rating-plan', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeRatingPlan (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/product-rating/rating-plan', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showRatingPlan (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/product-rating/rating-plan/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateRatingPlan (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/product-rating/rating-plan/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getRatingConfig (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/product-rating/rating-config', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeRatingConfig (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/product-rating/rating-config', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showRatingConfig(id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/product-rating/rating-config/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateRatingConfig (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/product-rating/rating-config/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },

}
