<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div v-for="data in dataDetails.sections" v-else>
      <a href="" @click.prevent="submit(data.target_page)" data-target_id="undefined" data-target_page="dotgames"
         class="topup-section aos-init aos-animate games-click" data-aos="fade">
        <img :src="data.image_link" alt="undefined">
        <div class="category-overlay" :class="customCss?data.color_class:''">
          <h4 v-if="data.text">{{ data.text }}</h4>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'
import {updateEvent} from "@/services/api/vivr-engine-helper";

export default {
  name: 'list_service',
  data () {
    return {
      dataApiUrl: 'content/',
      dataDetails: {},
      is_loading: true,
      service: sessionStorage.getItem('service'),
      serviceGroupId: sessionStorage.getItem('serviceGroupId'),
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      operatorId: localStorage.getItem('operatorId'),
      msisdn: localStorage.getItem('msisdn'),
      customCss:'',
    }
  },
  methods: {
    async init () {
      if (this.service === 'caller-tunes') {
        this.dataApiUrl += 'caller-tune-page'
        this.customCss =true
      } else if (this.service === 'religion') {
        this.dataApiUrl += 'religion-page-content'
      } else {
        this.dataApiUrl += 'games-page'
      }
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.dataApiUrl, '', {
            params: {
              msisdn: this.msisdn,
              service: this.service,
              languageId: this.languageId,
              serviceGroupId: this.serviceGroupId
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit (page) {
      if (this.service === 'caller-tunes') {
        this.$router.push({ name: 'multiple-service-details', params: { service: page } })
      } else {
        this.$router.push({ name: 'listServiceDetails', params: { service: page } })
      }
    }
  },
  mounted () {
    this.init()
  }

}
</script>

<style scoped>

</style>
