import axios from 'axios'

export default {
  getProductCategory (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/product/product-category', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  createProductCategory (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/product/product-category', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showProductCategory (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/product/product-category/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateProductCategory (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/product/product-category/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getProduct (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/product/products', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  storeProduct (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/product/products', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showProduct (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/product/products/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateProduct (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/product/products/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  destroyProduct (id: any) {
    return new Promise((resolve, reject) => {
      axios.delete('/cbs/product/products/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
}
