import axios from 'axios'

export default {
  getGetway (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/payment/gateway', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  storeGetway (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/payment/gateway', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showGetway (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/payment/gateway/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateGetway (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/payment/gateway/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getMerchantGetway (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params }
      axios.get('/cbs/payment/merchant-gateway', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  storeMerchantGetway (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/payment/merchant-gateway', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showMerchantGetway (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/payment/merchant-gateway/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateMerchantGetway (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/payment/merchant-gateway/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getPaymentTransaction (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params }
      axios.get('/cbs/payment/payment-transaction', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  getPaymentTransactionEvent (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params }
      axios.get('/cbs/payment/payment-transaction-event', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  },
  storePaymentTransactionEvent (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/payment/payment-transaction-event', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showPaymentTransactionEvent (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/payment/payment-transaction-event/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updatePaymentTransactionEvent (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/payment/payment-transaction-event/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
}
