import { RouteConfig } from 'vue-router'
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue'

const routePath = '/ext-cbs-subscription'
const viewPath = '/CBS/Subscription'

const routes: Array<RouteConfig> = [
  {
    path: '/admin' + routePath + '/subscription_plan',
    name: 'SubscriptionListPlan',
    component: () => import('../views' + viewPath + '/Plan/PlanList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/subscription_plan/add',
    name: 'SubscriptionPlanAdd',
    component: () => import('../views' + viewPath + '/Plan/PlanForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/subscription_plan/:id/edit',
    name: 'SubscriptionPlanEdit',
    component: () => import('../views' + viewPath + '/Plan/PlanForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/subscription_plan/:id/show',
    name: 'SubscriptionPlanShow',
    component: () => import('../views' + viewPath + '/Plan/PlanShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/subscription',
    name: 'SubscriptionList',
    component: () => import('../views' + viewPath + '/Subscription/SubscriptionList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/subscription/add',
    name: 'SubscriptionAdd',
    component: () => import('../views' + viewPath + '/Subscription/SubscriptionForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/subscription/:id/edit',
    name: 'SubscriptionEdit',
    component: () => import('../views' + viewPath + '/Subscription/SubscriptionForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/subscription/:id/show',
    name: 'SubscriptionShow',
    component: () => import('../views' + viewPath + '/Subscription/SubscriptionShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/usage',
    name: 'UsageRecordList',
    component: () => import('../views' + viewPath + '/UsageRecord/UsageRecordList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/usage/add',
    name: 'UsageRecordAdd',
    component: () => import('../views' + viewPath + '/UsageRecord/UsageRecordForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/usage/:id/edit',
    name: 'UsageRecordEdit',
    component: () => import('../views' + viewPath + '/UsageRecord/UsageRecordForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/usage/:id/show',
    name: 'UsageRecordShow',
    component: () => import('../views' + viewPath + '/UsageRecord/UsageRecordShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  }
]

export default routes
