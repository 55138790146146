import axios from 'axios'

export default {
  getGeneralLedgerAccount (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params };
      axios.get('/cbs/accounting/general-ledger-account', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeGeneralLedgerAccount (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/accounting/general-ledger-account', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showGeneralLedgerAccount (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/accounting/general-ledger-account/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateGeneralLedgerAccount (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/accounting/general-ledger-account/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getAccount (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params }
      axios.get('/cbs/accounting/account', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  storeAccount (payload: any) {
    return new Promise((resolve, reject) => {
      axios.post('/cbs/accounting/account', payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  showAccount (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/accounting/account/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  updateAccount (id: any, payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('/cbs/accounting/account/' + id, payload)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  getAccountingTransactions (currentPage = null, query: any = null, per_page = 10) {
    return new Promise((resolve, reject) => {
      let params = { page: currentPage, per_page: per_page }
      params = { ...query, ...params }
      axios.get('/cbs/accounting/accounting-transaction', {
        params: params
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  showAccountingTransaction (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/cbs/accounting/accounting-transaction/' + id)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },

}
