<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div class="confirm-wrap" v-else>
      <img :src="!_.isEmpty(dataDetails) ? dataDetails.image_link : ''" alt="">
      <p>{{ !_.isEmpty(dataDetails) ? dataDetails.success_text : '' }}</p>
      <p>Ticket Number: <b>{{ !_.isEmpty(dataDetails) ? dataDetails.ticket_number : '' }}</b></p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from '@/services/api/saveUserJourney'

export default {
  name: 'ticketSuccessPage',
  data () {
    return {
      apiUrl: '/content/ticket-create-success',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      ticketSerialNumber: sessionStorage.getItem('ticket_serial') ?? '',
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language'),
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, 'support-page', this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, { ticketNumber: this.ticketSerialNumber, languageId: this.languageId, operatorId: this.operatorId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    }

  }
}
</script>

<style scoped>

</style>
