import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

const routes: Array<RouteConfig> = [
  {
    path: '/admin/ext-dotlines-admin/services',
    name: 'Services',
    meta: {title: 'Services - Dotlines', requireAuth: true, layout: MasterLayout},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "services" */ '../views/Service/ServiceList.vue')
  },
  {
    path: '/admin/ext-dotlines-admin/services/create',
    name: 'ServiceForm',
    component: () => import(/* webpackChunkName: "service-create" */ '../views/Service/ServiceForm.vue'),
    meta: {
      requireAuth: true, layout: MasterLayout,
      title: 'Service form - Dotlines'
    }
  },
  {
    path: '/admin/ext-dotlines-admin/services/:id/show',
    name: 'ServiceShow',
    component: () => import(/* webpackChunkName: "service-show" */ '../views/Service/ServiceShow.vue'),
    meta: {
      requireAuth: true, layout: MasterLayout,
      title: 'Service show - Dotlines'
    }
  },
  {
    path: '/admin/ext-dotlines-admin/services/:id/edit',
    name: 'ServiceEdit',
    component: () => import(/* webpackChunkName: "service-edit" */ '../views/Service/ServiceForm.vue'),
    meta: {
      requireAuth: true, layout: MasterLayout,
      title: 'Service edit - Dotlines'
    }
  },
];

export default routes;
