<template>
  <div v-if="routeName ==='FrontHome' || routeName === 'FrontHomeOp'">
  </div>
  <div v-else class="terms-support">
    <ul>
      <li>
        <a href="javascript:void(0)" @click="termsPage()" id="terms_of_use_inner_page">
          <img src="./../../../public/assets/digi/images/terms-gray-icon.svg" alt=""> {{ !_.isEmpty(data) ? data.terms_of_use_text : '' }}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="supportPage" id="support_inner_page">
          <img src="./../../../public/assets/digi/images/support-gray-icon.svg" alt=""> {{ !_.isEmpty(data) ? data.support_text : '' }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'clientFooter',
  data () {
    return {
      // nameRoute: this.$route.name,
      routeName: 'FrontHome',
      data:{},
      dataUrl: '/get-footer-section',
      languageId: sessionStorage.getItem('language') ?? '1',
      operatorId: localStorage.getItem('operatorId')
    }
  },
  watch: {
    '$route.name': function (newRouteName, oldRouteName) {
      this.routeName = newRouteName
    }
  },
  methods: {
    async init()
    {
      await this.getdata().then(response => {
        this.data = response.data
        this.is_loading = false
      })
    },
    termsPage () {
      this.$router.push({ name: 'termsAndCondition' })
    },
    supportPage()
    {
      this.$router.push({ name: 'support-page' })
    },
    getdata () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.dataUrl, {
            languageId: this.languageId,
            operatorId: this.operatorId,
          })
          .then((response) => {
            // console.log(response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  mounted () {
    this.routeName = this.$route.name
    this.init()
  }
}
</script>

<style scoped>

</style>
