
import { Header, Footer } from '@/components/partials'

export default {
  components: {
    Footer
  },
  data: () => ({
    //
  }),
  methods: {
    //
  },
  mounted: function () {
    //console.log('Guest layout component mounted.')
  }
}
