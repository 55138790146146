<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div>
      <div class="single-category-box cinema-box" v-for="images in dataDetails.images">
        <img :src="baseUrl+'/'+images.link" alt="">
      </div>
      <div class="warning-text">
        <p>{{dataDetails.desc}}</p>
        <a :href="'tel:'+dataDetails.tel" class="btn">{{ dataDetails.dial_text }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'

export default {
  name: 'cinemaServiceDetails',
  data () {
    return {
      apiUrl: '/content/',
      dataDetails: {},
      is_loading: true,
      service: sessionStorage.getItem('service'),
      baseUrl: process.env.VUE_APP_MAIN_URL,
      sessionId: sessionStorage.getItem('sessionId'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language'),
      msisdn: localStorage.getItem('msisdn')
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.apiUrl += this.service += '/step/1'
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, { pmsisdn: this.msisdn, languageId: this.languageId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit (dial) {
      console.log(dial)
    }
  }
}
</script>

<style scoped>

</style>
