export function gtmDataPush (event = null, apartyNumber = null, bpartyNumber = null, packageName = null, amount = null) {
  window.dataLayer.push(
    {
      event: event,
      A_Party_Number: apartyNumber,
      B_Party_Number: bpartyNumber,
      Package_Name: packageName,
      Deduction_Amount: amount
    })
}
