import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

import routes from './routes';

const router = new VueRouter({
  mode: 'history',//hash for mobile app
  routes // you may use only 'routes' short for routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (localStorage.getItem('token') == undefined || localStorage.getItem('token') == null) {
      next({
        path: '/login',
        params: {nextUrl: to.fullPath}
      });
    } else {
      next();
    }
  } else {
    next();
  } /*else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next()
    } else {
      next({ name: 'userboard' })
    }
  } else {
    next()
  }*/
})


export default router
