<template>
  <div>
    <div class="col-md-12">
      <h4 class="site-heading my-3"><span>Select Columns</span></h4>
    </div>
    <div
      class="form-row"
      v-for="(selection, index) in column_selections"
      :key="index"
    >
      <div class="form-group col-md-2">
        <multiselect
          :options="select_options"
          v-model="selection.column"
          placeholder="Pick a value"
          v-if="selection.is_raw == 'no'"
          :searchable="true"
          :internal-search="false"
          :clear-on-select="true"
          :close-on-select="true"
          :options-limit="300"
          :limit="3"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="true"
        ></multiselect>
        <input
          v-if="selection.is_raw == 'yes'"
          class="form-control"
          v-model="selection.column"
        />
      </div>
      <div class="form-group col-md-1 text-center">AS</div>
      <div class="form-group col-md-2">
        <input
          class="form-control"
          v-model="selection.alias"
          placeholder="Column alias"
        />
      </div>
      <div class="form-group col-md-2">
        <label class="checkbox"
          ><input
            type="checkbox"
            v-model="selection.is_raw"
            true-value="yes"
            false-value="no"
          />
          <span class="checkmark"></span> is raw?
        </label>
      </div>
      <div class="form-group col-md-2">
        <label class="checkbox"
          ><input
            type="checkbox"
            v-model="selection.is_sortable"
            true-value="yes"
            false-value="no"
          />
          <span class="checkmark"></span> is sortable?
        </label>
      </div>
      <div class="form-group col-md-2">
        <label class="checkbox"
          ><input
            type="checkbox"
            v-model="selection.has_label"
            true-value="yes"
            false-value="no"
          />
          <span class="checkmark"></span> has label?
        </label>
        <div v-if="selection.has_label == 'yes'">
          <button type="button" @click="showLabelModal(index)">
            <i class="fa fa-cog"></i>
          </button>
        </div>
      </div>
      <div class="form-group col-md-1">
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="deleteSelect(index)"
        >
          <i class="fa fa-times"></i> &nbsp;Delete
        </button>
      </div>
    </div>
    <div class="col-sm-8">
      <button type="button" @click="addSelect" class="btn btn-success btn-sm">
        <i class="fa fa-plus"></i> New
      </button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import LabelModal from "./partials/LabelModal.vue";

export default {
  props: ["selections", "select_options"],
  components: {
    LabelModal,
  },
  data: () => ({
    index: 0,
    column_selections: [
      {
        column: "",
        alias: "",
        is_raw: "no",
        is_sortable: "no",
        has_label: "no",
        label_config: [
          {
            value: "",
            class: "",
          },
        ],
      },
    ],
  }),
  components: {
    Multiselect,
    LabelModal,
  },
  methods: {
    addSelect: function () {
      this.column_selections.push({
        column: "",
        alias: "",
        is_raw: "no",
        is_sortable: "no",
        has_label: "no",
        label_config: [
          {
            value: "",
            class: "",
          },
        ],
      });
    },
    deleteSelect: function (index) {
      this.index = index;
      this.column_selections.splice(index, 1);
    },
    showLabelModal: function (index) {
      this.$modal.show(
        LabelModal,
        { column_selection: this.column_selections[index] },
        { height: "auto", scrollable: true, draggable: true }
      );
    },
    closeModal: function () {},
    updateSelection: function (value) {
      this.column_selections[this.index] = value;
    },
  },
  watch: {
    selections: function (val, oldVal) {
      this.column_selections = val;
    },
  },
  updated() {
    this.$emit("updateColumnSelection", this.column_selections);
  },
};
</script>
<style></style>
