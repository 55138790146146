<template>
  <div class="choose-language">

    <div class="selected-language" @click="toggoleDropdown">
      <img v-if="languageIcon" :src="baseUrl+languageIcon" alt="বাংলা">
      <img v-else :src=" !_.isEmpty( lang) ?baseUrl+lang[0].icon:''" alt="বাংলা">
      <span class="dropdown-arrow" :class="{active : dropdownLanguagelist}"></span>
    </div>
    <div class="language-list " v-bind:style="dropdownLanguagelist ?'display:block;':'display:none;'">
      <ul>
        <li style="font-size: 12px" class="select_language" data-locale="locale/id" v-for="lan in lang">
          <a href="" @click="setLanguage(lan.id , lan.icon ,lan.code)"><img
            :src="baseUrl+lan.icon"
            alt=""> {{ lan.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'select-language',
  // props:['toggoleDropdown','dropdownLanguagelist','language'],
  // props:['operatorId'],
  data () {
    return {
      dropdownLanguagelist: false,
      baseUrl: process.env.VUE_APP_MAIN_URL + '/uploads/',
      languageIcon: sessionStorage.getItem('icon') ?? ''
      // language: {},
      // LanguageApiUrl: '/get-language',
      // operatorId: sessionStorage.getItem('operatorId')?? '1'
    }
  },
  mounted () {
    // this.getLanguage(this.operatorId)
    // localStorage.getItem('operatorId')?this.GET_LANGUAGE :
    if (this.$route.query.op && this.$route.query.lang) {
      const urlLang = this.$route.query.lang
      let tempIcon = {}
      let tempCode = {}
     this.GET_LANGUAGE(this.$route.query.op)
      // eslint-disable-next-line no-unused-expressions
      setTimeout(() => {
        for (let j = 0; j < Object.keys(this.lang).length; j++) {
          if (this.lang[j].id == urlLang) {
            tempCode = this.lang[j].code
            tempIcon = this.lang[j].icon
            this.languageIcon = tempIcon
            sessionStorage.setItem('language', urlLang)
            sessionStorage.setItem('icon', tempIcon)
            sessionStorage.setItem('languageName', tempCode)
          }
        }

      }, 1000)
    }
    if (Object.keys(this.lang).length <= 0) {
      this.GET_LANGUAGE(parseInt(localStorage.getItem('operatorId')))
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    ...mapActions(['GET_LANGUAGE']),

    toggoleDropdown () {
      this.dropdownLanguagelist = !this.dropdownLanguagelist
    },
    setLanguage (id, icon , code) {
      sessionStorage.setItem('language', id)
      sessionStorage.setItem('icon', icon)
      sessionStorage.setItem('languageName', code)
      if (sessionStorage.getItem('receiverMsisdn'))
      {
        sessionStorage.removeItem('receiverMsisdn')
      }
      if (sessionStorage.getItem('receiverOperatorId'))
      {
        sessionStorage.removeItem('receiverOperatorId')
      }
      if (sessionStorage.getItem('receiverOperatorImageUrl'))
      {
        sessionStorage.removeItem('receiverOperatorImageUrl')
      }
      if (sessionStorage.getItem('receiverCountry'))
      {
        sessionStorage.removeItem('receiverCountry')
      }
      if (this.$route.name === 'FrontHome') {
        location.reload()
      } else {
        this.$router.push({ name: 'FrontHome' })
      }
    }
  }
}
</script>

<style scoped>

</style>
