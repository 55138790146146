import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";
const routes: Array<RouteConfig> = [
  {
    path: '/admin/auth/roles',
    name: 'Role',
    meta: {requireAuth: true,layout: MasterLayout},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "departments" */ '../views/Roles/RoleList.vue')
  },
  // {
  //   path: '/admin/auth/roles',
  //   name: 'Roles',
  //   meta: {title: 'roles - App', requireAuth: true},
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "departments" */ '../views/roles/RoleForm.vue')
  // },
  {
    path: '/admin/auth/roles/add',
    name: 'RoleAdd',
    component: () => import(/* webpackChunkName: "departments" */ '../views/Roles/RoleForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/auth/roles/edit/:id',
    name: 'RoleEdit',
    component: () => import(/* webpackChunkName: "departments" */ '../views/Roles/RoleForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/auth/roles/view/:id',
    name: 'RoleView',
    component: () => import(/* webpackChunkName: "departments" */ '../views/Roles/RoleView.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
];
export default routes;
