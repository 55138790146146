import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";

const routes: Array<RouteConfig> = [
  {
    path: '/admin/ext-dotlines-admin/database-connections',
    name: 'DatabaseConnections',
    meta: {requireAuth: true,layout: MasterLayout},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "DatabaseConnections" */ '../views/DatabaseConnection/DatabaseConnections.vue')
  },
  {
    path: '/admin/ext-dotlines-admin/database-connections/create',
    name: 'DatabaseConnectionCreate',
    component: () => import(/* webpackChunkName: "DatabaseConnectionShowCreate" */ '../views/DatabaseConnection/DatabaseConnectionForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/ext-dotlines-admin/database-connections/:id/show',
    name: 'DatabaseConnectionShow',
    component: () => import(/* webpackChunkName: "DatabaseConnectionShow" */ '../views/DatabaseConnection/DatabaseConnectionShow.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/ext-dotlines-admin/database-connections/:id/edit',
    name: 'DatabaseConnectionEdit',
    component: () => import(/* webpackChunkName: "DatabaseConnectionEdit" */ '../views/DatabaseConnection/DatabaseConnectionForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
];

export default routes;
