import axios from 'axios'

export default {
  getSubscription(offset = 0, query: any = null, limit = 10) {
    return new Promise((resolve, reject) => {
      let params = { offset: offset, limit: limit }
      params = { ...query, ...params }
      axios.get(process.env.VUE_APP_CBS_COMMISSION_API_BASE_URL + '/commission-plan').then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        //
      })
    })
  }
}
