<template>
  <div>
    <draggable
      element="div"
      v-model="rowList"
      handle=".handle-movement"
      v-bind="dragOptions"
      :move="onMove"
      :disabled="!enabled"
      ghost-class="ghost"
      @start="dragging = true"
      @end="dragging = false"
    >
      <transition-group name="no" class="list-group" tag="div">
        <div
          class="list-group-item"
          v-for="(element, index) in rowList"
          :key="element.order"
        >
          <span
            class="handle-movement"
            style="cursor: grabbing; float: left; padding-right: 8px"
            ><i class="fa fa-align-justify"></i> Drag Here (@{{
              element.name
            }})</span
          >
          <i class="fa fa-times fa-2x close" @click="removeBlock(index)"></i>
          <div class="clearfix"></div>
          <div class="" style="margin-top: 5px">
            <table class="table">
              <thead>
                <th style="width: 100px">No of Column/s</th>
                <th style="width: 150px">Role</th>
                <th>Grid(Width)</th>
                <th>Choose Report/s</th>
                <th>Settings</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select
                      class="form-control"
                      style="width: 100%"
                      v-model="element.column_no"
                      @change="changeCol(index)"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </td>
                  <td>
                    <multiselect
                      v-model="element.role_ids"
                      track-by="id"
                      label="name"
                      :options="roleOptions"
                      :searchable="true"
                      :multiple="true"
                      :close-on-select="false"
                      :show-labels="false"
                      placeholder="Pick a value"
                    ></multiselect>
                  </td>
                  <td>
                    <select
                      class="form-control"
                      style="width: 100%"
                      v-model="element.selected_report.report[0].grid"
                    >
                      <option v-for="option in colOptions">
                        @{{ option }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-if="element.column_no >= 2"
                      style="width: 100%; margin-top: 5px"
                      v-model="element.selected_report.report[1].grid"
                    >
                      <option v-for="option in colOptions">
                        @{{ option }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-if="element.column_no >= 3"
                      style="width: 100%; margin-top: 5px"
                      v-model="element.selected_report.report[2].grid"
                    >
                      <option v-for="option in colOptions">
                        @{{ option }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-if="element.column_no >= 4"
                      style="width: 100%; margin-top: 5px"
                      v-model="element.selected_report.report[3].grid"
                    >
                      <option v-for="option in colOptions">
                        @{{ option }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-if="element.column_no >= 5"
                      style="width: 100%; margin-top: 5px"
                      v-model="element.selected_report.report[4].grid"
                    >
                      <option v-for="option in colOptions">
                        @{{ option }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-if="element.column_no >= 6"
                      style="width: 100%; margin-top: 5px"
                      v-model="element.selected_report.report[5].grid"
                    >
                      <option v-for="option in colOptions">
                        @{{ option }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <multiselect
                      v-model="element.selected_report.report[0].report_id"
                      track-by="id"
                      label="report_name"
                      :options="queryList"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="false"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Pick a value"
                    ></multiselect>
                    <div style="margin-top: 5px">
                      <multiselect
                        v-if="element.column_no >= 2"
                        v-model="element.selected_report.report[1].report_id"
                        track-by="id"
                        label="report_name"
                        :options="queryList"
                        :searchable="true"
                        :allow-empty="false"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Pick a value"
                      ></multiselect>
                    </div>

                    <div style="margin-top: 5px">
                      <multiselect
                        v-if="element.column_no >= 3"
                        v-model="element.selected_report.report[2].report_id"
                        track-by="id"
                        label="report_name"
                        :options="queryList"
                        :searchable="true"
                        :allow-empty="false"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Pick a value"
                      ></multiselect>
                    </div>
                    <div style="margin-top: 5px">
                      <multiselect
                        v-if="element.column_no >= 4"
                        v-model="element.selected_report.report[3].report_id"
                        track-by="id"
                        label="report_name"
                        :options="queryList"
                        :searchable="true"
                        :allow-empty="false"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Pick a value"
                      ></multiselect>
                    </div>
                    <div style="margin-top: 5px">
                      <multiselect
                        v-if="element.column_no >= 5"
                        v-model="element.selected_report.report[4].report_id"
                        track-by="id"
                        label="report_name"
                        :options="queryList"
                        :searchable="true"
                        :allow-empty="false"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Pick a value"
                      ></multiselect>
                    </div>
                    <div style="margin-top: 5px">
                      <multiselect
                        v-if="element.column_no >= 6"
                        v-model="element.selected_report.report[5].report_id"
                        track-by="id"
                        label="report_name"
                        :options="queryList"
                        :searchable="true"
                        :allow-empty="false"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Pick a value"
                      ></multiselect>
                    </div>
                  </td>
                  <td>
                    <div>
                      <button type="button" @click="show(index, 0)">
                        <i class="fa fa-cog"></i>
                      </button>
                    </div>
                    <div style="margin-top: 15px" v-if="element.column_no >= 2">
                      <button type="button" @click="show(index, 1)">
                        <i class="fa fa-cog"></i>
                      </button>
                    </div>
                    <div style="margin-top: 15px" v-if="element.column_no >= 3">
                      <button type="button" @click="show(index, 2)">
                        <i class="fa fa-cog"></i>
                      </button>
                    </div>
                    <div style="margin-top: 15px" v-if="element.column_no >= 4">
                      <button type="button" @click="show(index, 3)">
                        <i class="fa fa-cog"></i>
                      </button>
                    </div>
                    <div style="margin-top: 15px" v-if="element.column_no >= 5">
                      <button type="button" @click="show(index, 4)">
                        <i class="fa fa-cog"></i>
                      </button>
                    </div>
                    <div style="margin-top: 15px" v-if="element.column_no >= 6">
                      <button type="button" @click="show(index, 5)">
                        <i class="fa fa-gear"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </transition-group>
    </draggable>
    <br />
    <div class="col-sm-8">
      <button type="button" @click="add" class="btn btn-success btn-sm">
        <i class="fa fa-plus"></i> New
      </button>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import { ReportHelper } from "@/services/api";
import SettingModal from "./partials/SettingModal.vue";

export default {
  props: ["list", "queryReports", "colOptions", "role_options"],
  data: () => ({
    count: 1,
    dragging: false,
    enabled: true,
    rowList: [
      {
        name: "Row " + 1,
        order: 1,
        fixed: false,
        column_no: 1,
        role_ids: [
          {
            id: "all",
            name: "All",
          },
        ],
        selected_report: {
          report: [
            {
              title: "",
              display_type: "",
              grid: "",
              report_id: "",
              icon_image: "",
              graph_type: "",
              url: "",
              column: "",
              label_column: "",
              color_scheme: "",
              data_column: "",
              additional_data: "",
              redFrom: "",
              redTo: "",
              yellowFrom: "",
              yellowTo: "",
              table_columns: "",
              table_rows: "",
              aggregates_sum: "",
              aggregates_count: "",
              title_color: "",
              value_color: "",
            },
          ],
        },
        display_type: "",
      },
    ],
    queryList: [],
    roleOptions: [],
    isDragging: false,
    listCount: 0,
  }),
  components: {
    Multiselect,
    draggable,
    SettingModal,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
  },
  mounted: function () {
    let that = this;
    ReportHelper.getQueriesByConnection()
      .then(function (response) {
        that.queryList = response.data;
      })
      .catch(function (e) {
        that.errors.push(e);
      });
    ReportHelper.getAllRoles()
      .then(function (response) {
        that.roleOptions = response.data;
      })
      .catch(function (e) {
        that.errors.push(e);
      });
  },
  methods: {
    add: function (event) {
      this.listCount = this.count + 1;
      this.rowList.push({
        name: "Row " + this.listCount,
        order: this.listCount,
        fixed: false,
        column_no: 1,
        role_ids: [
          {
            id: "all",
            name: "All",
          },
        ],
        selected_report: {
          report: [
            {
              title: "",
              display_type: "",
              grid: "",
              report_id: "",
              icon_image: "",
              graph_type: "",
              url: "",
              column: "",
              label_column: "",
              data_column: "",
              additional_data: "",
              redFrom: "",
              redTo: "",
              yellowFrom: "",
              yellowTo: "",
              color_scheme: "",
              table_columns: "",
              table_rows: "",
              aggregates_sum: "",
              aggregates_count: "",
              title_color: "",
              value_color: "",
            },
          ],
        },
        display_type: "",
      });
      ++this.count;
    },
    removeBlock: function (index) {
      this.rowList.splice(index, 1);
    },
    changeCol: function (index) {
      this.rowList[index].selected_report.report = [];
      for (let i = 0; i < this.rowList[index].column_no; i++) {
        this.rowList[index].selected_report.report.push({
          title: "",
          display_type: "",
          grid: "",
          report_id: "",
          icon_image: "",
          graph_type: "",
          url: "",
          column: "",
          label_column: "",
          data_column: "",
          additional_data: "",
          redFrom: "",
          redTo: "",
          yellowFrom: "",
          yellowTo: "",
          color_scheme: "",
          table_columns: "",
          table_rows: "",
          aggregates_sum: "",
          aggregates_count: "",
          title_color: "",
          value_color: "",
        });
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    show: function (index, report_pos_no) {
      this.$modal.show(
        SettingModal,
        {
          index: index,
          report_pos_no: report_pos_no,
          report: this.rowList[index].selected_report.report[report_pos_no],
        },
        { height: "auto", scrollable: true, draggable: true }
      );
    },
    updateReport: function (value) {
      this.rowList[value.index].selected_report.report[value.report_pos_no] =
        value.report;
    },
    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
  },
  updated() {
    this.$emit("updateRow", {
      rowList: this.rowList,
      listCount: this.listCount,
      roleOptions: this.roleOptions,
      queryList: this.queryList,
    });
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
};
</script>
<style scoped>
.handle-movement {
  cursor: grabbing !important;
  float: left;
  padding: 8px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
