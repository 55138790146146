import axios from 'axios'

export default {
  getAllDbConnections: function () {
    return axios.get('/ext-report-builder/db-connections')
  },
  getAllRoles: function () {
    return axios.get('/ext-report-builder/roles')
  },
  getTablesByConnection: function (param : '') {
    return axios.get('/ext-report-builder/get-tables-by-connection?'+ param)
  },
  getQueriesByConnection: function(param : '') {
    // eslint-disable-next-line eqeqeq
    const query = param == undefined ? '' : '?' + param
    return axios.get('/ext-report-builder/get-queries-by-connection' + query)
  },
  getColumnsByTable: function (param: '') {
    return axios.get('/ext-report-builder/get-table-columns?' + param)
  },
  getColumnsByQuery: function (param: '') {
    return axios.get('/ext-report-builder/get-columns-by-query?' + param)
  },
  storeQuery: function (payload : any, id: '') {
    return axios.post('/ext-report-builder/store-report-query/' + id, { data: payload })
  },
  storeDesign: function (payload : any, id: '') {
    return axios.post('/ext-report-builder/store-design/' + id, { data: payload })
  },
  getReportById: function (id : any) {
    return axios.get('/ext-report-builder/query-reports/' + id)
  },
  getAllReports: function (queryParams : any) {
    return axios.get('/ext-report-builder/query-reports', { params: queryParams })
  },
  removeReport: function (id: any) {
    return axios.delete('/ext-report-builder/query-reports/' + id)
  }
}
