import axios from "axios";

export default {
  index(query: any = null, is_paginate = true) {
    return new Promise((resolve, reject) => {
      let params = { pagination: is_paginate };
      params = { ...query, ...params };
      axios
        .get("route-lists", {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  store(payload: any) {
    return axios
      .post("route-lists", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response;
      });
  },
  show(id: any) {
    return axios
      .get("route-lists/" + id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response;
      });
  },
  update(id: any, payload: any) {
    return axios
      .put("route-lists/" + id, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response;
      });
  },
  destroy(id: any) {
    return axios
      .delete("route-lists/" + id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response;
      });
  },

  allRouteList() {
    return axios.get("all-route-lists");
  },
};
