import axios from 'axios';

export default {
  getAllMenus: function () {
    return new Promise((resolve, reject) => {
      axios.get('all-menu')
        .then(response => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error ' + error);
          reject(error);
        });
    });
  },
  getAllMenuForDropdown: function () {
    return new Promise((resolve, reject) => {
      axios.get('/all-menu-for-dropdown')
        .then(response => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error ' + error);
          reject(error);
        });
    });
  },
  store: function (payload: any) {
    return axios.post('admin-menu', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  update(id: any, payload: any) {
    return axios.put('admin-menu/' + id, payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
        return error;
      });
  },
  destroy(id: any) {
    return axios.delete('admin-menu/' + id)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  getMenuDetail: function (id: any) {
    return new Promise((resolve, reject) => {
      axios.get('/admin-menu/' + id)
        .then(response => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('error ' + error);
          reject(error);
        });
    });
  },
  saveMenuOrder: function (payload: any) {
    return new Promise((resolve, reject) => {
      axios.put('update-menu-order', payload)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
      });
    });
  }
}
