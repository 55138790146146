<template>
  <div id='loader' v-if="is_loading"></div>
  <div v-else>
    <div class="container p-0">
      <div id="feed_section">
        <div class="feed-box" v-for="data in dataDetails">
          <div class="feed-heading">
            <h3>{{ !_.isEmpty(dataDetails) ? data.title : '' }}</h3>

            <p>{{ !_.isEmpty(dataDetails) ? data.published_at : '' }}</p>
          </div>
          <div class="feed-image">
            <img :src="!_.isEmpty(dataDetails) ? data.image : '' " alt="">
          </div>
          <div class="feed-content">
            <p>{{ !_.isEmpty(dataDetails) ? data.description : '' }}</p>

            <div class="feed-share-like">
              <label style="pointer-events: none;">
                <input type="checkbox" checked="" class="" data-notice_id="833">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.353" height="15.071" viewBox="0 0 15.353 15.071">
                  <path id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart"
                        d="M13.133,3.938H13.1a3.652,3.652,0,0,0-3.049,1.669A3.652,3.652,0,0,0,7,3.938H6.97A3.629,3.629,0,0,0,3.375,7.565a7.813,7.813,0,0,0,1.534,4.259,26.887,26.887,0,0,0,5.142,4.953,26.887,26.887,0,0,0,5.142-4.953,7.813,7.813,0,0,0,1.534-4.259A3.629,3.629,0,0,0,13.133,3.938Z"
                        transform="translate(-2.375 -2.938)" stroke-width="2"></path>
                </svg>
                <span id="notice_content_love_833">1</span>
              </label>
              <a class="share-count" data-title="এক্সচেঞ্জ রেট" data-shared_link="https://sohoj.io/n?id=833"
                 data-notice_id="833" href="javascript:void(0)">
                <img :src="pictureBaseUrl+'/digi/images/share-alt-icon.svg'" alt=""><span id="share_count_id_833">0</span>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div class="load-more">
        <a href="javascript:void(0)" id="load_more_data" data-page="1" class="btn load-more-data">See More</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "noticePage",
  components: {},
  data() {
    return {
      apiUrl: 'content/notice-page',
      dataDetails: {},
      is_loading: true,
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn') ?? '',
      showModal: false,
      operatorId: localStorage.getItem('operatorId') ?? '1',
      index_no: '',
      detailsShow: false
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init() {
      await this.getDatails().then(response => {
        this.dataDetails = response.data.sections
        this.is_loading = false
      })
    },
    getDatails() {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, '', {
            params: {
              languageId: this.languageId,
              msisdn: this.msisdn,
              operatorId: this.operatorId
            }
          }) // param language,msisdn
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    toggleDetails(index) {
      this.detailsShow = !this.detailsShow
      this.index_no = index
    },
    playGames(link) {
      {
        location.href = link
      }
    }
  }
}
</script>

<style scoped>

</style>
