<template>
  <div v-if="shown">
    <div class="operator-modal" ref="modal" id="shortcutModal">
      <div class="shortcut-content">
        <span class="modal-close">
          <img @click="dismissPrompt" v-bind:src="crossImg" alt="">
        </span>
        <img height="65" v-bind:src="payImg" alt="">
        <h4>হোমস্ক্রিনে শর্টকাট তৈরি করতে</h4>
        <a @click="installPWA" class="btn add-button">এখানে ক্লিক করুন</a>
        <h6></h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PwaPrompt',
  data: () => ({
    shown: false,
    payImg: 'img/pwa/pay-with-digi.svg',
    crossImg: 'img/pwa/shortcut-close.svg'
  }),

  beforeMount () {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      const shortcutModal = localStorage.getItem('shortcut_modal') ?? ''
      this.shown = shortcutModal !== 'false'
    })
  },

  methods: {
    dismissPrompt () {
      console.log('prompt dismissed')
      localStorage.setItem('shortcut_modal', 'false')
      this.shown = false
    },

    installPWA () {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
          localStorage.setItem('shortcut_modal', 'false')
          this.shown = localStorage.getItem('shortcut_modal') === 'false' ?? false
        } else {
          // Do something additional if the user declined
          localStorage.setItem('shortcut_modal', 'false')
          this.shown = localStorage.getItem('shortcut_modal') === 'false' ?? false
        }
      })
    }
  }
}
</script>
