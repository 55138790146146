import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";
const routes: Array<RouteConfig> = [
  {
    path: '/admin/ext-dotlines-admin/organizations',
    name: 'Organizations',
    meta: {requireAuth: true,layout: MasterLayout},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "OrganizationList" */ '../views/Organization/OrganizationList.vue')
  },
  {
    path: '/admin/ext-dotlines-admin/organizations/create',
    name: 'OrganizationForm',
    component: () => import(/* webpackChunkName: "OrganizationForm" */ '../views/Organization/OrganizationForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/ext-dotlines-admin/organizations/:id/edit',
    name: 'OrganizationEdit',
    component: () => import(/* webpackChunkName: "OrganizationFormEdit" */ '../views/Organization/OrganizationForm.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
  {
    path: '/admin/ext-dotlines-admin/organizations/:id/show',
    name: 'OrganizationShow',
    component: () => import(/* webpackChunkName: "OrganizationShow" */ '../views/Organization/OrganizationShow.vue'),
    meta: {requireAuth: true,layout: MasterLayout},
  },
];
export default routes;
