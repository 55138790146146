<template>
  <div id ='loader' v-if="is_loading"></div>
  <body id="site-body" class="feedpage" v-else>
  <div class="container p-0">
    <div class="feed-box" v-for="(data,index) in dataDetails">
      <div class="feed-image" @click.prevent="playGames(data.external_url)">
        <a href="javascript:void(0)" data-target_page="external_url" data-target_id="13"
           data-link="" class="target_free_service">
          <img :src="!_.isEmpty(dataDetails) ? data.banner_image :''" alt="">
          <div class="play-overlay"></div>
        </a>
      </div>
      <div class="feed-content">
        <h4>{{ !_.isEmpty(dataDetails) ? data.title : '' }}</h4>
        <p>{{ !_.isEmpty(dataDetails) ? data.sub_title : '' }}</p>
        <div class="game-details" :style="detailsShow?'display: block':''" v-if="index ===index_no">
          {{ !_.isEmpty(dataDetails) ? data.description : '' }}
          <a href="javascript:void(0)" @click.prevent="playGames(data.external_url)" data-target_page="external_url" data-target_id="13"
             data-link="" class="btn target_free_service">
            {{ !_.isEmpty(dataDetails) ? data.button_text : '' }}
          </a>
        </div>
        <a href="javascript:void(0);" v-if="index !==index_no" @click.prevent="toggleDetails(index)" class="details-btn">Details</a>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import axios from 'axios'

export default {
  name: 'freeService',
  components: {},
  data () {
    return {
      apiUrl: 'content/free-service-page' + '/' + this.$route.params.id,
      dataDetails: {},
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn') ?? '',
      showModal: false,
      operatorId: localStorage.getItem('operatorId') ?? '1',
      index_no: '',
      detailsShow: false
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      await this.getDatails().then(response => {
        this.dataDetails = response.data.sections
        this.is_loading = false
      })
    },
    getDatails () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, '', { params: { languageId: this.languageId, msisdn: this.msisdn, operatorId: this.operatorId } }) // param language,msisdn
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    toggleDetails (index) {
      this.detailsShow = !this.detailsShow
      this.index_no = index
    },
    playGames (link) {
      { location.href = link }
    }
  }
}
</script>

<style scoped>

</style>
