<template>
  <form @submit.prevent="getFilterData" method="GET" novalidate>
    <div class="card-body">
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="name">Report Id</label>
            <div class="col-md-9">
              <input
                type="text"
                class="form-control"
                id="id"
                v-model="search.id"
                placeholder="id"
              />
            </div>
          </div>
          <!-- Date range -->
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="created_at"
              >Date range</label
            >
            <div class="col-md-9">
              <date-picker
                type="datetime"
                input-class="form-control"
                v-model="search.created_at"
                range
                lang="en"
                format="YYYY-MM-DD hh:mm:ss"
                confirm
                id="created_at"
                value-type="YYYY-MM-DD hh:mm:ss"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-9 offset-md-3">
              <button type="submit" class="btn btn-common m-2px">
                <i class="fas fa-search"></i> Search
              </button>
              <button
                type="reset"
                class="btn btn-warning"
                @click="resetFilter()"
              >
                <i class="fas fa-undo"></i> Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  props: ['filter'],
  data: () => ({
    search: {},
    current_page: 1
  }),
  components: {
    DatePicker
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  mounted: function () {},
  methods: {
    resetFilter () {
      /* this.current_page = 1
      this.search = {}
      this.getAllReports() */
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ path: this.$route.path }).catch(() => {})
      this.$emit('reload')
    },
    getFilterData () {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.push({ path: this.$route.fullPath, query: { id: this.search.id, created_at: this.search.created_at } }).catch(() => {})
      /* this.$emit('reload', {
        filter: this.search,
        current_page: this.current_page
      }) */
    }
  }
}
</script>
