

import ClientHeader from '@/views/includes/clientHeader.vue'
import ClientFooter from '@/views/includes/clientFooter.vue'
import PwaPrompt from '@/components/PWAPrompt.vue'

export default {
  components: { PwaPrompt, ClientFooter, ClientHeader },
  data: () => ({
    //
  }),
  methods: {
    //
  },
  mounted: function () {
    // console.log('Master layout component mounted.');
  }
}
