<template>
  <div>
    <div class="col-sm-12 py-15">
      <label class="checkbox"
        ><input
          type="checkbox"
          v-model="enable_graph"
          true-value="yes"
          false-value="no"
          name="add_graph"
        />
        <span class="checkmark"></span> Wanted to add graph? (Works only in grid
        tables)</label
      >
    </div>

    <div class="row" v-if="add_graph == 'yes'">
      <div class="col-sm-12">
        <div class="form-group">
          <label for="table" class="col-sm-3 control-label">Graph Type</label>
          <div class="col-sm-7">
            <select
              required="required"
              v-model="settings.graph_type"
              class="form-control table m-0"
              style="width: 100%"
            >
              <option value=""></option>
              <option>Pie</option>
              <option>DonutChart</option>
              <option>PolarChart</option>
              <option>RadarChart</option>
              <option>Line</option>
              <option>Area</option>
              <option>Bar</option>
              <option>Column</option>
            </select>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label">Graph Title</label>
            <input
              type="text"
              v-model="settings.graph_title"
              class="form-control"
            />
          </div>
        </div>

        <div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Label Column</label>
              <input
                type="text"
                v-model="settings.graph_label"
                class="form-control"
                placeholder="Label"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Data Column</label>
              <input
                type="text"
                v-model="settings.graph_data"
                class="form-control"
                placeholder="Comma separated value"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Color Scheme</label>
            <input
              type="text"
              v-model="settings.graph_color_scheme"
              class="form-control"
              placeholder="Color codes"
            />
            <p class="help-block">
              Color codes with comma separated value. Example #e7717d,#c2cad0
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["graph_settings", "add_graph"],
  data: () => ({
    enable_graph: "no",
    settings: {
      graph_type: "",
      graph_title: "",
      graph_label: "",
      graph_data: "",
      graph_color_scheme: "",
    },
  }),
  methods: {},
  watch: {
    add_graph: function (val, oldVal) {
      this.enable_graph = val;
    },
    graph_settings: function (val, oldVal) {
      this.settings = val;
    },
  },
  updated() {
    this.$emit("updateGraphSettings", {
      settings: this.settings,
      enable_graph: this.enable_graph,
    });
  },
};
</script>
