import axios from 'axios';

export default {
  index(query: any = null, is_paginate = true) {
    return new Promise((resolve, reject) => {
      // let params = {page: currentPage, pagination: is_paginate}
      // params = {...query, ...params};
      axios.get('admin-permissions', {
        params: query
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      }).finally(() => {
        //
      });
    })
  },
  store(payload: any) {
    return axios.post('admin-permissions', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  show(id: any) {
    return axios.get('admin-permissions/' + id)
      .then(response => {
        console.log('single edit response',response.data)
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  update(id:any, payload: any) {
    return axios.put('admin-permissions/' + id, payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  destroy(id: any) {
    return axios.delete('admin-permissions/' + id)
      .then(response => {
        console.log('org api deleted sucress',response);
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  }
}
