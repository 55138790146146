<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>Report</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Report Designer</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="card" id="users-add">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form method="POST" @submit.prevent="saveDesign($event)" novalidate>
            <input type="hidden" v-model="id" name="id" />
            <div class="card-header">
              <h3 class="card-title">Report Designer</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{ name: 'QueryList' }">
                  <i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group required">
                <label for="report_name" class="control-label"
                  >Design Name</label
                >
                <input
                  autocomplete="off"
                  type="text"
                  v-validate="'required'"
                  :class="{ 'has-error': errors.has('name') }"
                  class="form-control"
                  id="name"
                  v-model="name"
                  name="name"
                  placeholder="Design Name"
                />
                <div class="help text-danger" v-show="errors.has('name')">
                  {{ errors.first("name") }}
                </div>
              </div>
              <design-row
                :list="list"
                :queryReports="queryReports"
                :colOptions="colOptions"
                :role_options="role_options"
                @updateRow="updateRow"
              />
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-site">Submit</button>
              <button type="reset" class="btn btn-warning" @click="resetForm">
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { DesignRow } from "./Components";
import { ReportHelper } from "@/services/api";

function initialState() {
  return {
    id: 0,
    is_loading: false,
    update: "no",
    name: "",
    list: [],
    queryReports: [],
    colOptions: [
      "col-md-1",
      "col-md-2",
      "col-md-3",
      "col-md-4",
      "col-md-5",
      "col-md-6",
      "col-md-7",
      "col-md-8",
      "col-md-9",
      "col-md-10",
      "col-md-11",
      "col-md-12",
    ],
    role_options: [],
    totalListCount: 0,
  };
}
export default {
  components: {
    DesignRow,
  },
  data: function () {
    return initialState();
  },
  methods: {
    saveDesign: function (event) {
      this.is_loading = true;
      let post_data = JSON.parse(JSON.stringify(this.$data));
      let that = this;
      var id = this.$route.params.id ? this.$route.params.id : 0;
      ReportHelper.storeDesign(post_data, id)
        .then(function (response) {
          that.$toaster.success(response.data);
          window.location.href = "/admin/ext-report-builder/report-designer";
        })
        .catch(function (errors) {
          that.$toaster.error(errors);
          that.$setErrorsFromResponse(errors.response.data);
        })
        .finally(function () {
          that.is_loading = false;
        });
    },
    resetForm: function () {
      Object.assign(this.$data, initialState());
    },
    updateRow: function (value) {
      this.list = value.rowList;
      this.queryReports = value.queryList;
      this.totalListCount = value.listCount;
      this.role_options = value.roleOptions;
    },
  },
};
</script>
