// eslint-disable-next-line @typescript-eslint/no-empty-function
import axios from 'axios'

export function updateEvent (pageID: any, eventID: any) {
  let serviceBaseURL = "https://vivr.dotlines.com.sg/"
  let previouspageID = "8"
  let params = []
  let i = 0
  let req = []
  let count = 0
  let subscription_id = localStorage.getItem('subscription_id')
  let pageURL = window.location.href
  let parentPageURL = window.parent.location.href
  let msisdn = localStorage.getItem('msisdn')
  let se = sessionStorage.getItem('se')
  let ck = localStorage.getItem('ck')
  let input = {
    'eventID': eventID,
    'pageID': pageID,
    'previouspageID': previouspageID,
    'orgID': 7,
    'appID': 14,
    'subscription_id': subscription_id,
    'se':1,
    'ck':2,
    'msisdn':msisdn,
    'pageURL': pageURL,
    'parentPageURL': parentPageURL
  }
  // console.log('input::', input)
  let api_url = serviceBaseURL + "vivr/update-event"
  let responseData = {}
  axios
    .get(api_url, {params: input})
    .then((response) => {
      responseData = response
      console.log('update-vivr done')
    })
}
