import {RouteConfig} from "vue-router";
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";
const routes: Array<RouteConfig> = [
  {
    path: '/admin/auth/logs',
    name: 'OperationLogs',
    meta: {layout: MasterLayout, requireAuth: true},
    component: () => import(/* webpackChunkName: "departments" */ '../views/OperationLog/OperationLogList.vue')
  }
];
export default routes;
