<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="favorite-number">
        <div class="favorite-number-heading">
          <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.favourite_number_add_title : '' }}</h3>
        </div>
        <div class="receiver-box">
          <div class="form-gorup">
            <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.receiver_mobile_no_text : '' }}</label>
            <div class="receiver-number-box digi-number">
              <div class="country-box" @click="showCountryList">
                <img v-if="numberFlag" :src="pictureBaseUrl+'/'+numberFlag" alt="">
                <img v-else
                     :src="!_.isEmpty(dataDetails) ? pictureBaseUrl+'/'+dataDetails.available_country.bn.flag :''"
                     alt="">
                <span v-if="numberPrefix">{{ !_.isEmpty(dataDetails) ? numberPrefix : '' }}</span>
                <span v-else>{{ !_.isEmpty(dataDetails) ? dataDetails.available_country.bn.prefix : '' }}</span>
              </div>
              <div class="input-number">
                <input type="text" class="form-control" v-model="receiverMsisdn" name="mobile_no" id="mobile_no"
                       style="text-align:left"
                       maxlength="12" minlength="10" autocomplete="off">
              </div>
            </div>
          </div>
          <div id="div_id_select_operator" style="display: none;">
            <label class="form-label">Select Operator</label>
          </div>
          <div id="operator_block">
            <div class="change-operator">
              <img id="operator_small_1" :src="pictureBaseUrl+'/'+selectedOperatorImageUrl" class="operator_small"
                   alt="aritel"
                   style=" margin: 0 auto; height: 35px;"
                   :style="selectedOperatorShow?'display: block  ;':'display: none ;'">
              <button id="changeOperator" @click="toggoleOperatorChoose" type="button" class="btn" style="">
                {{ !_.isEmpty(dataDetails) ? dataDetails.change_operator_btn_text : '' }}
              </button>
            </div>
            <div id="div_id_all_operator" class="operator-wrap" style="display: block;" v-if="allOperatorShow">
              <ul>
                <li v-for="operator in dataDetails.operators">
                  <label class="operator">
                    <input type="radio" name="operator_id" id="operator_1" value="1">
                    <div class="operator-box" @click="submitOperator(operator.id,operator.image)">
                      <img :src="pictureBaseUrl+'/'+operator.image" alt="Airtel-Prepaid">
                      <div class="checked-icon"><img :src="pictureBaseUrl+'/digi/images/checked-black.svg'" alt="">
                      </div>
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="fav-save">
          <form action="#">
            <div class="form-gorup">
              <label class="form-label">{{
                  !_.isEmpty(dataDetails) ? dataDetails.favourite_number_name_text : ''
                }}</label>
              <input type="text" id="name" v-model="receiverName" class="form-control">
            </div>
            <div class="btn-wrapper justify-between">
              <a class="btn no" @click="goToFavouritePage"
                 href="javascript:void(0);">{{ !_.isEmpty(dataDetails) ? dataDetails.cancel : '' }}</a>
              <a class="btn yes" @click="saveFavouriteFriend()"
                 href="javascript:void(0);">{{ !_.isEmpty(dataDetails) ? dataDetails.save : '' }}</a>
            </div>
          </form>
        </div>
      </div>
      <div class="country-modal" id="countryModal" style="display: block;" v-if="showCountry">
        <div class="country-modal-inner">
          <div class="country-modal-close" @click="showCountryList">×</div>
          <div class="country-modal-content">
            <h3>{{ dataDetails.select_country_text }}</h3>
            <ul>
              <li v-for="(country,index) in dataDetails.available_country" :key="index">
                <label @click.prevent="submitCountry(index,country.flag,country.prefix)">
                  <div>
                    <img :src="pictureBaseUrl+'/'+country.flag" alt="">
                    <span>{{ country.name }}</span> <span>{{ country.prefix }}</span>
                  </div>
                  <input type="radio" name="country" value="" checked="">
                  <span class="radio-btn"></span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'favouriteNumberAdd',
  data () {
    return {
      apiUrl: 'content/favourite-number-add',
      getFnfInfoUrl: 'get_favourite_number_info',
      dataDetails: {},
      languageId: sessionStorage.getItem('language') ?? '1',
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      receiverMsisdn: this.$route.params.receiverMsisdn ?? '',
      firstThreeDigitOfReceiverMsisdn: null,
      editId: this.$route.params.id ?? '',
      operatorApiUrl: 'get-countrywise-operator-list',
      showCountry: false,
      showOperator: false,
      numberPrefix: '',
      is_loading: true,
      numberFlag: '',
      service: '',
      receiverOperatorId: sessionStorage.getItem('receiverOperatorId') ?? null,
      receiverCountry: null,
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      saveFavouriteFriendApiUrl: 'save_or_update_favourite_number',
      receiverName: this.$route.params.receiverName ?? '',
      allOperatorShow: false,
      selectedOperatorShow: false,
      selectedOperatorImageUrl: sessionStorage.getItem('receiverOperatorImageUrl') ?? null
    }
  },
  watch: {
    receiverMsisdn (value) {
      if (value.length >= 3) {
        this.allOperatorShow = true
        this.firstThreeDigitOfReceiverMsisdn = value.substring(0, 3)
        for (let i = 0; i < this.dataDetails.operators.length; i++) {
          for (let j = 0; j < this.dataDetails.operators[i].prefix.length; j++) {
            if (this.dataDetails.operators[i].prefix[j] === this.firstThreeDigitOfReceiverMsisdn) {
              this.selectedOperatorImageUrl = this.dataDetails.operators[i].image
              this.receiverOperatorId = this.dataDetails.operators[i].id
              this.selectedOperatorShow = true
              this.allOperatorShow = false
            }
          }
        }
      }
      if (value.length < 3) {
        this.allOperatorShow = false
        this.selectedOperatorShow = false
        this.firstThreeDigitOfReceiverMsisdn = null
      }
    }
  },
  mounted () {
    this.init()
    console.log('mounted receiver operator id', this.receiverOperatorId)
  },
  methods: {
    async init () {
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      if (this.$route.params.receiverMsisdn) {
        await this.getFnfNumberInfo().then(response => {
          if (response.data != '') {
            this.receiverCountry = response.data.receiverCountry
            this.receiverOperatorId = response.data.receiverOperatorId
          }
        })
        for (const countryCode in this.dataDetails.available_country) {
          if (countryCode === this.receiverCountry) {
            this.numberFlag = this.dataDetails.available_country[countryCode].flag
          }
        }
        await this.getAvailableOperator(this.receiverCountry)
      }
      this.is_loading = false
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, {
            languageId: this.languageId,
            requestFor: this.requestFor,
            msisdn: this.msisdn
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    toggoleOperatorChoose () {
      this.allOperatorShow = !this.allOperatorShow
    },
    showCountryList () {
      this.showCountry = !this.showCountry
    },
    showOperatorList () {
      this.showOperator = !this.showOperator
    },
    submitCountry (country, flag, prefix) {
      this.getAvailableOperator(country)
      this.receiverCountry = country
      console.log('country', country)
      this.showCountryList()
      this.numberPrefix = prefix
      this.numberFlag = flag
    },
    getAvailableOperator (country) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.operatorApiUrl, { language: country })
          .then((response) => (this.dataDetails.operators = response.data
          ))
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.firstThreeDigitOfReceiverMsisdn = this.receiverMsisdn.substring(0, 3)
            console.log('recevier operrator id', this.receiverOperatorId)
            for (let i = 0; i < this.dataDetails.operators.length; i++) {
              if (this.dataDetails.operators[i].id === this.receiverOperatorId) {
                this.selectedOperatorImageUrl = this.dataDetails.operators[i].image
                this.selectedOperatorShow = true
                this.allOperatorShow = false
              }
            }
            console.log('finally')
          })
      })
    },
    submitOperator (operatorId, image) {
      this.receiverOperatorId = operatorId
      this.selectedOperatorImageUrl = image
    },
    submitFavouriteFriend () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.saveFavouriteFriendApiUrl, {
            editId: this.editId,
            operatorId: this.operatorId,
            languageId: this.languageId,
            msisdn: this.msisdn,
            receiverMsisdn: this.receiverMsisdn,
            name: this.receiverName,
            receiverOperatorId: this.receiverOperatorId,
            receiverCountry: this.receiverCountry
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getFnfNumberInfo () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.getFnfInfoUrl, {
            operatorId: this.operatorId,
            languageId: this.languageId,
            msisdn: this.msisdn,
            receiverMsisdn: this.receiverMsisdn
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    async saveFavouriteFriend () {
      if (!this.receiverCountry) {
        this.receiverCountry = Object.keys(this.dataDetails.available_country)[0]
      }
      await this.submitFavouriteFriend().then(response => {
        if (response.data.code === '01') {
          this.$toaster.error(response.data.message)
        }
      })
      this.goToFavouritePage()
    },
    goToFavouritePage () {
      this.$router.push({ name: 'favourite-number-view' })
    }
  }
}
</script>

<style scoped>

</style>
