
  export default {
    props: {
      //
    },
    data: () => ({
      //
    }),
    mounted: function () {
      //
    },
    methods: {
      //
    }
  }
