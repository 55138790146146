<template>
  <div class="row">
    <div style="height: 550px; overflow-y: auto !important">
      <div class="col-md-12 text-center" style="margin-top: 30px">
        <h2>Settings</h2>
      </div>
      <div class="col-md-12" style="padding: 0 50px">
        <div>
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              class="form-control"
              v-model="reportDetail.title"
            />
          </div>
          <div class="form-group">
            <label>Link/URL</label>
            <input
              type="text"
              class="form-control"
              v-model="reportDetail.url"
            />
          </div>
          <div class="form-group">
            <label>Display Type</label>
            <select class="form-control" v-model="reportDetail.display_type">
              <option value=""></option>
              <option value="Iconic Data">Card (With Icon)</option>
              <option value="Infographic Data">Card (Without Icon)</option>
              <option value="Table">Table</option>
              <option value="Pivot Table">Pivot Table</option>
              <option value="Graph">Graph</option>
            </select>
          </div>
          <div
            class="form-group"
            v-if="reportDetail.display_type == 'Iconic Data'"
          >
            <label>Icon</label>
            <input
              type="text"
              class="form-control"
              v-model="reportDetail.icon_image"
              placeholder="Image/Icon URL"
            />
          </div>
          <div
            class="form-group"
            v-if="
              reportDetail.display_type == 'Iconic Data' ||
              reportDetail.display_type == 'Infographic Data'
            "
          >
            <label>Column Name</label>
            <input
              type="text"
              class="form-control"
              v-model="reportDetail.column"
              placeholder="Column Name"
            />
          </div>
          <div
            class="form-group"
            v-if="
              reportDetail.display_type == 'Iconic Data' ||
              reportDetail.display_type == 'Infographic Data'
            "
          >
            <label>Title Color</label>
            <input
              type="text"
              class="form-control"
              v-model="reportDetail.title_color"
              placeholder="Title Color"
            />
          </div>
          <div
            class="form-group"
            v-if="
              reportDetail.display_type == 'Iconic Data' ||
              reportDetail.display_type == 'Infographic Data'
            "
          >
            <label>Value Color</label>
            <input
              type="text"
              class="form-control"
              v-model="reportDetail.value_color"
              placeholder="Value Color"
            />
          </div>
          <div class="form-group" v-if="reportDetail.display_type == 'Graph'">
            <select v-model="reportDetail.graph_type" class="form-control">
              <option value=""></option>
              <option>Pie</option>
              <option>DonutChart</option>
              <option>PolarChart</option>
              <option>RadarChart</option>
              <option>ScatterChart</option>
              <option>BubbleChart</option>
              <option>ComboChart</option>
              <option>Line</option>
              <option>Area</option>
              <option>Bar</option>
              <option>Column</option>
              <option>SteppedAreaChart</option>
              <option>Timeline</option>
              <option>GaugeChart</option>
            </select>
          </div>
          <div>
            <div
              class="col-md-6"
              v-if="
                reportDetail.display_type == 'Graph' &&
                (reportDetail.graph_type == 'Line' ||
                  reportDetail.graph_type == 'Pie' ||
                  reportDetail.graph_type == 'Column' ||
                  reportDetail.graph_type == 'Bar' ||
                  reportDetail.graph_type == 'Area' ||
                  reportDetail.graph_type == 'DonutChart' ||
                  reportDetail.graph_type == 'PolarChart' ||
                  reportDetail.graph_type == 'RadarChart' ||
                  reportDetail.graph_type == 'ScatterChart' ||
                  reportDetail.graph_type == 'ComboChart' ||
                  reportDetail.graph_type == 'SteppedAreaChart' ||
                  reportDetail.graph_type == 'Timeline' ||
                  reportDetail.graph_type == 'GaugeChart')
              "
            >
              <div
                class="form-group"
                v-if="
                  reportDetail.graph_type == 'Line' ||
                  reportDetail.graph_type == 'Pie' ||
                  reportDetail.graph_type == 'Column' ||
                  reportDetail.graph_type == 'Bar' ||
                  reportDetail.graph_type == 'Area' ||
                  reportDetail.graph_type == 'DonutChart' ||
                  reportDetail.graph_type == 'PolarChart' ||
                  reportDetail.graph_type == 'RadarChart' ||
                  reportDetail.graph_type == 'ComboChart' ||
                  reportDetail.graph_type == 'SteppedAreaChart' ||
                  reportDetail.graph_type == 'Timeline' ||
                  reportDetail.graph_type == 'GaugeChart'
                "
              >
                <label>Label Column</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.label_column"
                  placeholder="Label"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group"
                v-if="
                  reportDetail.graph_type == 'Line' ||
                  reportDetail.graph_type == 'Pie' ||
                  reportDetail.graph_type == 'Column' ||
                  reportDetail.graph_type == 'Bar' ||
                  reportDetail.graph_type == 'Area' ||
                  reportDetail.graph_type == 'DonutChart' ||
                  reportDetail.graph_type == 'PolarChart' ||
                  reportDetail.graph_type == 'RadarChart' ||
                  reportDetail.graph_type == 'ComboChart' ||
                  reportDetail.graph_type == 'SteppedAreaChart' ||
                  reportDetail.graph_type == 'Timeline' ||
                  reportDetail.graph_type == 'GaugeChart' ||
                  reportDetail.graph_type == 'ScatterChart' ||
                  reportDetail.graph_type == 'BubbleChart'
                "
              >
                <label
                  v-if="
                    reportDetail.graph_type == 'Line' ||
                    reportDetail.graph_type == 'Pie' ||
                    reportDetail.graph_type == 'Column' ||
                    reportDetail.graph_type == 'Bar' ||
                    reportDetail.graph_type == 'Area' ||
                    reportDetail.graph_type == 'DonutChart' ||
                    reportDetail.graph_type == 'PolarChart' ||
                    reportDetail.graph_type == 'RadarChart' ||
                    reportDetail.graph_type == 'ComboChart' ||
                    reportDetail.graph_type == 'SteppedAreaChart' ||
                    reportDetail.graph_type == 'Timeline' ||
                    reportDetail.graph_type == 'GaugeChart'
                  "
                  >Data Column</label
                >
                <label
                  v-if="
                    reportDetail.graph_type == 'ScatterChart' ||
                    reportDetail.graph_type == 'BubbleChart'
                  "
                  >Data Series</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.data_column"
                  placeholder="Comma separated value"
                />
              </div>
            </div>
            <div
              class="col-md-12"
              v-if="reportDetail.graph_type == 'ComboChart'"
            >
              <div class="form-group">
                <label>Additional Line Data Column</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.additional_data"
                  placeholder="Comma separated value"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="reportDetail.graph_type == 'GaugeChart'"
            >
              <div class="form-group">
                <label>Red From</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.redFrom"
                  placeholder="80"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="reportDetail.graph_type == 'GaugeChart'"
            >
              <div class="form-group">
                <label>Red To</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.redTo"
                  placeholder="100"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="reportDetail.graph_type == 'GaugeChart'"
            >
              <div class="form-group">
                <label>Yellow From</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.yellowFrom"
                  placeholder="70"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="reportDetail.graph_type == 'GaugeChart'"
            >
              <div class="form-group">
                <label>Yellow To</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.yellowTo"
                  placeholder="80"
                />
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="
              reportDetail.display_type == 'Graph' &&
              (reportDetail.graph_type == 'Line' ||
                reportDetail.graph_type == 'Pie' ||
                reportDetail.graph_type == 'Column' ||
                reportDetail.graph_type == 'Bar' ||
                reportDetail.graph_type == 'Area' ||
                reportDetail.graph_type == 'DonutChart' ||
                reportDetail.graph_type == 'PolarChart' ||
                reportDetail.graph_type == 'RadarChart' ||
                reportDetail.graph_type == 'ScatterChart' ||
                reportDetail.graph_type == 'BubbleChart' ||
                reportDetail.graph_type == 'ComboChart' ||
                reportDetail.graph_type == 'SteppedAreaChart' ||
                reportDetail.graph_type == 'Timeline')
            "
          >
            <label>Color Scheme</label>
            <input
              type="text"
              class="form-control"
              v-model="reportDetail.color_scheme"
              placeholder="Color codes"
            />
            <p class="help-block">
              Color codes with comma separated value. Example #e7717d,#c2cad0
            </p>
          </div>

          <div
            class="form-inline"
            v-if="reportDetail.display_type == 'Pivot Table'"
          >
            <div class="form-group" style="margin-bottom: 20px">
              <div class="col-md-6">
                <label>Columns(Comma Separated)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.table_columns"
                  placeholder="Columns"
                />
              </div>
              <div class="col-md-6">
                <label>Rows(Comma Separated)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.table_rows"
                  placeholder="Rows"
                />
              </div>
              <div class="col-md-6">
                <label>Sum Column(aggregates)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.aggregates_sum"
                  placeholder="Sum Column"
                />
              </div>
              <div class="col-md-6">
                <label>Count Column(aggregates)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reportDetail.aggregates_count"
                  placeholder="Count Column"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 20px">
          <div class="col-md-12">
            <button class="btn btn-danger" @click="closeModal">
              Save & Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SettingModal",
  props: ["index", "report_pos_no", "report"],
  data: () => ({
    reportDetail: {},
  }),
  mounted: function () {
    this.reportDetail = this.report;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  updated() {
    this.$emit("updateReport", {
      index: this.index,
      report_pos_no: this.report_pos_no,
      report: this.reportDetail,
    });
  },
};
</script>
