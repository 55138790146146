<template>
  <div>
    <div class="col-md-12">
      <h4 class="site-heading my-3"><span>Filters(Where Clause)</span></h4>
    </div>
    <div class="form-row">
      <div
        class="form-group col-md-12"
        v-for="(filters, group_index) in filter_where"
        :key="group_index"
      >
        <label class="control-label mb-10">Filters want to show in grid</label>
        <table
          class="table table-bordered builder-table has-aggregate-function"
        >
          <thead>
            <th style="width: 140px">
              <ul class="list-inline" v-if="group_index > 0">
                <li class="list-inline-item">
                  <label class="radio">
                    <input
                      type="radio"
                      v-model="filters.filter_type"
                      value="and"
                      checked=""
                    />
                    <span class="radio-mark"></span>
                    And
                  </label>
                </li>
                <li class="list-inline-item">
                  <label class="radio">
                    <input
                      type="radio"
                      v-model="filters.filter_type"
                      value="or"
                    />
                    <span class="radio-mark"></span>
                    Or
                  </label>
                </li>
              </ul>
            </th>
            <th>Column</th>
            <th>Operator</th>
            <th>Value</th>
            <th>Is Function</th>
            <th>Use Predefined</th>
            <th>Is query param?</th>
            <th>Is raw?</th>
            <th style="width: 100px">
              <button
                v-if="group_index > 0"
                type="button"
                @click="deleteGroupFilter(group_index)"
                class="add-filter btn btn-danger btn-sm"
              >
                <i class="fa fa-plus"></i>&nbsp;Delete Group
              </button>
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(query_filter, index_query) in filters['query_filters']"
              :key="index_query"
            >
              <td>
                <div v-if="index_query > 0">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <label class="radio">
                        <input
                          type="radio"
                          v-model="query_filter.radio"
                          value="and"
                          checked=""
                        />
                        <span class="radio-mark"></span>
                        And
                      </label>
                    </li>
                    <li class="list-inline-item">
                      <label class="radio"
                        ><input
                          type="radio"
                          v-model="query_filter.radio"
                          value="or"
                        />
                        <span class="radio-mark"></span>
                        Or
                      </label>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <select
                  v-if="query_filter.is_raw == 'no'"
                  class="form-control"
                  v-model="query_filter.filter"
                >
                  <option value="">Choose Column</option>
                  <option
                    v-for="(option, id) in select_options"
                    v-bind:value="option"
                    :key="id"
                  >
                    {{ option }}
                  </option>
                </select>
                <input
                  v-if="query_filter.is_raw == 'yes'"
                  class="form-control"
                  v-model="query_filter.filter"
                />
              </td>
              <td>
                <select class="form-control" v-model="query_filter.operators">
                  <option value="equal">equal</option>
                  <option value="not_equal">not_equal</option>
                  <option value="in">in</option>
                  <option value="not_in">not_in</option>
                  <option value="is_null">is_null</option>
                  <option value="is_not_null">is_not_null</option>
                  <option value="less">less</option>
                  <option value="less_or_equal">less_or_equal</option>
                  <option value="greater">greater</option>
                  <option value="greater_or_equal">greater_or_equal</option>
                  <option value="between">between</option>
                  <option value="begins_with">begins_with</option>
                  <option value="contains">contains</option>
                  <option value="ends_with">ends_with</option>
                </select>
              </td>
              <td>
                <input
                  v-if="
                    query_filter.use_predefined == false ||
                    query_filter.use_predefined == ''
                  "
                  type="text"
                  class="form-control"
                  v-model="query_filter.value"
                  placeholder="value"
                />

                <select
                  v-if="query_filter.use_predefined == true"
                  class="form-control filter-operators-primary-table"
                  v-model="query_filter.value"
                >
                  <option value="auto_organization_id">Organization ID</option>
                  <option value="auto_user_id">User ID</option>
                  <option value="date_from_last_7_days">
                    From Last 7 Days
                  </option>
                  <option value="date_from_last_30_days">
                    From Last 30 Days
                  </option>
                  <option value="date_current">Current Date</option>
                </select>
              </td>
              <td>
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    value="true"
                    v-model="query_filter.is_function"
                  />
                  <span class="checkmark"></span>&nbsp;
                </label>
              </td>
              <td>
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    value="true"
                    v-model="query_filter.use_predefined"
                  />
                  <span class="checkmark"></span>&nbsp;
                </label>
              </td>
              <td>
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    value="true"
                    v-model="query_filter.is_param"
                  />
                  <span class="checkmark"></span>&nbsp;
                </label>
              </td>
              <td>
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="query_filter.is_raw"
                    true-value="yes"
                    false-value="no"
                  />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="deleteFilter(group_index, index_query)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-sm-12" style="padding: 10px 0px">
          <div class="float-right">
            <button
              type="button"
              @click="addFilter(group_index)"
              class="add-filter btn btn-success btn-sm"
            >
              <i class="fa fa-plus"></i>&nbsp;Add Filter
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-row">
      <div class="col-sm-12">
        <button
          type="button"
          @click="addGroupFilter"
          class="add-filter btn btn-success"
        >
          <i class="fa fa-plus"></i>&nbsp;Add Group
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["filter_group", "select_options"],
  data: () => ({
    filter_where: [
      {
        query_filters: [
          {
            filter: "",
            operators: "",
            value: "",
            radio: "",
            is_function: false,
            use_predefined: false,
            is_param: false,
            is_raw: "no",
          },
        ],
        filter_type: "and",
      },
    ],
  }),
  methods: {
    addFilter: function (group_index) {
      this.filter_where[group_index].query_filters.push({
        filter: "",
        operators: "",
        value: "",
        radio: "and",
        is_function: false,
        use_predefined: false,
        is_param: false,
        is_raw: "no",
      });
    },
    deleteFilter: function (group_index, index) {
      this.filter_where[group_index].query_filters.splice(index, 1);
    },
    deleteGroupFilter: function (group_index) {
      this.filter_where.splice(group_index, 1);
    },
    addGroupFilter: function () {
      this.filter_where.push({
        query_filters: [
          {
            filter: "",
            operators: "",
            value: "",
            radio: "and",
            is_function: false,
            use_predefined: false,
            is_param: false,
            is_raw: "no",
          },
        ],
        filter_type: "and",
      });
    },
  },
  watch: {
    filter_group: function (val, oldVal) {
      this.filter_where = val;
    },
  },
  updated() {
    this.$emit("updateFilterGroup", this.filter_where);
  },
};
</script>
