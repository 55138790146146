<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="single-category-box">
        <img :src="!_.isEmpty( dataDetails) ? dataDetails.service.image_link : ''" alt=""/>
        <div class="overlay bg-pink heaven-overlay">
          <div>
            <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.service.title : '' }}</h3>
            <!--            <p id="hide2"></p>-->
            <p id="hide2">{{ !_.isEmpty(dataDetails) ? dataDetails.service.overlay_desc : '' }}</p>
            <p></p>
          </div>
        </div>
      </div>
      <div class="unsubscribe-box">
        <p>{{ !_.isEmpty(dataDetails) ? dataDetails.service.desc : '' }}</p>
        <div class="terms-by-clicking"></div>
        <a
          href=""
          class="btn-visit"
          data-option_id="undefined"
          data-service_id="undefined"
          v-if="subscriptionStatus=='0'"
          data-language="1"
          @click.prevent="visitSite()"
        >{{ !_.isEmpty(dataDetails) ? dataDetails.service.visit_btn_text : '' }}</a
        >
        <a
          :href="!_.isEmpty(dataDetails.service.app_download_link)?dataDetails.service.app_download_link:''"
          class="btn purple"
          data-option_id="undefined"
          data-service_id="undefined"
          v-else-if="!_.isEmpty(dataDetails.service.app_download_link)"
          data-language="1"
        >{{ !_.isEmpty(dataDetails) ? dataDetails.service.download_btn_text : '' }}</a
        >
        <a
          href=""
          class="btn purple"
          data-option_id="undefined"
          data-service_id="undefined"
          v-else
          data-language="1"
          @click.prevent="submit()"
        >{{ !_.isEmpty(dataDetails) ? dataDetails.service.subscribe_btn_text : '' }}</a
        >
        <a class="unsbs-btn" href="javascript:void(0)" @click="unSubSubmit()" v-if="subscriptionStatus=='0'"
           data-option_id="undefined" data-service_id="undefined"
           data-language="1">{{ !_.isEmpty(dataDetails) ? dataDetails.service.unsubscribe_btn_text : '' }}</a>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {saveUserJourney} from '@/services/api/saveUserJourney'
import {uuid} from 'vue-uuid'
import {updateEvent} from '@/services/api/vivr-engine-helper'

export default {
  name: 'servicePage',
  components: {},
  data: () => ({
    apiUrl: '/content/',
    dataDetails: {
      service: {}
    },
    msisdn: localStorage.getItem('msisdn') ?? '',
    is_loading: true,
    subscriptionStatus: '',
    languageId: sessionStorage.getItem('language') ?? '1',
    registrationCheckUrl: '/check-reg-status',
    service: {},
    sessionId: sessionStorage.getItem('sessionId'),
    operatorId: localStorage.getItem('operatorId')
  }),
  mounted: function () {
    this.init()
  },
  methods: {
    async init() {
      this.service = this.$route.params.service ? this.$route.params.service : sessionStorage.getItem('sub_service')
      this.apiUrl += this.service
      sessionStorage.setItem('sub_service', this.service)
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      if (this.msisdn) {
        this.is_loading = true
        await this.getRegistrationStatus().then(response => {
          this.subscriptionStatus = response.data
          this.is_loading = false
        })
      }
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData() {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, null, {params: {msisdn: this.msisdn, languageId: this.languageId}})
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getRegistrationStatus: function () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.registrationCheckUrl, {
            msisdn: this.msisdn,
            service: this.service,
            operatorId: this.operatorId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submit() {
      if (this.service === 'cinema') {
        this.$router.push({name: 'cinemaServiceDetails'})
      } else {
        if (this.msisdn) {
          this.$router.push({name: 'service-confirmation-page'})
        } else {
          this.$router.push({name: 'input-msisdn-page'})
        }
      }
    },
    unSubSubmit () {
      this.$router.push({ name: 'unsubConfirmationPage' })
    },
    visitSite() {
      if (this.service === 'rashifol' || this.service === 'bangla_barta') {
        this.$router.push({name: 'dialService', params: {service: this.service}})
      } else {
        {
          location.href = this.dataDetails.service.visit_url
        }
      }
      // this.$router.push(this.dataDetails.service.visit_url)
    }
  }
}
</script>
