import { RouteConfig } from 'vue-router'
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue'

const routePath = '/ext-cbs-product'
const viewPath = '/CBS/Product'

const routes: Array<RouteConfig> = [

  {
    path: '/admin' + routePath + '/product_category',
    name: 'ProductCategoryList',
    component: () => import('../views' + viewPath + '/ProductCategory/ProductCategoryList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/product_category/add',
    name: 'ProductCategoryAdd',
    component: () => import('../views' + viewPath + '/ProductCategory/ProductCategoryForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/product_category/:id/edit',
    name: 'ProductCategoryEdit',
    component: () => import('../views' + viewPath + '/ProductCategory/ProductCategoryForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/product_category/:id/show',
    name: 'ProductCategoryShow',
    component: () => import('../views' + viewPath + '/ProductCategory/ProductCategoryShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/product',
    name: 'ProductList',
    component: () => import('../views' + viewPath + '/Product/ProductList.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/product/add',
    name: 'ProductAdd',
    component: () => import('../views' + viewPath + '/Product/ProductForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/product/:id/edit',
    name: 'ProductEdit',
    component: () => import('../views' + viewPath + '/Product/ProductForm.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
  {
    path: '/admin' + routePath + '/product/:id/show',
    name: 'ProductShow',
    component: () => import('../views' + viewPath + '/Product/ProductShow.vue'),
    meta: { layout: MasterLayout, requireAuth: true }
  },
];

export default routes;
