<template>
  <div class="row">
    <div style="height: 700px; overflow-y: auto !important">
      <div class="col-md-12 text-center" style="margin-top: 30px">
        <h2>Settings</h2>
      </div>
      <div class="col-md-12" style="padding: 0 50px">
        <div>
          <table>
            <tr>
              <th style="padding: 10px">Value</th>
              <th style="padding: 10px">Class</th>
            </tr>
            <tr v-for="(config, id) in selections.label_config" :key="id">
              <td style="padding: 10px">
                <input
                  type="text"
                  class="form-control"
                  v-model="config.value"
                />
              </td>
              <td style="padding: 10px">
                <select class="form-control" v-model="config.class">
                  <option value="default">default</option>
                  <option value="primary">primary</option>
                  <option value="success">success</option>
                  <option value="info">info</option>
                  <option value="warning">warning</option>
                  <option value="danger">danger</option>
                </select>
              </td>
            </tr>
            <tr>
              <td style="padding: 10px">
                <button type="button" class="btn btn-dark" @click="addlabel">
                  Add more
                </button>
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        <div class="row" style="margin-top: 20px">
          <div class="col-md-12">
            <button class="btn btn-dark" @click="closeModal">
              Save & Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfigurationModal",
  props: ["column_selection"],
  data: () => ({
    selections: {
      column: "",
      alias: "",
      is_raw: "no",
      is_sortable: "no",
      has_label: "no",
      label_config: [
        {
          value: "",
          class: "",
        },
      ],
    },
  }),
  mounted: function () {
    this.selections = this.column_selection;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    addlabel: function () {
      if (typeof this.selections.label_config === "undefined") {
        this.selections.label_config = [];
      }
      this.selections.label_config.push({
        value: "",
        class: "",
      });
    },
  },
  updated() {
    this.$emit("updateSelection", this.selections);
  },
};
</script>
