<template>
  <div>
    <div class="container">
      <div id ='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="favorite-number">
        <div class="favorite-number-heading">
          <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.favourite_number_title : '' }}</h3>
          <p>{{ !_.isEmpty(dataDetails) ? dataDetails.favourite_numbers.length :"" }}/{{ !_.isEmpty(dataDetails) ? dataDetails.max_limit:"" }}</p>
        </div>
        <div class="favorite-number-list">
          <ul>
            <li v-for="data in dataDetails.favourite_numbers">
              <h5><span>{{ !_.isEmpty(data) ? data.name : '' }}</span> {{ !_.isEmpty(data) ? data.mobile_number : '' }}
                - ({{ !_.isEmpty(data) ? data.country_name : '' }})</h5>
              <div class="edit-delete">
                <a href="javascript:void(0);" @click="editFavouriteFriend(data.id,data.mobile_number , data.name)" class="edit" data-mobile_number="01841497007" data-name="waskaz"
                   data-operator_id="4" data-id="11" data-country="bn"><img
                  :src="pictureBaseUrl+'/digi/images/icon-edit.svg'" alt=""></a>
                <a href="javascript:void(0);" class="delete" data-mobile_number="01841497007" data-name="waskaz"
                   data-operator_id="4" data-id="11" @click="showConfirmDeleteModal(data.id)" data-country="bn"><img
                  :src="pictureBaseUrl+'/digi/images/icon-delete.svg'" alt=""></a>
              </div>
            </li>
          </ul>
        </div>
        <a href="javascript:void(0);" @click="addFavouriteFriend" class="add-more btn"><img src="/digi/images/icon-address.svg" alt="">
          {{ !_.isEmpty(dataDetails) ? dataDetails.add_more_number_text : '' }}</a>
        <br>
        <a href="javascript:void(0);" @click="goTopUpPage" class="btn top-up"><img src="/digi/images/icon-address.svg" alt="">{{
            !_.isEmpty(dataDetails) ? dataDetails.top_up_text : ''
          }}</a>
      </div>
    </div>
      <div class="modal fav-modal" id="favModal" style="display: block" v-if="confirmDelete">
        <div class="modal-inner">
          <p>{{ !_.isEmpty(dataDetails) ? dataDetails.favorite_number_delete_confirmation_text : '' }}</p>
          <div class="btn-wrapper justify-between">
            <a class="btn no" @click="changeDeleteValue"
               href="javascript:void(0);">{{ !_.isEmpty(dataDetails) ? dataDetails.no_text : '' }}</a>
            <a class="btn yes" @click="deleteFavouriteFriend()"
               href="javascript:void(0);">{{ !_.isEmpty(dataDetails) ? dataDetails.yes_text : '' }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'favouriteNumberList',
  data () {
    return {
      apiUrl: 'content/favourite-number-list',
      dataDetails: {},
      is_loading: true,
      languageId: sessionStorage.getItem('language') ?? '1',
      msisdn: localStorage.getItem('msisdn'),
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      editFavouriteFriendUrl: 'save_or_update_favourite_number',
      deleteFavoriteFriendUrl: 'delete-favourite-number',
      confirmDelete: false,
      deleteFavouriteFriendId: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })

    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, '', {
            params: {
              languageId: this.languageId,
              msisdn: this.msisdn

            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {

          })
      })
    },
    deleteFavouriteFriend () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.deleteFavoriteFriendUrl, {
            id: this.deleteFavouriteFriendId,
            msisdn: this.msisdn
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.confirmDelete = !this.confirmDelete
            this.init()
          })
      })
    },

    showConfirmDeleteModal (id) {
      this.deleteFavouriteFriendId = id
      this.confirmDelete = !this.confirmDelete
    },
    changeDeleteValue () {
      this.confirmDelete = !this.confirmDelete
    },
    addFavouriteFriend () {
      this.$router.push({ name: 'favourite-number-add' })
    },
    editFavouriteFriend (id, number, name) {
      this.$router.push({ name: 'favourite-number-add', params: { id: id, receiverMsisdn: number, receiverName: name } })
    },
    goTopUpPage()
    {
      this.$router.push({ name: 'topUp', params: { service: 'topup' } })
    }
  }
}
</script>

<style scoped>

</style>
