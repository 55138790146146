const state = {
  condition_fields: [
    { key: "account_type", value: "UserAccountType" },
    { key: "id", value: "UserId" },
    { key: "organization_id", value: "OrganizationId" },
    { key: "department_id", value: "DepartmentId" },
    { key: "user_ref_id", value: "UserRefId" },
    { key: "role_ref_id", value: "RoleRefId" },
    { key: "department_ref_id", value: "DepartmentRefId" },
    { key: "organization_ref_id", value: "OrganizationRefId" },
  ],
  condition_value_auth: [
    { key: "id", value: "UserId" },
    { key: "organization_id", value: "OrganizationId" },
    { key: "department_id", value: "DepartmentId" },
  ],
  condition_operators: [
    { key: "like", value: "like" },
    { key: "=", value: "=" },
    { key: "==", value: "==" },
    { key: "!=", value: "!=" },
    { key: ">", value: ">" },
    { key: "<", value: "<" },
    { key: ">=", value: ">=" },
    { key: "<=", value: "<=" },
  ],
  joining_rules: [
    { key: "NA", value: "NA" },
    { key: "AND", value: "AND" },
    { key: "OR", value: "OR" },
  ],
};

const getters = {
  conditionFields(state: { condition_fields: any }) {
    return state.condition_fields;
  },
  conditionValueAuth(state: { condition_value_auth: any }) {
    return state.condition_value_auth;
  },
  conditionOperators(state: { condition_operators: any }) {
    return state.condition_operators;
  },
  joiningRules(state: { joining_rules: any }) {
    return state.joining_rules;
  },
};

const actions = {
  //
};

const mutations = {
  //
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};
