import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// modules
import auth from "./auth.module";
import customPermissionDetail from "./customPermissionDetail.module";
import { report } from "./report";

const debug = process.env.VUE_APP_ENV !== "production";
export default new Vuex.Store({
  strict: false,
  modules: {
    auth,
    customPermissionDetail,
    report,
  },
});
