<template>

</template>

<script>
export default {
  name: "operator",
  data(){
    return{
      operatorId:{}
    }
  },
  mounted() {
    this.operatorId=this.$route.params.id
    console.log(this.operatorId)
  }
}
</script>

<style scoped>

</style>
