<template>
  <div class="container">
    <div id='loader' v-if="is_loading"></div>
    <div class="support-history-box" v-else>
      <form action="#">
        <div class="search-number">
        </div>
        <div class="form-group">
          <div class="prblem-wrapper">
            <label class="problem">
              <input type="radio" name="ticket_status" class="ticket_status" data-ticket_status="In Progress"
                     data-page_no="2" checked="">
              <div class="problem-box" @click="getData()"> {{
                  !_.isEmpty(dataDetails) ? dataDetails.inprogress_text : ''
                }}
              </div>
            </label>
            <label class="problem">
              <input type="radio" name="ticket_status" class="ticket_status" value="Initiated"
                     data-ticket_status="Initiated" data-page_no="2">
              <div class="problem-box" @click="getStatusWiseDataOnclick('Initiated')">
                {{ !_.isEmpty(dataDetails) ? dataDetails.initiated_text : '' }}
              </div>
            </label>
            <label class="problem">
              <input type="radio" name="ticket_status" class="ticket_status" value="Resolved"
                     data-ticket_status="Resolved" data-page_no="2">
              <div class="problem-box" @click="getStatusWiseDataOnclick('Resolved')">
                {{ !_.isEmpty(dataDetails) ? dataDetails.resolve_text : '' }}
              </div>
            </label>
          </div>
        </div>
        <div class="transaction-history">
          <h4 class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.all_support_text : '' }}</h4>
          <table class="fold-table">
            <tbody v-for="(data,index) in dataDetails.supportHistoryList">
            <tr class="view" :class="detailsView?(index===indexNo?'open':''):''" @click="toggleDetailsView(index)">
              <td><img :src="pictureBaseUrl+'/digi/images/bn/complete-icon.svg'" alt=""></td>
              <td>+6{{ !_.isEmpty(dataDetails) ? data.customer_id : '' }} <span></span></td>
              <td class="active"> {{ !_.isEmpty(pageName) ? pageName : '' }}</td>
            </tr>
            <tr class="fold" :class="detailsView?'open':''" v-if="index===indexNo">
              <td colspan="2">
                <div class="support-details-box">
                  <ul>
                    <li>{{ !_.isEmpty(dataDetails) ? dataDetails.ticket_no_text : '' }} <strong>{{ !_.isEmpty(dataDetails) ? '#'+data.ticket_number : '' }}</strong></li>
                    <li>{{ !_.isEmpty(dataDetails) ? dataDetails.issue_type_text : '' }}<strong>{{ !_.isEmpty(dataDetails) ? data.title : '' }}</strong></li>
                    <li>{{ !_.isEmpty(dataDetails) ? dataDetails.mobile_no_text : '' }} <strong>{{ !_.isEmpty(dataDetails) ? data.receiver_number : '' }}</strong></li>
                    <li>{{ !_.isEmpty(dataDetails) ? dataDetails.transaction_id_text : '' }} <strong>{{ !_.isEmpty(dataDetails) ? '#'+data.transaction_id : '' }}</strong></li>
                    <li>{{ !_.isEmpty(dataDetails) ? dataDetails.request_time_text : '' }} <strong>{{ !_.isEmpty(dataDetails) ? data.request_time : '' }}</strong></li>
                    <li>{{ !_.isEmpty(dataDetails) ? dataDetails.resolve_text : '' }} <strong>{{ !_.isEmpty(dataDetails) ? data.resolve_date : '' }}</strong></li>
                    <li>
                      <h5>{{ !_.isEmpty(dataDetails) ? dataDetails.feedback_text : '' }}</h5>
                      <p class="comments">{{ !_.isEmpty(dataDetails) ? data.remarks : '' }}</p>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!--<a href="#" class="btn">আরও  দেখুন </a>-->
        <a href="javascript:void(0);" data-page_no="2" data-submit="Generate"
           class="btn">{{ !_.isEmpty(dataDetails) ? dataDetails.see_more_text : '' }}</a>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from '@/services/api/saveUserJourney'

export default {
  name: 'supportHistory',
  data () {
    return {
      apiUrl: '/content/support-history',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      service: {},
      selectedService: null,
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language'),
      ticketCreateUrl: 'create_ticket_service',
      message: '',
      ticketResponseData: '',
      waitingMessage: '',
      detailsView: false,
      indexNo: '',
      pageName: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, 'support-page', this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, { msisdn: this.msisdn, languageId: this.languageId, operatorId: this.operatorId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getStatusWiseData (status) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, {
            msisdn: this.msisdn,
            languageId: this.languageId,
            operatorId: this.operatorId,
            ticket_status: status,
            page_no: this.page,
            scope: 'global'
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    async getStatusWiseDataOnclick (status) {
      if (status === 'Initiated') {
        this.pageName = this.dataDetails.initiated_text
      } else if (status === 'Resolved') {
        this.pageName = this.dataDetails.resolve_text
      }
      await this.getStatusWiseData(status).then(response => {
        this.dataDetails = response.data
      })
    },
    toggleDetailsView (index) {
      console.log('clicked details')
      this.detailsView = !this.detailsView
      this.indexNo = index
    }
  }
}
</script>

<style scoped>

</style>
