<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="confirm-wrap">
        <img :src="this.responseData.imageLink" alt="">
      </div>
      <div class="unsubscribe-box" v-if="this.responseData.type === 'topup' || this.responseData.type === 'datapack'">
        <p>{{ this.responseData.text }}</p>
      </div>
      <div v-else-if="this.responseData.type === 'subscription'">
        <div class="unsubscribe-box">
          <p>{{ this.responseData.text }}</p>
        </div>
        <div v-if="this.responseData.registeredUser === 'registered'">
          <a class="btn" :href="this.responseData.visitUrl"> {{ this.responseData.visitKorunText }}</a>
        </div>
      </div>
      <div class="unsubscribe-box" v-else>
        <p>{{ this.responseData.text }}</p>
      </div>

      <a class="btn" href="javascript:void(0);" @click="goHomePage"> {{ this.responseData.homeBtnText }}</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { saveUserJourney } from '@/services/api/saveUserJourney'
import { gtmDataPush } from '../../../public/front/js/gtmHepler'
export default {
  name: 'umobileSuccessFailed',
  data () {
    return {
      dataApiUrl: '',
      responseData: '',
      pageType: '',
      type: '',
      service: '',
      languageId: sessionStorage.getItem('language') ?? '',
      packageName: sessionStorage.getItem('packageName') ?? '',
      msisdn: localStorage.getItem('msisdn') ?? '',
      sessionId: sessionStorage.getItem('sessionId'),
      operatorId: localStorage.getItem('operatorId'),
      is_loading: true,
      priceRmWithCommision: sessionStorage.getItem('priceRmWithCommision') && sessionStorage.getItem('priceRmWithCommision') > 0 ? sessionStorage.getItem('priceRmWithCommision') : sessionStorage.getItem('packageBasePrice')
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.pageType = this.$route.params.pageType
      this.type = this.$route.params.type
      this.service = this.$route.params.service ?? ''

      if (this.pageType === 'success') {
        this.dataApiUrl = 'umobile/success'
        gtmDataPush('purchase', this.msisdn, this.receiverNumber, this.packageName ?? 'talk-time', this.priceRmWithCommision)
      } else {
        this.dataApiUrl = 'umobile/failed'
      }
      await this.getdata().then(response => {
        this.responseData = response.data
        // console.log(this.responseData)
        if (this.responseData.status === 400) this.goHomePage()
        if (_.isEmpty(this.responseData)) this.goHomePage()
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, this.service + '_um_success_fail_page', this.msisdn, this.languageId, this.sessionId)
    },
    getdata () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.dataApiUrl, { msisdn: this.msisdn, service: this.service, languageId: this.languageId, operatorId: this.operatorId, type: this.type, pageType: this.pageType })
          .then((response) => { resolve(response) })
          .catch((error) => { reject(error) })
      })
    },
    goHomePage () {
      this.$router.push({
        name: 'FrontHomeOp',
        params: { op: '2' }
      })
    }
  }
}
</script>

<style scoped>

</style>
