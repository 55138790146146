<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div class="offer-wrap" v-else>
      <ul>
        <li v-for="data in dataDetails.offers">
          <a class="offer" @click="submit(data.package_id)" href="javascript:void(0)" data-option_id="98" data-service_id="9"
             data-target_page="offer-details" data-package_id="1">
            <div class="offer-box">
              <div class="offer-left">
                <h3>{{ !_.isEmpty(dataDetails) ? data.title : '' }}</h3>
              </div>
              <div class="offer-placeholder">
                <div class="placeholder-inner">
                  <h2>{{ !_.isEmpty(dataDetails) ? data.pack_limit : '' }}</h2>
                  <h6><span>GB</span> {{ !_.isEmpty(dataDetails) ? data.body : '' }}
                  </h6>
                </div>
              </div>
              <div class="offer-right">
                <h4>{{ !_.isEmpty(dataDetails) ? data.validity : '' }}</h4>
                <h2>{{ !_.isEmpty(dataDetails) ? data.price : '' }}</h2>
              </div>
              <div class="unlimited-box">
                <h5 v-html="!_.isEmpty(dataDetails) ? data.unlimited_offer : ''"></h5>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'

export default {
  name: 'internetService',
  data () {
    return {
      apiUrl: '/content/offer-page',
      dataDetails: {},
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      service: {},
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language') ?? '1'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, { msisdn: this.msisdn, languageId: this.languageId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit (id) {
      this.$router.push({ name: 'internetServiceDetails', params: { id: id } })
    }
  }
}
</script>

<style scoped>

</style>
