export const report = {
    state: {
        db_connections : []
    },
    getters: {
        getDbConnections(state : any) {
            return state.db_connections;
        }
    },
    mutations: {
        addToDbConnections(state: any, payload : any){
            state.db_connections = payload;
        }
    }
}