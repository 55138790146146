import axios from 'axios';

export default {
  async fetchProfile() {
    return await axios.get('/auth/get-user').then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  updateProfile(payload: any) {
    return axios.post('/auth/change-password', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  changePassword(payload: any) {
    return axios.post('/auth/change-password', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  resetUserPasswordByAdmin(payload: any) {
    return axios.post('/users/reset-password', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  async notifications(query: any = null) {
    return await axios.get('/auth/notifications', {
      params: query
    }).then(response => {
      return response.data;
    }).catch(error => {
      throw(error.response);
    });
  },
  async showNotifications(id: any) {
    return await axios.get('/auth/notifications/' + id).then(response => {
      return response.data;
    }).catch(error => {
      throw(error.response);
    });
  },
  destroyNotifications(id: any) {
    return axios.delete('/auth/notifications/' + id)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  },
  destroySelectedRows(payload: any) {
    return axios.put('/auth/notifications/destroySelectedRows', payload)
      .then(response => {
        return response.data;
      }).catch(error => {
        throw(error.response);
      });
  }
}
