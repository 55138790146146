<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div class="bangla-khobor" v-else>
      <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.tel : '' }}</h3>
      <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.desc : '' }}</h4>
      <!--<a href="tel:1312260" onclick="ActivityLog('19')"  class="btn">ডায়াল </a>-->
      <a :href="'tel:'+dataDetails.tel" class="btn" data-option_id="236" data-service_id="5" data-tel="1312260" data-language="1">ডায়াল</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from '@/services/api/saveUserJourney'

export default {
  name: 'dialService',
  data () {
    return {
      apiUrl: '/content/',
      dataDetails: {},
      service: {},
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('languageId') ?? '1'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      this.apiUrl += this.service += '-khobor'
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
