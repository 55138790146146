<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>Report</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Report</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="card" id="users-add">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form method="POST" @submit.prevent="saveQuery($event)" novalidate>
            <input type="hidden" v-model="id" name="id" />
            <div class="card-header">
              <h3 class="card-title">Query Builder</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{ name: 'QueryList' }">
                  <i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group required">
                <label for="report_name" class="control-label"
                  >Report Name</label
                >
                <input
                  autocomplete="off"
                  type="text"
                  v-validate="'required'"
                  :class="{ 'has-error': errors.has('report_name') }"
                  class="form-control"
                  id="report_name"
                  v-model="report_name"
                  name="report_name"
                  placeholder="Report Name"
                />
                <div
                  class="help text-danger"
                  v-show="errors.has('report_name')"
                >
                  {{ errors.first("report_name") }}
                </div>
              </div>
              <div class="form-group required">
                <label for="active_status" class="control-label"
                  >Database Connection</label
                >
                <select
                  style="width: 100%"
                  :class="{ 'has-error': errors.has('db_connection_name') }"
                  v-validate="'required'"
                  name="db_connection_name"
                  id="db_connection_name"
                  v-model="db_connection_name"
                  class="form-control"
                  @change="onConnectionValueChange($event)"
                >
                  <option value="default">Default</option>
                  <option
                    v-for="(connection, index) in db_connections"
                    v-bind:value="connection.id"
                    :key="index"
                  >
                    {{ connection.connection_name }}
                  </option>
                </select>
                <div
                  class="help text-danger"
                  v-show="errors.has('db_connection_name')"
                >
                  {{ errors.first("db_connection_name") }}
                </div>
              </div>
              <div class="form-group">
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="query_type"
                      value="query_builder"
                      v-model="query_type"
                    />
                    <span class="radio-mark"></span>
                    Query Builder
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="query_type"
                      value="raw_query"
                      v-model="query_type"
                    /><span class="radio-mark"></span>
                    Raw Query
                  </label>
                </div>
                <div class="form-check-inline disabled">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="query_type"
                      value="dynamic_query"
                      v-model="query_type"
                    />
                    <span class="radio-mark"></span>
                    Dynamic Query
                  </label>
                </div>
              </div>
              <div
                class="queryBuilder"
                v-if="
                  query_type == 'query_builder' || query_type == 'dynamic_query'
                "
              >
                <table-list
                  :report_name="report_name"
                  :query_type="query_type"
                  :tables="tables"
                  :table_options="table_options"
                  :select_options="select_options"
                  :query_options="query_options"
                  :db_connection_name="db_connection_name"
                  @updateTable="updateTable"
                ></table-list>
                <select-column
                  :selections="selections"
                  :select_options="select_options"
                  @updateColumnSelection="updateColumnSelection"
                ></select-column>
                <br />
                <aggregate-column
                  :select_aggregates="select_aggregates"
                  :select_options="select_options"
                  :have_aggregate_function="have_aggregate_function"
                  @updateAggregates="updateAggregates"
                ></aggregate-column>
                <add-filter
                  :filter_group="filter_group"
                  :select_options="select_options"
                  @updateFilterGroup="updateFilterGroup"
                />
                <br />
                <custom-condition
                  :query_clause="query_clause"
                  :select_options="select_options"
                  :have_group_by="have_group_by"
                  :have_order_by="have_order_by"
                  :set_limit="set_limit"
                  :set_custom_action="set_custom_action"
                  :add_graph="add_graph"
                  :custom_link_value="custom_link_value"
                  :custom_icon_value="custom_link_icon"
                  @updateQueryClause="updateQueryClause"
                />
                <br />
                <graph-setting
                  :graph_settings="graph_settings"
                  :add_graph="add_graph"
                  @updateGraphSettings="updateGraphSettings"
                />
                <br />
                <filter-grid
                  :grid_filters="grid_filters"
                  :select_options="select_options"
                  @updateGridFilter="updateGridFilter"
                />
              </div>
              <div class="rowQuery" v-if="query_type == 'raw_query'">
                <div class="form-group">
                  <label for="report_name" class="control-label"
                    >Write Query</label
                  >
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="raw_query"
                  ></textarea>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <button type="submit" class="btn btn-site" style="margin-right: 10px">Submit</button>
              <button type="reset" class="btn btn-warning" @click="resetForm">
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {
  SelectColumn,
  AggregateColumn,
  AddFilter,
  CustomCondition,
  GraphSetting,
  FilterGrid,
  TableList
} from './Components'
import { ReportHelper } from '@/services/api'

function initialState () {
  return {
    id: 0,
    report_name: '',
    db_connections: [],
    db_connection_name: '',
    query_type: 'query_builder',
    selections: [],
    select_options: [],
    tables: [],
    table_options: [],
    query_options: [],
    have_aggregate_function: 'no',
    select_aggregates: [],
    raw_query: '',
    filter_group: [],
    have_group_by: 'no',
    have_order_by: 'no',
    set_limit: 'no',
    set_custom_action: 'no',
    add_graph: 'no',
    custom_link_value: '',
    custom_link_icon: '',
    query_clause: {},
    graph_settings: {},
    grid_filters: [],
    is_loading: false
  }
}
export default {
  name: 'QueryForm',
  components: {
    SelectColumn,
    AggregateColumn,
    AddFilter,
    CustomCondition,
    GraphSetting,
    FilterGrid,
    TableList
  },
  data: function () {
    return initialState()
  },
  created () {
    this.is_loading = true
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    const id = this.$route.params.id ? this.$route.params.id : 0
    if (id > 0) {
      ReportHelper.getReportById(id)
        .then(function (response) {
          console.log(response.data)
          that.report_name = response.data.report_name
          that.db_connection_name = response.data.db_connection_name
          that.query_type =
            response.data.query_type === undefined
              ? 'query_builder'
              : response.data.query_type
          that.raw_query = response.data.raw_query
          that.table_options = response.data.table_options
          that.query_options = response.data.query_options
          that.tables = response.data.tables
          that.select_options = response.data.select_options
          that.selections = response.data.selections
          that.have_aggregate_function = response.data.have_aggregate_function
          that.select_aggregates = response.data.select_aggregates
          that.query_filters = response.data.query_filters
          that.query_clause = response.data.query_clause
          that.filter_group =
            response.data.filter_group !== undefined
              ? response.data.filter_group
              : []
          that.have_group_by = response.data.have_group_by
          that.have_order_by =
            response.data.have_order_by !== undefined
              ? response.data.have_order_by
              : 'no'
          that.set_limit =
            response.data.set_limit !== undefined
              ? response.data.set_limit
              : 'no'
          that.set_custom_action =
            response.data.set_custom_action !== undefined
              ? response.data.set_custom_action
              : 'no'
          that.custom_link_value =
            response.data.custom_link_value !== undefined
              ? response.data.custom_link_value
              : ''
          that.custom_link_icon =
            response.data.custom_link_icon !== undefined
              ? response.data.custom_link_icon
              : ''
          that.grid_filters = response.data.grid_filters
          that.dynamic_table_options =
            response.data.dynamic_table_options !== undefined
              ? response.data.dynamic_table_options
              : []
          that.add_graph =
            response.data.add_graph !== undefined ? response.data.add_graph : ''
          that.graph_settings =
            response.data.graph_settings !== undefined
              ? response.data.graph_settings
              : {}
          that.is_loading = false
        })
        .catch(function (e) {
          that.errors.push(e)
        })
        .finally(function () {
          that.is_loading = false
        })
    }
  },
  mounted () {
    this.is_loading = true
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    ReportHelper.getAllDbConnections()
      .then(function (response) {
        that.db_connections = response.data
      })
      .catch(function (e) {
        that.errors.push(e)
      })
      .finally(function () {
        that.is_loading = false
      })
  },
  methods: {
    onConnectionValueChange: function (event) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      // eslint-disable-next-line camelcase
      const connection_id = event.target.value
      // eslint-disable-next-line no-undef
      ReportHelper.getTablesByConnection($.param({ connection: connection_id }))
        .then(function (response) {
          that.table_options = response.data
        })
        .catch(function (e) {
          that.errors.push(e)
        })
      ReportHelper.getQueriesByConnection(
        // eslint-disable-next-line no-undef
        $.param({ connection: connection_id })
      )
        .then(function (response) {
          that.query_options = response.data
        })
        .catch(function (e) {
          that.errors.push(e)
        })
    },
    saveQuery: function (event) {
      this.is_loading = true
      // eslint-disable-next-line camelcase
      const post_data = JSON.parse(JSON.stringify(this.$data))
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      const id = this.$route.params.id ? this.$route.params.id : 0
      ReportHelper.storeQuery(post_data, id)
        .then(function (response) {
          that.$toaster.success(response.data)
          // window.location.href = '/admin/ext-report-builder/query-reports'
          that.$router.push({ name: 'QueryList' })
        })
        .catch(function (errors) {
          that.$toaster.error(errors)
          that.$setErrorsFromResponse(errors.response.data)
        })
        .finally(function () {
          that.is_loading = false
        })
    },
    updateTable: function (value) {
      this.tables = value.tables
      this.select_options = value.select_options
    },
    updateColumnSelection: function (value) {
      this.selections = value
    },
    updateAggregates: function (value) {
      this.select_aggregates = value.select_aggregates
      this.have_aggregate_function = value.have_aggregate_function
    },
    updateFilterGroup: function (value) {
      this.filter_group = value
    },
    updateQueryClause: function (value) {
      this.query_clause = value.query
      this.custom_link_value = value.link_value
      this.custom_link_icon = value.link_icon
      this.have_group_by = value.enable_group_by
      this.have_order_by = value.enable_order_by
      this.set_limit = value.enable_limit
      this.set_custom_action = value.enable_custom_action
      this.add_graph = value.enable_graph
    },
    updateGraphSettings: function (value) {
      this.graph_settings = value.settings
      this.add_graph = value.enable_graph
    },
    updateGridFilter: function (value) {
      this.grid_filters = value
    },
    resetForm: function () {
      Object.assign(this.$data, initialState())
    }
  }
}
</script>
<style>
.has-aggregate-function.table thead > th {
  padding: 10px 5px;
  font-weight: 500;
  /* color: #282929; */
  color: #00a79c !important;
  font-size: 15px;
  /* background: #e8e8ea; */
  background-color: rgba(0, 167, 156, 0.15);
  text-align: center;
}
.has-aggregate-function.table > tbody > tr > td {
  padding: 10px 5px;
  text-align: center;
}
.has-aggregate-function.table > tbody > tr > td:last-child,
.has-aggregate-function.table > thead > tr > th:last-child {
  text-align: center !important;
  white-space: nowrap;
  padding: 0 !important;
}
</style>
